import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alcohol from "../../../../Images/module8/alcohol.png";
import Drugs from "../../../../Images/module8/drugs.png";
import DriveAlcohol from "../../../../Images/module8/driveacohol.png";
import Physical from "../../../../Images/module8/physical.png";
import Suspension from "../../../../Images/module8/suspension.png";
import Law from "../../../../Images/module8/law.png";
import Pound from "../../../../Images/module8/pound.png";
import PersonCar from "../../../../Images/module8/person_car.png";
import Risk from "../../../../Images/module8/risk_drug.png";
import ProvisionLaw from "../../../../Images/module8/provision.png";
import LawEffect from "../../../../Images/module8/law_effect.png";
import Leandra from "../../../../Images/module8/leandra.mp4";
import Chemical from "../../../../Images/module8/m8p1-chemical-test.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleEightPart1 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const [showText, setShowText] = useState(false);

  const handleButtonClick = () => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    setShowText(true);
  };

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
      <Container>
        <div className="module_drugs">
          <Container>
            <div
              className="drugs_content"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <p className="mb-1">Module 8 - Part 1</p>
              <h1 className="mb-0">
                ALCOHOL, <br />
                OTHER DRUGS <br />& DRIVING
              </h1>
            </div>
          </Container>
        </div>

        <div className="overview_content mb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              OVERVIEW
            </h2>

            <p>
              Drinking and driving is a significant problem in the United States
              and a major contributor to the country's highway death toll.
              According to police records, nearly half of all motor
              vehicle-related deaths involve alcohol. This means that either the
              driver, a passenger, or someone else, such as a pedestrian, had
              been drinking at the time of the accident. In most cases, these
              deaths are the result of someone who was operating a vehicle while
              under the influence of alcohol.{" "}
            </p>
            <p>
              {" "}
              On a national level, the statistics are staggering. Every year,
              over 17,000 motor vehicle-related deaths occur as a result of
              alcohol consumption. Additionally, hundreds of thousands of people
              are injured in alcohol-related accidents each year. This is truly
              a national tragedy that affects families and communities across
              the country.{" "}
            </p>
            <p>
              {" "}
              It's important to note that drinking and driving not only poses a
              risk to the driver but also to the passengers, other drivers,
              pedestrians and anyone else who may be on the road. Moreover, the
              consequences of a DUI conviction can be severe and long-lasting,
              including fines, jail time, and a criminal record that can affect
              future employment and other opportunities.{" "}
            </p>
          </Container>
        </div>

        <div className="alcohol_drugs">
          <h2
            className="same_alcohol_head"
            data-aos="zoom-in-right"
            data-aos-duration="2000"
          >
            WHAT ARE "DRUGS" AND "ALCOHOL"?
          </h2>

          <Container>
            <Row>
              <Col lg={6} md={12} xs={12}>
                <div className="alcohol_div mt-3">
                  <h6>WHAT IS ALCOHOL?</h6>
                  <Row>
                    <Col md={8} xs={12}>
                      <p>
                        Alcohol is an odorless, colorless, mind altering
                        (depressant) drug, which, when in beverage form, is
                        contained primarily in beer, wine and distilled spirits.
                      </p>
                    </Col>

                    <Col md={4} xs={12}>
                      <div
                        className="alcohol_img"
                        data-aos="zoom-in-up"
                        data-aos-duration="2000"
                      >
                        <img src={Alcohol} alt="Image" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6} md={12} xs={12}>
                <div className="alcohol_div mt-3">
                  <h6>WHAT ARE DRUGS?</h6>

                  <Row>
                    <Col md={10} xs={12}>
                      <ul className="p-0">
                        <li>
                          A drug is any substance that alters normal bodily
                          function when absorbed by the body.
                        </li>

                        <li>Drugs come in various forms and strengths.</li>
                      </ul>
                    </Col>

                    <div
                      className="drug_img"
                      data-aos="zoom-in-left"
                      data-aos-duration="2000"
                    >
                      <img src={Drugs} alt="Image" />
                    </div>
                  </Row>
                </div>
              </Col>

              <div className="border_div_bottom"></div>

              <Col md={12} xs={12}>
                <div className="drive_alcohol">
                  <h6>
                    WHY DO PEOPLE DRIVE UNDER THE INFLUENCE OF ALCOHOL AND
                    DRUGS?
                  </h6>

                  <p>
                    The following are just a few of the reasons why people drink
                    or use drugs and drive:
                  </p>

                  <ul className="p-0">
                    <li data-aos="fade-right" data-aos-duration="2000">
                      They believe the risk is minimal. Example: An example of a
                      person who believes the risk is minimal is a college
                      student who has been drinking at a party and feels
                      confident to drive home because they have "only had a few
                      drinks."
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They feel that "crashes only happen to others." Example:
                      An example of someone who feels that "crashes only happen
                      to others" is a person who has never been in an accident
                      while driving under the influence and therefore sees no
                      reason to change their behavior.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have no concern for others; "I don't care" attitude.
                      Example: A person who has an "I don't care" attitude
                      towards the safety of others might drive after drinking or
                      using drugs because they prioritize their own convenience
                      over the safety of others on the road.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They believe they'll be okay if they're driving a short
                      distance. Example: Someone who believes they should be
                      okay to drive only a short distance might be a person who
                      drinks at a bar and only lives a few blocks away, so they
                      feel comfortable driving home.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They believe that being extra careful will compensate for
                      being under the influence. Example: A person who believes
                      that being extra careful should compensate for their
                      impairment may think that they can drive safely despite
                      being under the influence because they are taking extra
                      precautions such as driving slower or focusing harder.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have a self-destructive attitude. Example: A person
                      with a self-destructive attitude might drive under the
                      influence because they have a tendency to engage in risky
                      behaviors and don't care about the consequences.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have the perception that alcohol and/or drugs
                      actually enhance their driving abilities. Example: An
                      example of someone who perceives that alcohol or drugs
                      enhance their abilities is a person who is a nervous
                      driver and drinks before getting behind the wheel because
                      they believe it will calm their nerves and make them a
                      better driver.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They have a show-off attitude; "I can do it when others
                      can't." Example: A person with a show-off attitude may
                      drive under the influence to prove to their friends or
                      peers that they can handle their alcohol or drugs and
                      still drive safely.
                    </li>

                    <li data-aos="fade-right" data-aos-duration="2000">
                      They use a minimal amount of alcohol, in order to mask a
                      drug high; this improves chances of avoiding detection at
                      potential sobriety checkpoints. Example: A person who uses
                      a minimal amount of alcohol to mask a drug high might be a
                      person who uses marijuana but drinks just enough alcohol
                      to mask the odor and avoid detection at sobriety
                      checkpoints.
                    </li>

                    <li
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="overlap_content"
                    >
                      Their judgement is impaired by the substance in their
                      system. Example: A person whose judgment is impaired by a
                      substance might drive under the influence because they are
                      not able to make sound decisions and assess the risks of
                      driving while impaired.
                    </li>

                    <li
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="overlap_content"
                    >
                      They are addicted to a type of drug, including alcohol.
                      Example: An example of someone who is addicted to drugs or
                      alcohol might be a person who has a history of substance
                      abuse and finds themselves unable to stop drinking or
                      using drugs even when they know it's dangerous to drive.
                    </li>

                    <li
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      className="overlap_content"
                    >
                      They are a problem drinker and have developed a tolerance.
                      Example: A problem drinker who has developed a tolerance
                      might drive under the influence because they can handle
                      more alcohol than the average person and may not feel as
                      impaired as they actually are.
                    </li>
                  </ul>
                </div>

                <div
                  className="drive_alcohol_img"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <img src={DriveAlcohol} alt="Image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="drug_use">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE PHYSICAL & PHYSIOLOGICAL EFFECTS OF ALCOHOL & DRUG USE
            </h2>
            <p className="physical_para">
              ALCOHOL-IMPAIRED DRIVERS MAY DISPLAY THE FOLLOWING
              CHARACTERISTICS:
            </p>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>Mild neurosis, or anxieties and phobias</li>

                  <li>Low tolerance of tension and frustration</li>

                  <li>Reduced inhibitions and greater risk taking</li>

                  <li>Immaturity</li>

                  <li>Enhanced emotions</li>

                  <li>Inhibition of the decision-making process</li>
                </ul>
                <p className="physical_para">
                  The physical and psychological impairments caused by consuming
                  one and one half to two ounces of alcohol (two beers, two
                  glasses of wine, two shots distilled spirits) in an hour may
                  be the following:
                </p>
                <ul className="p-0">
                  <li>Peripheral vision decreases</li>

                  <li>Eye reaction decreases</li>

                  <li>
                    Visual acuity decreased by an amount comparable to wearing
                    dark glasses at night
                  </li>

                  <li>
                    Recovery time from headlight glare is longer, from 7 to 32
                    seconds depending upon the individual
                  </li>

                  <li>Complex reaction time increases 15 to 25 percent</li>

                  <li>Judgment as to distance and speed is less accurate</li>
                  <li>Attention to details is lacking</li>
                  <li>Driver talks more</li>
                  <li>A false increase in self-confidence </li>
                  <li>The moral code may be temporarily decreased</li>
                  <li>Double vision occurs</li>
                  <li>Blurring of vision occurs</li>
                  <li>
                    Nystagmus (rapid involuntary oscillation of the eyes) occurs
                  </li>
                  <li>Night vision impaired </li>
                  <li>Impairment of stereopsis (three-dimensional vision) </li>
                  <li>Visual hallucinations occur</li>
                  <li>Dizziness occurs </li>
                  <li>Feelings of drowsiness and fatigue enhanced</li>
                </ul>
                <p className="drug_effect">
                  Many drugs, such as depressants, have effects very similar to
                  alcohol.
                </p>

                <p className="note_drug">
                  Note: Alcohol also impairs pedestrians; their behavior is
                  often unpredictable. Be particularly attentive after sporting
                  events and near taverns.
                </p>
              </Col>

              <Col md={6} xs={12}>
                <div
                  className="physical_img"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                >
                  <img src={Physical} alt="Image" />
                </div>
              </Col>
            </Row>

            <p className="physical_para">
              DEFINING AND CALCULATING BLOOD ALCOHOL CONTENT (BAC)
            </p>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>
                    Accurately measuring a person’s Blood Alcohol Content (BAC)
                    is achieved by administering a blood test, urine test,
                    saliva test or breath test. Individuals can roughly
                    calculate their own BAC by knowing the following facts:
                  </li>

                  <li>
                    An average drink (12 ounces of beer, 5 ounces of wine, 1
                    ounce of distilled spirits) will produce a BAC of .020
                    percent in a 160-pound person (.030 percent in a 110-pound
                    person, .015 percent in a 220-pound person).
                  </li>
                </ul>
              </Col>

              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>
                    Once the alcohol is in the bloodstream, all people,
                    regardless of weight, will metabolize or burn up from .015
                    percent to .020 percent alcohol in approximately one hour
                    (about one drink for a 160-pound person). This can decrease
                    with the onset of middle age.
                  </li>

                  <li>
                    Alcohol takes from approximately one-quarter to
                    three-quarters of an hour to permeate the stomach wall and
                    enter the bloodstream.
                  </li>
                </ul>
              </Col>
            </Row>

            <p className="physical_para">
              BEVERAGES COTAINING ALCHOHOL THAT ARE CONSIDERED TO EQUAL 1 DRINK
              ARE:
            </p>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>A (12 oz) can of beer with an alcohol content of 4.5%</li>

                  <li>
                    A (12 oz) bottle of wine cooler with an alcohol content of
                    6%
                  </li>
                </ul>
              </Col>

              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>A (5 oz) glass of wine with an alcohol content of 12%</li>

                  <li>
                    A (1 oz) shot of distilled spirits with an alcohol content
                    of 45%
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="person_example">
          <h2 data-aos="zoom-in-right" data-aos-duration="2000">
            HERE IS AN EXAMPLE OF THE BAC OF A 160-POUND PERSON WHO BEGINS
            DRINKING AT 5:00PM:
          </h2>
          <Row>
            <Col xs={12}>
              <ul className="p-0">
                <li>
                  Approximate blood alcohol content at 6:00 equals .08 percent
                  minus approximately .01 percent that was metabolized, and
                  perhaps .01 percent still in the stomach. Therefore, BAC is
                  approximately .06 percent.
                </li>

                <li>
                  Approximate BAC at 6:30pm equals .08 percent minus
                  approximately .02 percent metabolized. Therefore, BAC is
                  approximately .06 percent.
                </li>
                <li>
                  Approximate BAC at 7:00pm equals .08 percent minus
                  approximately .03 percent metabolized. Therefore, BAC is still
                  approximately .05 percent.
                </li>
              </ul>
            </Col>
          </Row>
          <p className="note_drug">
            NOTE: These are estimates. There can be errors in estimating BAC. It
            is possible to have two people of equal weight with equal food
            amounts in their stomachs to have BACs that differ by .02 percent.
          </p>
          <p className="note_drug">
            NOTE: Zero tolerance is defined as any measurable amount (.02 and
            above) of alcohol in the blood, breath or urine of a driver.{" "}
          </p>
          <Container>
            <div className="mt-3 mb-5">
              <Table responsive className="text-center" cellspacing="5">
                <thead>
                  <tr>
                    <th>BEVERAGE</th>
                    <th className="odd_color">BAC/DRINK</th>
                    <th>time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="data_normal">12 ounces of beer</td>
                    <td className="odd_color">.02%</td>
                    <td>5:00-5:10pm</td>
                  </tr>
                  <tr>
                    <td className="data_normal">+12 ounces of beer</td>
                    <td className="odd_color">+.02%</td>
                    <td>5:10-5:25pm</td>
                  </tr>
                  <tr>
                    <td className="data_normal">+12 ounces of beer</td>
                    <td className="odd_color">+.02%</td>
                    <td>5:25-5:40pm</td>
                  </tr>

                  <tr>
                    <td className="data_normal">+12 ounces of beer</td>
                    <td className="odd_color">+.02%</td>
                    <td>5:40-5:55pm</td>
                  </tr>
                </tbody>
              </Table>

              <div className="pound_img pb-2">
                <img
                  src={Pound}
                  className="image1"
                  alt="Image"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
                <img
                  src={PersonCar}
                  className="image2"
                  alt="Image"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </Container>
        </div>

        <div className="vehicle_undersection">
          <Container>
            <h2
              className="same_vehicle_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              SECTION 1194 OF THE VEHICLE & TRAFFIC LAW REGARDING CHEMICAL
              TESTING & IMPLIED CONSENT
            </h2>

            <ul className="p-0">
              <li>
                Chemical tests use blood, breath, urine or saliva to measure the
                alcohol and/or drug content of a person’s blood.
              </li>

              <li>
                When a driver is arrested for an alcohol or drug-related
                violation, the police officer may request that he or she submit
                to a chemical test.
              </li>

              <li>
                Under New York’s “Implied Consent” law, any motorist who drives
                in this state is considered to have already given consent for
                this test.
              </li>
            </ul>

            <h6>CAN YOU REFUSE A CHEMICAL TEST?</h6>

            <div className="h5p-iframe-module-8" id="h5p_content_navigate">
              {/* <iframe
                src="https://h5p.org/h5p/embed/1228768"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
                title="Module 8 - chemical test"
              ></iframe>
              <script
                src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js"
                charset="UTF-8"
              ></script> */}

              <img src={Chemical} width="100%" alt="Image" />

              {!showText && (
                <button onClick={handleButtonClick} className="press-btn">
                  Press here for the correct answer after making a prediction.
                </button>
              )}
              {showText && (
                <h4 className="para-content">
                  No, you may not refuse a chemical test. - Under New York's
                  "Implied Consent" law, any motorist who drives in this state
                  is considered to have already given consent for this test. -
                  The penalties for refusing are in addition to penalties
                  imposed after conviction of the alcohol or drug related
                  traffic violation. If a motorist refuses to take the chemical
                  test after being arrested, his or her license will be
                  suspended at the court arraignment. The fact that the chemical
                  test was refused may be introduced in court when the motorist
                  is tried on the alcohol or drug-related charge. - A separate
                  DMV hearing will be held to determine whether the chemical
                  test was refused. If the hearing confirms that the test was
                  refused, the motorist's license will be revoked, even if he or
                  she is found not guilty of the alcohol or other drug-related
                  violation. In addition to the license revocation, the motorist
                  will be required to pay a civil penalty as well as take a
                  driver responsibility assessment. - The court will also order
                  a screening to determine whether treatment for alcoholism or
                  substance abuse is required. - Specific information about
                  sanctions and penalties for chemical test refusal may be found
                  in chapters two and nine of the Driver's Manuel.
                </h4>
              )}

              <h3 className="h5p-content-note">
                Please do above activity before going further.
              </h3>
            </div>

            {/* <img src={Chemical} className="chemical-image" alt="Image" />

            <p className="mb-3 vehiclesection">
              No, you may not refuse a chemical test. The penalties for refusing
              are in addition to penalties imposed after conviction of the
              alcohol or drug related traffic violation. If a motorist refuses
              to take the chemical test after being arrested, his or her license
              will be suspended at the court arraignment. The fact that the
              chemical test was refused may be introduced in court when the
              motorist is tried on the alcohol or drug-related charge. A
              separate DMV hearing will be held to determine whether the
              chemical test was refused. If the hearing confirms that the test
              was refused, the motorist license will be revoked, even if he or
              she is found not guilty of the alcohol or other drug-related
              violation. In addition to the license revocation, the motorist
              will be required to pay a civil penalty as well as take a driver
              responsibility assessment. The court will also order a screening
              to determine whether treatment for alcoholism or substance abuse
              is required. Specific information about sanctions and penalties
              for chemical test refusal may be found in chapters two and nine of
              the Drivers Manual.
            </p> */}

            {/* <H5PPlayerUI
              ref={h5pPlayer}
              contentId={"2202648480"}
              loadContentCallback={contentService.getPlay}
              onInitialized={onPlayerInitialized}
              onxAPIStatement={(statement, context, event) => {}}
            /> */}

            <h6>THE DRIVER RESPONSIBILITY ASSESSMENT (DRA)</h6>

            <p className="mb-3 vehiclesection">
              Sections 503(4) and 1199 of the Vehicle and Traffic Law impose a
              Driver Responsibility Assessment (DRA) following convictions for
              certain traffic violations.
            </p>

            <p className="mb-3 vehiclesection">
              This assessment is in addition to any fines, fees, penalties or
              surcharges that are already collected by the courts. DMV (not the
              courts) is responsible for collecting this assessment from
              motorists.
            </p>

            <h6>THE DRA IS IMPOSED IN THE FOLLOWING CIRCUMSTANCES:</h6>

            <ul className="p-0">
              <li>
                When a motorist is convicted of any alcohol or drug-related
                driving offense or of a refusal to submit to a chemical test
                that does not arise out of the same incident as the conviction.
                The assessment is $250 each year for three years.
              </li>

              <li>
                The DMV assigns points for conviction of some traffic
                violations. When a motorist accumulates six or more points on
                their driving record within an 18-month period, the assessment
                is $100 each year for three years, plus $25 for each additional
                point accumulated during that 18-month period.
              </li>

              <li>
                Completing a point reduction course will not reduce the number
                of points for the purposes of the DRA.
              </li>
            </ul>

            <Row>
              <Col md={8} xs={12}>
                <h6 className="mt-3">DRA SUSPENSION</h6>

                <p className="dra_para">
                  If a DRA remains unpaid for a period of 45 days, driving
                  privileges are suspended. The suspension will only be cleared
                  upon receipt of payment.
                </p>
              </Col>

              <Col md={4} xs={12}>
                <div
                  className="suspension_img mb-3"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <img src={Suspension} alt="Image" />
                </div>
              </Col>
            </Row>

            <h6>EXAMPLE OF AN INDIVIDUAL VIOLATING THE IMPLIED CONSENT LAW:</h6>

            <ul className="p-0">
              <li>
                An individual in New York who refuses a chemical test requested
                by a law enforcement officer is in violation of the state's
                implied consent law. The law requires drivers to submit to a
                chemical test (such as a breathalyzer or blood test) if they are
                suspected of driving under the influence of alcohol or drugs.
              </li>

              <li>
                Here is an example scenario: John is pulled over by a police
                officer in New York after running a red light. The officer
                suspects that John may be under the influence of alcohol and
                requests that he submit to a breathalyzer test. John refuses to
                take the test, stating that he does not believe in it.
              </li>

              <li>
                As a result of John's refusal, he has violated New York's
                implied consent law. The law states that by operating a vehicle
                on the state's roads, drivers have already given their consent
                to take a chemical test if requested by law enforcement. John's
                refusal to take the test will result in automatic license
                suspension and potential fines.
              </li>

              <li>
                Additionally, John's refusal can be used as evidence against him
                in court if he is charged with a DUI. The prosecution can argue
                that John's refusal to take the test suggests that he was aware
                that he was under the influence and did not want to provide
                evidence of it.
              </li>

              <li>
                In summary, refusing a chemical test in New York is a violation
                of the state's implied consent law and can result in license
                suspension, fines, and negative implications in court.
              </li>
            </ul>
          </Container>
        </div>
        <div className="zero_tolerance pb-3">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE ZERO TOLERANCE LAW
            </h2>

            <Row>
              <Col md={8} xs={12}>
                <h4 className="mt-4">WHAT IS THE "ZERO TOLERANCE" LAW?</h4>

                <p className="mb-4">
                  This law makes it illegal for a driver under age 21 to have
                  consumed any alcohol. A police officer may temporarily detain
                  you to request or administer a chemical test to determine your
                  Blood Alcohol Content (BAC). If your BAC is .02 to .07
                  percent, you will be notified to appear at a DMV hearing. If
                  the judge's finding supports the charge, the penalty is a 6-
                  month license suspension, a $125 civil penalty, and a $100
                  suspension termination fee. Each additional offense will
                  result in your license being revoked for at least one year or
                  until age 21, whichever is longer, plus a $125 civil penalty,
                  and a $100 license re- application fee.
                </p>
              </Col>

              <Col md={4} xs={12}>
                <div className="law_img">
                  <img
                    src={Law}
                    alt="Image"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  />
                </div>
              </Col>
            </Row>

            <p className="law_note">
              NOTE: If your BAC is .05 percent or greater, the police may charge
              you with driving while ability impaired (DWAI) or driving while
              intoxicated (DWI), and may prosecute your arrest in criminal
              court.
            </p>
            <p>
              § 1192-a. Operating a motor vehicle after having consumed alcohol;
              under the age of twenty-one; per se.
            </p>
            <p>
              If you're under 21 years old, you're not allowed to drive if
              you've had any alcohol. Specifically, you're considered to have
              had alcohol if your blood alcohol level is 0.02% to 0.07%. This
              level is found out by testing your blood, breath, urine, or
              saliva. This rule doesn't usually apply to those driving
              commercial vehicles like trucks. Also, being found driving with a
              BAC of 0.02% to 0.07% when under 21 is not the same as being
              convicted of a crime.
            </p>
          </Container>
        </div>

        <div className="law_effect">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              THE ZERO TOLERANCE LAW WILL HAVE A DIRECT EFFECT ON DRIVERS UNDER
              THE AGE OF 21
            </h2>

            <h6 className="mt-3 mb-3">
              THE MAJOR PROVISIONS OF THE ZERO TOLERANCE LAW:
            </h6>

            <Row>
              <Col md={6} xs={12}>
                <ul className="p-0">
                  <li>
                    It is illegal for a person under the age of 21 to operate a
                    vehicle with a Blood Alcohol Content (BAC) of{" "}
                    <span> 0.2-0.7% </span>.
                  </li>

                  <li>
                    Violators must appear at a DMV hearing. If the youth is
                    found to have committed the offense, the license is
                    suspended for six months.
                  </li>

                  <li>
                    In addition to a license suspension, the violator must pay a
                    $125 civil penalty and a $100 suspension termination fee
                    before license reissuance.
                  </li>

                  <li>
                    If the youth refuses to submit to a chemical test, a DMV
                    hearing is scheduled. If the police officer had grounds for
                    the chemical test request, the penalties are:
                  </li>
                </ul>

                <p className="effect_para">
                  $300 civil penalty, one-year license revocation, and license
                  reapplication fee.
                </p>
              </Col>

              <Col md={6} xs={12}>
                <div className="penalties_div">
                  <h4 className="mb-4">
                    PENALTIES FOR SECOND OR SUBSEQUENT OFFENSES ARE:
                  </h4>

                  <Table responsive className="text-center" cellspacing="5">
                    <tbody>
                      <tr>
                        <td className="penalty_one">Alcohol Consumption</td>
                        <td className="penalty_two">
                          six month license revocation
                        </td>
                      </tr>
                      <tr>
                        <td className="penalty_one">
                          Refusal Of Chemical Test
                        </td>
                        <td className="penalty_two">
                          one year revocation of license loss until age 21
                          (whichever is longer)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <ul className="p-0 mt-5">
                  <li>
                    Violation remains on youth’s record for three years or until
                    age 21, whichever is longer.
                  </li>

                  <li>
                    A finding that a youth aged 16-20 drove after consuming
                    alcohol is considered an administrative violation and not a
                    criminal conviction.
                  </li>
                </ul>

                <div
                  className="law_effect_img"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                >
                  <img src={LawEffect} alt="Image" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              EXAMPLES OF INDIVIDUALS IN VIOLATION OF THE ZERO TOLERANCE LAW AND
              THEIR REPERCUSSIONS
            </h2>

            <ul className="p-0">
              <li>
                Example 1 (First Offense): John is a 20-year-old college student
                who went out to celebrate his birthday with friends. After
                having a few drinks, he gets behind the wheel to drive home. A
                police officer pulls him over for a routine traffic stop and
                suspects that he may be under the influence of alcohol. The
                officer requests a chemical test, which shows that John's BAC is
                .06%. According to the Zero Tolerance law, this is a violation
                and John's driver's license will be suspended for six months. He
                will also be required to pay a $125 civil penalty and a $100
                suspension termination fee before he can have his license
                reissued.
              </li>

              <li>
                Example 2 (Chemical Test Refusal): Sara is a 17-year-old high
                school student who is pulled over by a police officer on her way
                home from a party. The officer suspects that she has been
                drinking and requests a chemical test. Sara refuses to take the
                test and is cited for violating the Zero Tolerance law.
                According to the law, Sara must appear at a DMV hearing where
                the officer will present evidence to support the chemical test
                request. If the officer's evidence is found to be valid, Sara
                will be required to pay a $300 civil penalty, have her license
                revoked for one year, and pay a license reapplication fee.
              </li>

              <li>
                Example 3 (Multiple Offenses): Michael is a 19-year-old college
                student who was caught violating the Zero Tolerance law for the
                second time. He was previously convicted of driving under the
                influence of alcohol when he was 18. This time, his BAC was
                .05%. Michael will be required to have his license revoked for
                at least one year or until he turns 21, whichever is longer. He
                will also be required to pay a $125 civil penalty and a $100
                license reapplication fee.
              </li>
            </ul>
          </Container>
        </div>
      </Container>
    </div>
  );
};
