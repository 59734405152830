import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import slideImage1 from "../../../../Images/module4/slides/slide-1.png";
import slideImage2 from "../../../../Images/module4/slides/slide-2.png";
import slideImage3 from "../../../../Images/module4/slides/slide-3.png";
import slideImage4 from "../../../../Images/module4/slides/slide-4.png";
import slideImage5 from "../../../../Images/module4/slides/slide-5.png";
import slideImage6 from "../../../../Images/module4/slides/slide-6.png";
import "./ModuleNine.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-parent-buttons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slider-parent-buttons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SkidsSliderComponent({ totalOuterSlides }) {
  const [progress, setProgress] = useState((1 / totalOuterSlides) * 100);

  useEffect(() => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  const handleBeforeChange = (oldIndex, newIndex) => {
    console.log("newIndex ===== ", newIndex);
    const calc =
      (newIndex / totalOuterSlides) * 100 + (1 / totalOuterSlides) * 100;
    console.log("calc ===== ", calc);
    if (calc == 100) {
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    }
    setProgress(calc);
  };

  const handleAfterChange = (index) => {
    setInnerCurrentSlide(index);
    if (innerCurrentSlide + 1 == totalOuterSlides) {
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    }
  };
  const [innerCurrentSlide, setInnerCurrentSlide] = useState(0);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    swipe: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
  };

  return (
    <div className="h5p_content_navigate">
      <h3 className="h5p-content-note">
        Note: Please visit all of the below slides before going further.
      </h3>

      <div className="slider-container">
        <Slider {...settings}>
          <div className="outer-slide">
            <img src={slideImage1} width="100%" alt="Image" />
          </div>

          <div className="outer-slide">
            <img src={slideImage2} width="100%" alt="Image" />
          </div>

          <div className="outer-slide">
            <img src={slideImage3} width="100%" alt="Image" />
          </div>

          <div className="outer-slide">
            <img src={slideImage4} width="100%" alt="Image" />
          </div>

          <div className="outer-slide">
            <img src={slideImage5} width="100%" alt="Image" />
          </div>

          <div className="outer-slide">
            <img src={slideImage6} width="100%" alt="Image" />
          </div>
        </Slider>
        <div
          className="progress"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ backgroundSize: `${progress}% 100%` }}
        >
          <span className="slider__label sr-only">{progress}% completed</span>
        </div>
      </div>
    </div>
  );
}

export default SkidsSliderComponent;
