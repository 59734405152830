import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container, Card } from "react-bootstrap";
import under_con from "../../../../Images/module5/under_con.png";
import speeding_img from "../../../../Images/module5/speeding_img.png";
import drive from "../../../../Images/module5/drive.png";
import officer from "../../../../Images/module5/officer.png";
import Stop from "../../../../Images/module5/m54.png";
import Yield from "../../../../Images/module5/m55.png";
import Regulation from "../../../../Images/module5/m56.png";
import Warning from "../../../../Images/module5/m57.png";
import Railroad from "../../../../Images/module5/m58.png";
import WorkArea from "../../../../Images/module5/m59.png";
import Users from "../../../../Images/module5/users.mp4";
import LargeVehicle from "../../../../Images/module5/largevehicle.mp4";
import CellPhonesAndSafetyBeltsComponent from "./CellPhonesAndSafetyBeltsComponent";
import Slide1 from "../../../../Images/module5/slide1.jpg";
import Slide2 from "../../../../Images/module5/slide2.jpg";
import Slide3 from "../../../../Images/module5/slide3.jpg";
import Slide4 from "../../../../Images/module5/slide4.jpg";
import Slide5 from "../../../../Images/module5/slide5.jpg";
import Slide6 from "../../../../Images/module5/slide6.jpg";
import Slide7 from "../../../../Images/module5/slide7.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFive.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

const TRAFFIC_SIGNS = [
  {
    header: "STOP SIGN ",
    headerSpan: "(red with white letter)",
    img: Stop,
    details:
      "Come to a full stop, yield the right of way to vehicles and pedestrians in or approaching the intersection. Go when it is safe. You must come to a full stop before the stop line, if there is one.",
  },
  {
    header: "Yield Signs ",
    headerSpan: "(red and white with red letters)",
    img: Yield,
    details:
      "Slow down as you approach the intersection. Prepare to stop and yield the right of way to vehicles and pedestrians in or approaching the intersection. You must come to a full stop at a YIELD sign if traffic conditions require it. Approach with caution and be prepared to stop; otherwise, proceed with care.",
  },
  {
    header: "Regulation signs ",
    headerSpan: "(white with black and/or red letters or symbols)",
    img: Regulation,
    details:
      "These signs give you information about rules for traffic direction, lane use, turning, speed, parking, and other special requirements",
  },
  {
    header: "Warning signs ",
    headerSpan: "(yellow with black letters or sumbols)",
    img: Warning,
    details:
      "you are approaching an especially hazardous location or a place where there is a special rule. Be especially cautions when you see a warning sign.",
  },
  {
    header: "Railroad crossing ",
    headerSpan: "(Yellow with black letters 'RR' and 'X' symbol)",
    img: Railroad,
    details:
      "There is a railroad crossing ahead. you should use caution, and you may have to stop. Most buses and some trucks must stop rail road crossings, even when a train is not approaching and warning lights are not activated.",
  },
  {
    header: "Work area signs ",
    headerSpan: "(orange with black letters or symbols)",
    img: WorkArea,
    details:
      "People are working on or near the roadway, and traffic may be controlled by flag person. A work area speed limit of 35 MPH may be posted.",
  },
];

export const ModuleFivePart2 = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [videoOneStatus, setVideoOneStatus] = useState(false);
  const [videoTwoStatus, setVideoTwoStatus] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  useEffect(() => {
    if (videoTwoStatus && videoOneStatus) {
      sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
    }
  }, [videoOneStatus, videoTwoStatus]);
  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  var video = document.getElementById("video");
  var video2 = document.getElementById("video2");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }

    if (video2) {
      var supposedCurrentTime = 0;
      video2.addEventListener("timeupdate", function () {
        if (!video2.seeking) {
          supposedCurrentTime = video2.currentTime;
        }
      });
      // prevent user from seeking
      video2.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video2.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video2.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video2.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video, video2]);
  return (
    <div>
      <Container>
        <div className="module5_course same_width_module mt-md-4 mb-md-4">
          <div className="container">
            <div className="mdu5_page1">
              <h2>MODULE 5 - Part 2</h2>
            </div>
          </div>

          <div className="mdu5_page7">
            <div className="container">
              <h2
                className="w_zone_tit"
                data-aos="slide-right"
                data-aos-duration="3000"
              >
                WORK ZONE SAFETY
              </h2>
              <div className="row">
                <div className="col-md-4">
                  <div className="under_con">
                    <img src={under_con} alt="" srcset="" />
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="work_zone">
                    <h3>Definition of a "Work Zone"</h3>
                    <p className="pl-md-4">
                      <span>
                        {" "}
                        AS PER THE VEHICLE AND TRAFFIC LAW (SECTION 160):
                      </span>{" "}
                      <br /> A work zone is a "part of the highway being used or
                      occupied for the conduct of highway work, within which
                      workers, vehicles, equipment, materials, supplies,
                      excavations or other obstructions are present."
                    </p>
                  </div>
                </div>
              </div>

              <div className="safe_zone">
                <h2 data-aos="slide-left" data-aos-duration="3000">
                  BEING SAFE IN A WORK ZONE
                </h2>
                <p className="more_w">
                  More work zones are constantly set up each year. More work
                  zones = higher risk of accidents and deaths.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="safe_box"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <p>
                        669 people died nationally in work zones in the year
                        2014, per the US Department of Transportation.
                      </p>
                    </div>

                    <div
                      className="safe_box"
                      data-aos="slide-right"
                      data-aos-duration="3000"
                    >
                      <p>Speeding ticket fines are doubled in work zones.</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="safe_box"
                      data-aos="slide-left"
                      data-aos-duration="1000"
                    >
                      <p>
                        The most common crash in a highway work zone is the
                        rear-end collision (Federal Highway Administration).
                      </p>
                    </div>
                    <div
                      className="safe_box"
                      data-aos="slide-left"
                      data-aos-duration="3000"
                    >
                      <p>
                        Enforcement of traffic laws in work zones is maintained
                        24 hours a day; work zone speed limits are enforced even
                        when no work is underway.
                      </p>
                    </div>
                  </div>
                </div>
                <p className="more_w">
                  Traffic enforcement is enhanced in work zones because of all
                  the potential risks and dangers.
                </p>
              </div>

              <div className="determine">
                <h2 data-aos="slide-up" data-aos-duration="3000">
                  HOW DO WE DETERMINE A SAFE FOLLOWING DISTANCE BETWEEN
                  VEHICLES?
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="determine_box"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <p>
                        You should stay at least two seconds behind the vehicle
                        that is directly in front of you.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="determine_box"
                      data-aos="slide-left"
                      data-aos-duration="1000"
                    >
                      <p>
                        If you are driving in rain or other adverse conditions,
                        you may need to double or triple your following
                        distance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="determine_box"
                      data-aos="slide-up"
                      data-aos-duration="1000"
                    >
                      <p>
                        A safe following distance is sometimes defined using a
                        formula; one car length per each 10 miles per hour that
                        you are traveling.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="twoSec_rule">
                  <div className="row">
                    <div className="col-md-6">
                      <Card className="speeding_con QuizCollapse">
                        <h6 className="font-bold">Two-second rule</h6>
                        <Card.Body className="timepara">
                          <p>
                            To practice the two-second rule, choose a stationary
                            object on or alongside the road. When the rear
                            bumper of the vehicle ahead of you reaches the
                            object, begin to count “one-thousand-one,
                            one-thousand-two.” If you complete counting to
                            “one-thousand-two” before passing the stationary
                            object, your following distance is adequate.
                          </p>
                        </Card.Body>
                      </Card>

                      <Card className="speeding_con QuizCollapse">
                        <h6 className="font-bold">Covering the brake</h6>
                        <Card.Body className="timepara">
                          <p>
                            The practice of covering the brake is accomplished
                            by placing your foot just above the brake pedal.
                            This is done to shorten one’s reaction time, which
                            might be necessary in situations such as approaching
                            children that are playing near the road or
                            approaching a stale green light. A stale green light
                            is one that is green when you first see it, and may
                            turn to yellow at any time.
                          </p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-md-6">
                      <div className="speeding_con">
                        <h6 className="font-bold">
                          Speeding influences vision, stopping distance, and
                          crash survival as:
                        </h6>
                        <p>Peripheral vision decreases</p>
                        <p>Stopping distance increases</p>
                        <p>The force of impact increases</p>
                        <p>Chances of survival decrease</p>
                        <img
                          src={speeding_img}
                          alt=""
                          srcset=""
                          data-aos="slide-left"
                          data-aos-duration="3000"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mdu5_page8">
            <div className="container">
              <div className="way_user">
                <h2 data-aos="slide-up" data-aos-duration="3000">
                  WHO ARE OTHER HIGHWAY USERS AND HOW DO WE SAFELY
                  <br />
                  SHARE THE ROAD WITH THEM?
                </h2>

                <p className="video_highway_para">
                  The following video will show who the other highway users are
                  and explain how we, as drivers, can safely share the road with
                  them. Click the Play button to start the video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    setVideoOneStatus(true);
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Users} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          <div className="mdu5_page9">
            <div className="drowsiness mdu5_page7">
              <div className="container">
                <div className="safe_zone">
                  <h2
                    class="aos-init aos-animate"
                    data-aos="slide-right"
                    data-aos-duration="3000"
                  >
                    FATIGUE AND DROWSINESS
                  </h2>
                </div>
                <p>
                  The likelihood of falling asleep at the wheel is more common
                  than most people realize. One out of five drivers admit to
                  having fallen asleep at the wheel at least once. Many other
                  sleepy drivers who deny falling asleep at the wheel may have
                  experienced microsleeping while driving.
                </p>

                <p>
                  Microsleep occurs when a drowsy driver is overtaken by
                  involuntary “naps” that last four to five seconds. It is
                  estimated that 100,000 reported crashes annually are the
                  result of drowsiness.
                </p>

                <p>
                  Many drivers don’t realize that they have no control over
                  whether they fall asleep. They also don’t realize that they
                  cannot predict when they are about to fall asleep. In a test
                  situation, nearly 80% of drivers thought that they could
                  predict when they were about to fall asleep behind the wheel;
                  they were proven wrong. This creates a false sense of security
                  in the sleepy driver.
                </p>

                <p>
                  Have you ever fallen asleep when you didn't even know you were
                  tired? In school? Watching television? Doing homework? When
                  did you realize that you'd been sleeping? How long were you
                  asleep?
                </p>
              </div>

              <div className="abillity">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  How does drowsiness impair driving ability?
                </h2>
                <p>
                  Visual misperception: The driver misinterprets what he or she
                  sees on the road.
                </p>
                <p>
                  Increased reaction time: The driver responds more slowly to
                  changing road or vehicle conditions.
                </p>
                <p>
                  Decreased attention span and reduced information processing:
                  The driver has difficulty concentrating on the road and
                  vehicle conditions, causing a diminished ability to process
                  the necessary information for making appropriate driver
                  decisions.
                </p>
                <p>
                  Diminished judgment: The driver has an increased likelihood of
                  making decisions based on poor judgement, including attempting
                  risky maneuvers, such as passing other vehicles without fully
                  surveying the situation.
                </p>
                <p>
                  Impaired problem-solving ability: The driver is less capable
                  of getting out of dangerous situations.
                </p>
                <p>
                  Decreased ability to control the vehicle: The driver may tend
                  to have less control of the vehicle, such as allowing it to
                  drift from lane to lane.
                </p>
                <div className="tips">
                  <div className="row">
                    <div className="col-md-4">
                      <img className="under_con" src={drive} alt="" srcset="" />
                    </div>

                    <div className="col-md-8">
                      <h3>
                        TIPS ON STAYING AWAKE <span> (Before you drive) </span>
                      </h3>
                      <p>
                        <b>BE REALISTIC:</b>
                        <br />
                        Rather than trying to cover a long distance in one shot,
                        plan on stopping for a short rest or an overnight stay.
                      </p>
                      <p>
                        <b>AVOID ALCOHOL AND OTHER DRUGS:</b>
                        <br />
                        Even one drink will make a slightly tired person
                        drowsier. Avoid any medicines, such as allergy pills,
                        that may induce drowsiness.
                      </p>
                      <p>
                        <b>TRAVEL RESTED:</b>
                        <br />
                        Get plenty of rest before your trip.
                      </p>
                      <p>
                        <b>RESPECT YOUR BODY CLOCK:</b> <br />
                        If you drive when you normally sleep, you’re fighting
                        your body’s natural rhythm. Avoid this, or make plans to
                        rest along the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mdu5_page10">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <div className="mdu5_p10_con">
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      TIPS FOR STAYING AWAKE <span>(On the road)</span>
                    </h2>
                    <p>
                      <span>Nap</span> - A short rest may be an effective remedy
                      to drowsiness. Find a safe place, such as a lit parking
                      lot of an open restaurant or store, or a designated rest
                      stop.
                    </p>

                    <p>
                      <span>Stay stimulated - </span> Listen to the radio (a
                      talk show may keep you more alert than music), open a
                      window, chew gum.
                    </p>

                    <p>
                      <span>Share the ride</span> - A travel partner can keep
                      you awake with conversation and give you a break from
                      driving.
                    </p>

                    <p>
                      <span>Take a walk</span> - Get out at a rest stop to take
                      a quick walk to the restroom, or just stroll around for
                      five minutes. Even a short break is helpful.
                    </p>

                    <p>
                      <span>Drink caffeine</span> - Caffeine can be helpful in
                      getting you briefly energized, but, remember that the
                      effects begin to wear off in an hour or so.
                    </p>

                    <p>
                      <span>Recognize the warning signs</span> - If your eyes
                      get droopy, your head nods down, your sight begins to
                      diminish, your attention to the driving tasks wanders, or
                      you can’t stop yawning, you are at risk.
                    </p>
                    <p>
                      <span>Deterioration of your driving</span> - If you drift
                      back and forth in your driving lane, inadvertently
                      tailgate other vehicles, or misread traffic signs, you are
                      at risk.
                    </p>
                  </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-2">
                  <div className="officer_img">
                    <img
                      src={officer}
                      alt=""
                      srcset=""
                      data-aos="slide-left"
                      data-aos-duration="3000"
                    />
                  </div>
                </div>
              </div>
              <div className="m5_p10_sec2">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  LARGE VEHICLES & HOW TO SAFELY SHARE THE ROAD WITH THEM
                </h2>

                <p className="video_highway_para">
                  This video will talk about different Large Vehicles and will
                  demonstrate how to safely share the road with them. Click the
                  Play button to start the video.
                </p>

                <video
                  id="video2"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    setVideoTwoStatus(true);
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={LargeVehicle} type="video/mp4" />
                </video>
              </div>

              <div className="module-5-part-2">
                {/* <iframe
                  src="https://h5p.org/h5p/embed/1229216"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                  allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
                  title="Module 5: cell phones and safety belts updated"
                ></iframe>
                <script
                  src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js"
                  charset="UTF-8"
                ></script> */}

                <div className="CellPhonesAndSafetyBeltsComponent_h5p" id="h5p_content_navigate">
                  <CellPhonesAndSafetyBeltsComponent totalOuterSlides={7} />
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <H5PPlayerUI
              ref={h5pPlayer}
              contentId={"1933101977"}
              loadContentCallback={contentService.getPlay}
              onInitialized={onPlayerInitialized}
              onxAPIStatement={(statement, context, event) => {}}
            />
          </div> */}

          {/* <div className="mdu5_page11">
            <div className="abo_safety">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12">
                    <img
                      src={logo}
                      alt=""
                      srcset=""
                      data-aos="slide-right"
                      data-aos-duration="3000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <Container className="slide-container">
            <div className="row">
              <img
                src={Slide1}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
            <div className="row">
              <img
                src={Slide2}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
            <div className="row">
              <img
                src={Slide3}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
            <div className="row">
              <img
                src={Slide4}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
            <div className="row">
              <img
                src={Slide5}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
            <div className="row">
              <img
                src={Slide6}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
            <div className="row">
              <img
                src={Slide7}
                style={{ width: "100%" }}
                className="slide-image"
                alt="Image"
              />
            </div>
          </Container> */}
        </div>
      </Container>
    </div>
  );
};
