import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Logo from "../../../../Images/logo.png";
import Alcohol from "../../../../Images/module8/alcohol.png";
import Drugs from "../../../../Images/module8/drugs.png";
import DriveAlcohol from "../../../../Images/module8/driveacohol.png";
import Physical from "../../../../Images/module8/physical.png";
import Suspension from "../../../../Images/module8/suspension.png";
import Law from "../../../../Images/module8/law.png";
import Pound from "../../../../Images/module8/pound.png";
import PersonCar from "../../../../Images/module8/person_car.png";
import Risk from "../../../../Images/module8/risk_drug.png";
import ProvisionLaw from "../../../../Images/module8/provision.png";
import LawEffect from "../../../../Images/module8/law_effect.png";
import Leandra from "../../../../Images/module8/leandra.mp4";

// import faces image
import face1Image from "../../../../Images/module8/character-face-images/face-1.jpg";
import face2Image from "../../../../Images/module8/character-face-images/face-2.jpg";
import face3Image from "../../../../Images/module8/character-face-images/face-3.jpg";
import face4Image from "../../../../Images/module8/character-face-images/face-4.jpg";
import face5Image from "../../../../Images/module8/character-face-images/face-5.jpg";
import face6Image from "../../../../Images/module8/character-face-images/face-6.jpg";
import face7Image from "../../../../Images/module8/character-face-images/face-7.jpg";
import face8Image from "../../../../Images/module8/character-face-images/face-8.jpg";
import face9Image from "../../../../Images/module8/character-face-images/face-9.jpg";
import face10Image from "../../../../Images/module8/character-face-images/face-10.jpg";
import face11Image from "../../../../Images/module8/character-face-images/face-11.jpg";
import face12Image from "../../../../Images/module8/character-face-images/face-12.jpg";
import face13Image from "../../../../Images/module8/character-face-images/face-13.jpg";
import face14Image from "../../../../Images/module8/character-face-images/face-14.jpg";
import face15Image from "../../../../Images/module8/character-face-images/face-15.jpg";
import face16Image from "../../../../Images/module8/character-face-images/face-16.jpg";
import face17Image from "../../../../Images/module8/character-face-images/face-17.jpg";
import face18Image from "../../../../Images/module8/character-face-images/face-18.jpg";
import face19Image from "../../../../Images/module8/character-face-images/face-19.jpg";

// import example scenario images
import exampleScenarioImages1 from "../../../../Images/module8/example-scenario-images/example-scenario-1.png";
import exampleScenarioImages2 from "../../../../Images/module8/example-scenario-images/example-scenario-2.png";
import exampleScenarioImages3 from "../../../../Images/module8/example-scenario-images/example-scenario-3.png";
import exampleScenarioImages4 from "../../../../Images/module8/example-scenario-images/example-scenario-4.png";
import exampleScenarioImages5 from "../../../../Images/module8/example-scenario-images/example-scenario-5.png";
import exampleScenarioImages6 from "../../../../Images/module8/example-scenario-images/example-scenario-6.png";
import exampleScenarioImages7 from "../../../../Images/module8/example-scenario-images/example-scenario-7.png";
import exampleScenarioImages8 from "../../../../Images/module8/example-scenario-images/example-scenario-8.png";

import swal from "sweetalert";
import peopleWhoAreHighRiskImage from "../../../../Images/module8/people-who-are-high-risk.jpg";
import peopleWhoAreLowRiskImage from "../../../../Images/module8/people-who-are-low-risk.jpg";
import situationsThatAreHighRiskImage from "../../../../Images/module8/situations-that-are-high-risk.jpg";
import situationsThatAreLowRiskImage from "../../../../Images/module8/situations-that-are-low-risk.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleEight.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

export const ModuleEightPart2 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const [image1Clicked, setImage1Clicked] = useState(false);
  const [image2Clicked, setImage2Clicked] = useState(false);
  const [image3Clicked, setImage3Clicked] = useState(false);
  const [image4Clicked, setImage4Clicked] = useState(false);
  const [allFaceImagesClicked, setAllFaceImagesClicked] = useState(false);
  const [decisionMatrixImagesClicked, setDecisionMatrixImagesClicked] =
    useState(false);

  const handleImage1Click = (content) => {
    setImage1Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage2Click = (content) => {
    setImage2Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage3Click = (content) => {
    setImage3Clicked(true);
    handleImageClick("Details", content);
  };

  const handleImage4Click = (content) => {
    setImage4Clicked(true);
    handleImageClick("Details", content);
  };

  const clickedStatus = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
  };
  const handleFaceImageClick = (index) => {
    let content = "";
    let title = "";

    const clickedImage = document.querySelector(
      `.face-char-img[data-index="${index}"]`
    );
    if (clickedImage && !clickedImage.classList.contains("clicked")) {
      clickedImage.classList.add("clicked");
    }

    switch (index) {
      case 1:
        title = `Driving while ability impaired.`;
        content = `
    <span className="detail">
      John was pulled over by the police after swerving across lanes. Upon approaching the vehicle, the officer noticed the smell of alcohol on John's breath and observed his slurred speech. After conducting a field sobriety test, John's impaired ability to operate the motor vehicle was evident, with a blood alcohol content (BAC) of .07 percent, falling within the range specified under the law, leading to his arrest for driving while ability impaired.
    </span>`;
        break;

      case 2:
        title = `Driving while intoxicated; per se.`;
        content = `
    <span className="detail">
    Sarah was pulled over by the police for speeding. Upon conducting a routine traffic stop, the officer suspected Sarah of driving under the influence of alcohol due to her erratic behavior. Sarah consented to a breathalyzer test, which revealed a blood alcohol content (BAC) of 0.10 percent, exceeding the legal limit of 0.08 percent specified under the law. As per NYS Vehicle and Traffic Law §1192, driving with a BAC of 0.08 percent or higher is prohibited. Consequently, Sarah was arrested for driving while intoxicated; per se.
    </span>`;
        break;

      case 3:
        title = `Aggravated driving while intoxicated. Per se.`;
        content = `
    <span className="detail">
    Emily was stopped by law enforcement officers for swerving on the road and nearly colliding with another vehicle. Suspecting intoxication, the officers conducted a series of field sobriety tests on Emily. Subsequent breathalyzer testing revealed her blood alcohol content (BAC) to be 0.20 percent, surpassing the legal limit of 0.18 percent specified under NYS Vehicle and Traffic Law §1192. As per the law, driving with a BAC of 0.18 percent or higher constitutes aggravated driving while intoxicated; per se. Consequently, Emily was arrested for this offense.
    </span>`;
        break;

      case 4:
        title = ` Aggravated driving while intoxicated. With a child.`;
        content = `
    <span className="detail">
    Michael was pulled over by police officers for weaving between lanes. Upon approaching the vehicle, the officers detected the smell of alcohol emanating from Michael's breath. Subsequent testing revealed his blood alcohol content (BAC) to be 0.15 percent, exceeding the legal limit, as outlined in NYS Vehicle and Traffic Law §1192. Additionally, Michael's six-year-old daughter was found in the backseat of the car. As per the law, driving under the influence with a child aged 15 or younger in the vehicle constitutes aggravated driving while intoxicated. Consequently, Michael was arrested for this offense.
    </span>`;
        break;

      case 5:
        title = `Driving while intoxicated.`;
        content = `
    <span className="detail">
    Alex was stopped by law enforcement officers after they observed him swerving across lanes and driving erratically. Upon approaching the vehicle, the officers noticed the smell of alcohol emanating from Alex's breath and observed other signs of intoxication. Alex consented to a field sobriety test, which he failed. Subsequent breathalyzer testing revealed his blood alcohol content (BAC) to be 0.12 percent, exceeding the legal limit specified under NYS Vehicle and Traffic Law §1192. As per the law, driving while in an intoxicated condition is prohibited. Consequently, Alex was arrested for this offense.
    </span>`;
        break;

      case 6:
        title = `Driving while ability impaired by drugs.`;
        content = `
    <span className="detail">
    Rachel was pulled over by law enforcement officers for speeding and making abrupt lane changes without signaling. Upon approaching the vehicle, the officers noticed Rachel's dilated pupils and erratic behavior, leading them to suspect drug impairment. Rachel admitted to recently using marijuana. Subsequent drug testing confirmed the presence of THC, the active compound in marijuana, in her system. As per NYS Vehicle and Traffic Law §1192, driving while the ability to operate a motor vehicle is impaired by the use of a drug is prohibited. Consequently, Rachel was arrested for this offense.
    </span>`;
        break;

      case 7:
        title = `Driving while ability impaired by the combined influence of drugs or of alcohol and any drug or drugs.`;
        content = `
    <span className="detail">
    David was stopped by law enforcement officers for driving well below the speed limit and weaving between lanes. Upon approaching the vehicle, the officers noticed David's bloodshot eyes and slurred speech, indicating possible impairment. David admitted to consuming alcohol earlier in the evening and taking prescription medication for pain. Suspecting impairment from the combined influence of alcohol and drugs, the officers conducted a series of field sobriety tests. David's performance on these tests indicated impairment. Subsequent testing revealed the presence of both alcohol and prescription drugs in his system. As per NYS Vehicle and Traffic Law §1192, driving while the ability to operate a motor vehicle is impaired by the combined influence of drugs or alcohol and any drug or drugs is prohibited. Consequently, David was arrested for this offense.
    </span>`;
        break;

      case 8:
        title = `Commercial motor vehicles; per se – level l.`;
        content = `
    <span className="detail">
    Brian, a truck driver, was pulled over by law enforcement officers during a routine traffic stop. Suspecting impairment, the officers conducted a breathalyzer test, which revealed Brian's blood alcohol content (BAC) to be 0.05 percent. As per NYS Vehicle and Traffic Law §1192, driving a commercial vehicle with a BAC between 0.04 percent and 0.06 percent is prohibited. Consequently, Brian was arrested for this offense. Additionally, since Brian was under 21 years old and operating a commercial vehicle, his BAC fell within the range of 0.02 percent to 0.04 percent, resulting in a separate violation according to another part of the law.
    </span>`;
        break;

      case 9:
        title = `Commercial motor vehicles; per se – level ll.`;
        content = `
    <span className="detail">
    Emily, a truck driver, was stopped by law enforcement officers for a routine inspection. During the inspection, the officers conducted a breathalyzer test, which revealed Emily's blood alcohol content (BAC) to be 0.07 percent. As per NYS Vehicle and Traffic Law §1192, driving a commercial vehicle with a BAC above 0.06 percent but below 0.08 percent is prohibited. Consequently, Emily was arrested for this offense. Additionally, it's important to note that this rule does not preclude someone from being charged with a violation under a different part of the law.
    </span>`;
        break;

      case 10:
        title = `Where applicable.`;
        content = `
    <span className="detail">
    Mark was stopped by law enforcement officers for drinking alcohol in a parking lot adjacent to a bar. Despite being on private property, Mark was informed by the officers that the law prohibiting alcohol consumption applies to public highways, private roads accessible to cars, and any parking lot meeting specific criteria, as outlined in NYS Vehicle and Traffic Law § 1225. As the parking lot in question met these criteria, Mark was charged with consuming alcohol in a prohibited area. It's important to note that this rule does not apply to private areas associated with one or two-family houses.
    </span>`;
        break;

      case 11:
        title = `Effect of prior out-of-state conviction.`;
        content = `
    <span className="detail">
    Jane, who recently moved to New York from another state, was pulled over for speeding. During the stop, the officer discovered that Jane had a prior conviction for driving under the influence of alcohol in her previous state. Under NYS law, out-of-state convictions for driving under the influence of alcohol or drugs are considered when determining penalties or administrative actions. If the same behavior would have been considered a misdemeanor or felony in New York, it counts as a previous conviction with corresponding penalties. However, if the behavior would have been a less serious offense in New York, it is still counted as a previous conviction but is treated as a less serious violation when determining penalties or required administrative actions. Therefore, Jane's prior out-of-state conviction was taken into account in deciding the appropriate penalty for her current offense.
    </span>`;
        break;

      case 12:
        title = `Effect of prior finding of having consumed alcohol.`;
        content = `
    <span className="detail">
    Tom, who was under the age of 21, was pulled over for running a red light. During the stop, the officer discovered that Tom had a prior finding of driving after consuming alcohol, as outlined in section 1194-a of the law. According to NYS law, such prior findings are treated the same as previous convictions when determining the duration of a driver's license suspension or revocation. However, this applies only if the new offense occurs before the expiration of the time limit for keeping records of the previous offense, as specified in paragraph (k) of subdivision one of section 201. Consequently, Tom's prior finding of having consumed alcohol played a role in deciding the appropriate penalty for his current offense.
    </span>`;
        break;

      case 13:
        title = `Conviction of a different charge.`;
        content = `
    <span className="detail">
    Lisa was stopped by law enforcement officers for speeding, which falls under subdivision two of the law. However, during the course of the investigation, it was discovered that Lisa was also driving while under the influence of alcohol, which falls under subdivision one of the law. Despite being initially charged with speeding, Lisa was found guilty of driving while intoxicated. According to NYS law, a driver can be convicted of breaking any of the laws mentioned in subdivisions one, two, or three of the law, regardless of the original charge. This conviction can occur regardless of whether the driver pleads guilty or not. Therefore, Lisa's conviction for driving while intoxicated was based on the evidence gathered during the stop, even though she was initially pulled over for speeding.
    </span>`;
        break;

      case 14:
        title = `Plea bargain limitations.`;
        content = `
    <span className="detail">
      (a) Jack was charged with driving under the influence of alcohol, which falls under subdivision two of the law. When presented with the option to plead guilty, Jack was required to plead guilty to at least one of the specific violations mentioned in subdivisions two, three, four, or four-a, unless the district attorney determined that the charge wasn't warranted. In such cases, Jack might plead guilty to a different charge. The court must provide an explanation for allowing this plea bargain.
    </span>
    <br></br>
    <span className="detail">
      (ii) Emma, who was charged with driving while intoxicated, a violation falling under subdivision two of the law, decided to plead guilty to a lesser offense, such as driving while ability impaired (subdivision one). As part of the plea bargain, Emma agreed to attend and complete an alcohol and drug rehabilitation program. However, the court has the discretion to waive this requirement if Emma is already enrolled in a treatment program or for other valid reasons.
    </span>
    <br></br>
    <span className="detail">
      (iii) Tyler, who was under 21 years old, was charged with a violation related to alcohol consumption while driving, falling under subdivision one of the law. When pleading guilty, Tyler was required to plead guilty to that specific violation unless otherwise determined by the district attorney. The court was obligated to document the reasons for accepting this plea.
    </span>
    <br></br>
    <span className="detail">
      (b) Hannah, a commercial vehicle driver, was charged with driving while intoxicated, a violation falling under subdivision one of the law. When pleading guilty, Hannah was required to plead guilty to at least one of the specific violations mentioned in subdivisions one or six, unless the district attorney determined that the charge wasn't valid. In such cases, Hannah might plead guilty to a different charge.
    </span>
    <br></br>
    <span className="detail">
      (c) For drivers under 21 charged with a specific violation related to alcohol consumption while driving, the court might decide to resolve the charge by allowing the commissioner to take action. By agreeing to this resolution, the driver waives their right to a hearing, and the agreement is treated as a guilty finding. The driver must sign a waiver in court, acknowledging the consequences, and the commissioner then takes the necessary actions.
    </span>
    <br></br>
    <span className="detail">
      (d) If someone is charged with a specific violation related to refusing a breathalyzer test (subdivision two-a), and they plead guilty, it must be to a related offense mentioned in subdivisions two, two-a, or three. They cannot plead guilty to something else unless agreed upon by the district attorney. The guilty plea might require completing an alcohol and drug rehabilitation program, but the court can waive this requirement for valid reasons. This applies even if there are circumstances that would typically prevent participation in the program. However, this doesn't automatically entitle them to a conditional license.
    </span>`;
        break;

      case 15:
        title = `No person other than an operator of a commercial motor vehicle may be charged with or convicted of a violation of subdivision five or six of this section.`;
        content = `
        <span className="detail">
        Sophia, who was not operating a commercial motor vehicle, was involved in an accident where she was found to have a blood alcohol content (BAC) exceeding the legal limit. The charge against Sophia fell under subdivisions five or six of the law. According to NYS Vehicle and Traffic Law, only operators of commercial motor vehicles can be charged with or convicted of violations falling under these subdivisions. Therefore, Sophia cannot be charged or convicted of the violation as she was not operating a commercial motor vehicle at the time of the offense.
        </span>`;
        break;

      case 16:
        title = `Driving while intoxicated or while ability impaired by drugs--serious physical injury or death or child in the vehicle.`;
        content = `
    <span className="detail">
      (a) Sarah was pulled over by law enforcement officers for driving erratically, and upon investigation, it was determined that she was driving under the influence of alcohol, falling under subdivisions two, two-a, three, four, or four-a of the law. As required by NYS Vehicle and Traffic Law, the police officer made a special note on the traffic ticket indicating "D" if someone other than Sarah was killed, "S.P.I." if someone else was seriously hurt as defined in the penal law, and "C.I.V." if a child 15 or younger was in the vehicle. However, the absence of these notes doesn't alter the driving charge itself.
    </span>
    <br></br>
    <span className="detail">
      (b) John, who was the parent of a child aged 15 or younger, was accused by law enforcement officers of a specific violation falling under paragraph (b) of subdivision two-a of the law. As required by the law, the officer must report this situation following the social services law.
    </span>`;
        break;
      default:
        content = "Something went wrong";
        break;
    }

    clickedStatus[index] = true;
    const allClicked = Object.values(clickedStatus).every((status) => status);
    if (allClicked) {
      console.warn("All face images are clicked!");
      setAllFaceImagesClicked(true);

      const h5pContentNavigate = document.getElementById(
        "h5p_content_navigate"
      );
      if (h5pContentNavigate) {
        h5pContentNavigate.removeAttribute("id");
      }

      document.querySelectorAll(".add-h5p-class-1").forEach((element) => {
        element.setAttribute("id", "h5p_content_navigate");
      });
    }

    handleImageClick(title, content);
  };

  const handleImageClick = (title, content) => {
    swal({
      title: title,
      content: {
        element: "div",
        attributes: {
          innerHTML: content,
        },
      },
    });
  };

  useEffect(() => {
    if (image1Clicked && image2Clicked && image3Clicked && image4Clicked) {
      console.warn("All decision Matrix images are visited.");
      setDecisionMatrixImagesClicked(true);
    }
  }, [image1Clicked, image2Clicked, image3Clicked, image4Clicked]);

  useEffect(() => {
    if (allFaceImagesClicked && decisionMatrixImagesClicked) {
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    }
  }, [allFaceImagesClicked, decisionMatrixImagesClicked]);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  return (
    <div className="module8_course module_font same_width_module mb-md-4">
      <Container>
        <div className="module_drugs">
          <Container>
            <div
              className="drugs_content"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <p className="mb-1">Module 8 - Part 2</p>
              <h1 className="mb-0">
                ALCOHOL, <br />
                OTHER DRUGS <br />& DRIVING
              </h1>
            </div>
          </Container>
        </div>

        <div className="nys_vehicle">
          <div className="vehicle_law">
            <Container>
              <h2
                className="vehicle_traffic"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                NYS Vehicle and Traffic Law §1198
                <span className="d-block">
                  Installation and operation of ignition interlock devices
                </span>
              </h2>

              <h4 className="risk_head">1. Applicability</h4>
              <p>
                This rule is for the whole state. It says that if a court orders
                someone to install and use an ignition interlock device in their
                car as a condition of probation or conditional discharge, they
                must do it for any car they own or drive.
              </p>

              <h4 className="risk_head">2. Requirements</h4>
              <p>
                (a) Apart from other penalties, the court must order anyone
                convicted of certain alcohol-related driving offenses (like
                subdivision two, two-a, or three of section 1192) to install and
                maintain an ignition interlock device in their car. This is a
                requirement if they are on probation or conditional discharge.
                However, the court can't let someone drive if their license has
                been revoked, except in specific situations.
              </p>
              <p>
                (b) Courts can change the probation conditions of people
                convicted of these offenses before this rule was effective. They
                can require these individuals to install and maintain the
                ignition interlock device, and then these individuals will be
                under the rules of this section.
              </p>
              <p>
                (c) This section doesn't allow a court to put someone on
                probation or conditional discharge just to make them follow
                these ignition interlock device rules. They can only do this if
                the person would normally be eligible for probation or
                conditional discharge.
              </p>

              <h4 className="risk_head">3. Conditions</h4>
              <p>
                (a) The commissioner can give a conditional license after
                license revocation to someone who's been convicted of certain
                driving offenses (subdivision two, two-a, or three of section
                1192) and is on probation or conditional discharge. This can
                happen if they've completed the minimum revocation period and
                can only drive a vehicle with an ignition interlock device.
                However, they can't get this license if they've violated certain
                driving or alcohol-related conditions during their revocation
                period. After their probation or conditional discharge ends,
                they can apply for a regular license.
              </p>
              <p>(b) This conditional license lets the holder drive:</p>

              <p className="ml-4">1. To and from work.</p>

              <p className="ml-4">2. For work if it requires driving.</p>

              <p className="ml-4">
                3. To and from school or vocational training.
              </p>

              <p className="ml-4">
                4. To and from court-ordered probation activities.
              </p>

              <p className="ml-4">
                5. To a motor vehicle office for license-related business.
              </p>

              <p className="ml-4">
                6. For a 3-hour period during the day when not working.
              </p>

              <p className="ml-4">
                7. To and from necessary medical appointments.
              </p>
              <p className="ml-4">
                8. To and from required alcohol and drug rehab program
                activities.
              </p>
              <p className="ml-4">
                9. To and from a place where their child is cared for, if needed
                for work or school.
              </p>
              <p>
                (c) The commissioner can revoke this conditional license for
                reasons like not following court conditions, getting convicted
                of traffic offenses (except parking-related), alcohol or drug
                offenses, or not maintaining the ignition interlock device.
              </p>
              <p>
                (d) The court can require, as part of probation or conditional
                discharge, the installation of an ignition interlock device in
                any vehicle the person owns or drives, especially if it's
                necessary for public safety. This doesn't affect any license
                suspension or revocation periods.
              </p>
              <p>
                (e) The court can also impose other probation or conditional
                discharge conditions like alcohol or drug treatment,
                restitution, and community service.
              </p>
              <p>
                (f) The commissioner will record that the person can only drive
                vehicles with an ignition interlock device.
              </p>

              <h4 className="risk_head_4">
                4. Proof of compliance and recording of condition
              </h4>
              <p>
                (a) When the court orders someone to use an ignition interlock
                device as part of probation or conditional discharge, the person
                must show proof that they're following this rule to both the
                court and the probation department (or whoever is supervising
                their probation or discharge). If they don't show proof and the
                court doesn't have a good reason for this (which must be
                recorded), the court can change or end their probation or
                conditional discharge. A valid reason might be that the person
                swears they don't own a car and won't drive any car during the
                time they're supposed to have the interlock device, except as
                the law might allow otherwise. The term "owner" is defined as in
                section 128 of this chapter.
              </p>
              <p>
                (b) When the court orders the ignition interlock device, it must
                inform the commissioner in a way the commissioner decides. Then,
                the commissioner will mark this requirement on the person’s
                driving record.
              </p>

              <h4 className="risk_head_4">
                5. Cost, installation and maintenance
              </h4>
              <p>
                (a) The person who is required to have an ignition interlock
                device must pay for its installation and upkeep. However, if the
                court decides that they can't afford it, the cost can be
                adjusted into a payment plan or even waived. If it's waived, the
                cost will be covered according to specific regulations or
                agreements set for providing the device. This cost is treated
                like a fine under the criminal procedure law. It's an additional
                cost and doesn't replace any other fines or charges that the
                person might have to pay under this law or other applicable
                laws.
              </p>
              <p>
                (b) The company that installs and services the ignition
                interlock device is responsible for putting it in, setting it up
                properly, and keeping it working.
              </p>

              <h4 className="risk_head">6. Certification</h4>
              <p>
                (a) The commissioner of the department of health will approve
                certain ignition interlock devices for use and will publish a
                list of these approved devices.
              </p>
              <p>
                (b) After talking with ignition interlock device manufacturers
                and the national highway traffic safety administration, the
                commissioner of health, along with the commissioner and the
                office of probation and correctional alternatives, will set
                regulations for the standards and use of these devices. These
                standards will include setting a minimum and maximum range for
                the device to work properly. The devices must:
              </p>
              <p className="ml-4">
                1. Be hard to bypass and not affect safe driving.
              </p>
              <p className="ml-4">
                2. Work accurately and reliably on their own.
              </p>
              <p className="ml-4">
                3. Be tamper-resistant and show if someone tries to tamper with
                them.
              </p>
              <p className="ml-4">4. Be easy to use for sober drivers.</p>
              <p className="ml-4">
                5. Require a proper breath sample or other accurate measure of
                blood alcohol content.
              </p>
              <p className="ml-4">
                6. Work well in different car environments.
              </p>
              <p className="ml-4">
                7. Match well with legal alcohol limits set by the court or law.
              </p>
              <p className="ml-4">
                8. Be made by a company with product liability insurance.
              </p>
              <p>
                (c) The health commissioner can also use guidelines, rules,
                regulations, studies, or tests from other states that have been
                used to certify or approve ignition interlock devices.
              </p>

              <h4 className="risk_head"> 7. Use of other vehicles</h4>
              <p>
                (a) If someone is legally required to only drive vehicles with
                an ignition interlock device (because of this law or the penal
                law), this rule applies to every vehicle they drive. This
                includes vehicles they lease, rent, or are loaned, not just
                their own.
              </p>
              <p>
                (b) No one should knowingly rent, lease, or lend a vehicle to
                someone who is only allowed to drive vehicles with an ignition
                interlock device, unless that vehicle has such a device. If
                someone has this driving restriction, they must tell anyone who
                rents, leases, or lends them a vehicle about this restriction.
              </p>
              <p>
                (c) Breaking either of these rules (in paragraph a or b) is
                considered a misdemeanor, which is a type of criminal offense.
              </p>

              <h4 className="risk_head">8. Employer vehicle.</h4>
              <p>
                Despite other rules in this section, if someone needs to drive a
                vehicle owned by their employer for work, they can do so without
                an ignition interlock device, but only for work purposes. This
                is allowed if:
              </p>
              <p className="ml-4">
                1. The employer knows that the employee's driving privilege is
                restricted under these laws.
              </p>
              <p className="ml-4">
                2. The employee has given the court and probation department
                written proof that the employer knows about the restriction and
                allows the employee to drive the business vehicle without the
                device for work reasons.
              </p>
              <p>
                The employee must inform the court and the probation department
                before they start driving the employer's vehicle.
              </p>
              <p>
                However, this exception doesn't apply if the business vehicle is
                owned by a business that the employee partly or fully owns or
                controls. This rule is only for driving the employer's vehicle
                as part of the job.
              </p>

              <h4 className="risk_head_4">
                9. Circumvention of interlock device
              </h4>
              <p>
                (a) If someone is only allowed to drive with an ignition
                interlock device because of legal restrictions, they can't ask
                or allow anyone else to blow into the device or start the car
                for them, so they can drive.
              </p>
              <p>
                (b) Nobody should blow into an ignition interlock device or
                start a car with such a device for someone who is only allowed
                to drive cars with the device.
              </p>
              <p>
                (c) It's not allowed for anyone to tamper with or bypass a
                working ignition interlock device.
              </p>
              <p>
                (d) If a court has ordered someone to use an ignition interlock
                device, they must not drive a car that doesn't have one.
              </p>
              <p>
                (e) Breaking any of these rules (in paragraphs a, b, c, or d) is
                a Class A misdemeanor, which is a type of criminal offense.
              </p>

              <h4 className="risk_head">10. Warning label. </h4>
              <p>
                The department of health shall design a warning label which the
                manufacturer shall affix to each ignition interlock device upon
                installation in the state. The label shall contain a warning
                that any person tampering, circumventing, or otherwise misusing
                the device is guilty of a misdemeanor and may be subject to
                civil liability.
              </p>
            </Container>
            <div className="drug_class mt-5 mb-5">
              <h4 className="mb-3">Sanctions cited are as follows:</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th className="p-3">
                      DWAI (Driving While Ability Impaired) 1192(1):{" "}
                      <span className="detail">
                        License suspended for 90 days. If under 21, license
                        revoked for one year. For a second offense within 5
                        years, license revoked for 6 months; if under 21,
                        revoked for one year or until age 21, whichever is
                        longer.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWI; per se (Driving While Intoxicated) 1192(2):{" "}
                      <span className="detail">
                        License revoked for 6 months. If under 21, revoked for
                        one year. For a second offense of 1192(2), (3), (4), or
                        (4-a) within 10 years, license revoked for 1 year; if
                        under 21, revoked for one year or until age 21,
                        whichever is longer.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      Aggravated DWI; per se and with a child 1192(2-a):{" "}
                      <span className="detail">
                        License revoked for 1 year for all ages. For a second
                        offense of 1192(2-a), with a prior conviction of
                        1192(2), (2-a), (3), (4), or (4-a) within 10 years,
                        license revoked for 18 months; if under 21, revoked for
                        18 months or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) with a
                        prior conviction for 1192(2-a) within 10 years, license
                        revoked for 18 months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWI (Common Law DWI) 1192(3):{" "}
                      <span className="detail">
                        License revoked for 6 months; if under 21, revoked for
                        one year or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) within 10
                        years, license revoked for 1 year; if under 21, revoked
                        for one year or until age 21, whichever is longer. For a
                        second offense of 1192(3) with a prior conviction for
                        1192(2-a) within 10 years, license revoked for 18
                        months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWAI - Drugs 1192(4):{" "}
                      <span className="detail">
                        License revoked for 6 months; if under 21, revoked for
                        one year or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) within 10
                        years, license revoked for 1 year; if under 21, revoked
                        for one year or until age 21, whichever is longer. For a
                        second offense of 1192(4) with a prior conviction for
                        1192(2-a) within 10 years, license revoked for 18
                        months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      DWAI - Combined Drugs or of Drugs and Alcohol 1192(4-a):{" "}
                      <span className="detail">
                        License revoked for 6 months; if under 21, revoked for
                        one year or until age 21, whichever is longer. For a
                        second offense of 1192(2), (3), (4), or (4-a) within 10
                        years, license revoked for 1 year; if under 21, revoked
                        for one year or until age 21, whichever is longer. For a
                        second offense of 1192(4-a), with a prior conviction for
                        1192(2-a) within 10 years, license revoked for 18
                        months.
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="p-3">
                      Under 21:{" "}
                      <span className="detail">
                        For any first offense of 1192, if the person is under 21
                        or adjudicated as a youthful offender, the court must
                        issue at least a one-year revocation. If under 21 and
                        the prior conviction is for 1192(2-a), aggravated DWI,
                        revocation is for 18 months or until age 21, whichever
                        is longer.
                      </span>
                    </th>
                  </tr>
                </thead>
              </table>

              <h4 className="mb-3">
                Why are penalties for drinking and driving so strict?
              </h4>
              <p>
                Drinking and driving is a hazardous combination. Consider these
                facts:
              </p>
              <ul className="p-0">
                <li>
                  One third of the fatalities in NYS involve impaired or
                  intoxicated drivers and pedestrians
                </li>
                <li>
                  With increased Blood Alcohol Content (BAC), crash risk
                  increases sharply. A driver with a BAC of 0.08 is four times
                  as likely to cause a crash as a driver who has not been
                  drinking, while a driver with a BAC of 0.16 is 25 times as
                  likely to do so.
                </li>
                <li>
                  Young drinking drivers are at the highest risk of all. Drivers
                  20 years old or younger are almost three times as likely to be
                  involved in alcohol related fatal crashes than other drivers.
                </li>
              </ul>

              <h4 className="mb-3">
                How much can I drink before it affects my driving ability?
              </h4>
              <p>
                Any amount of drinking will begin to affect your judgment and
                coordination, and reduce your ability to judge distances,
                speeds, and angles. The degree of impairment depends on four
                basic factors:
              </p>
              <ul className="p-0">
                <li>The amount you drink.</li>
                <li>
                  Whether you've eaten before or while drinking (food slows
                  absorption).
                </li>
                <li>Your body weight. </li>
                <li>The length of time spent drinking</li>
              </ul>

              <h4 className="mb-3">What is the quickest way to sober up?</h4>
              <p>
                The only way to reduce your BAC after drinking is to wait for
                your body to metabolize (eliminate) the alcohol – and that takes
                several hours. Your body metabolizes about one drink each hour.
                Coffee will not sober you up. Neither will a walk or a cold
                shower. They may make you feel more awake, but you will be just
                as impaired and it will be just as dangerous for you to drive.
              </p>

              <h4 className="mb-3">
                Are license penalties the same for all age groups?
              </h4>
              <p>
                No. If you are under 21 years old your driver license will be
                revoked for one year if you are convicted of any alcohol-related
                offense that occurred in New York State, or in any other state
                or a province of Canada. If you commit a second such offense
                while you are under 21, your license will be revoked for at
                least one year or until you are 21, whichever is longer.
              </p>
              <p>
                If you enroll in the Drinking Driver Program (DDP) and receive a
                conditional license, your license will remain in conditional
                status for the original full period of revocation.
              </p>
              <p>
                Your driver license or privilege of applying for a license will
                also be suspended if you are found guilty of using a New York
                State Driver License or Non-Driver Identification Card as proof
                of age to illegally purchase alcoholic beverages.
              </p>

              <h4 className="mb-3">
                What will happen to me if I am stopped by the police?
              </h4>
              <p>
                If you are stopped by a police officer who believes you are
                under the influence of alcohol or drugs, you may be required to
                take a "field sobriety test" which may include a
                breath-screening test for the presence of alcohol. If you are
                arrested, you will be asked to take a "chemical test" for BAC.
                You may also be fingerprinted.
              </p>
              <p>
                Driving While Intoxicated (DWI) is a crime. If you are
                convicted, you will face a substantial fine, a mandatory
                surcharge, license revocation, higher insurance premiums, and a
                possible jail sentence.
              </p>

              <h4 className="mb-3">
                What will happen if I refuse to take the chemical test?
              </h4>
              <p>
                If you refuse a chemical test for BAC (Blood Alcohol Content),
                your license will be suspended at arraignment in court, and
                revoked for at least one year (18 months for a commercial
                driver) at a Department of Motor Vehicles hearing. If you are
                age 21 or over, and you have a second refusal within five years
                of a previous refusal or alcohol conviction, your license is
                revoked for 18 months (permanent for a commercial driver).
              </p>
              <p>
                If you are under age 21, and you have a second refusal within
                five years of a previous refusal or alcohol conviction, your
                license is revoked for one year or until age 21, whichever is
                longer
              </p>
              <p>
                You will also be subject to a civil penalty of $500 (second
                offense, $750). A driver under 21 years old who refuses to take
                a chemical test under the Zero Tolerance Law is subject to a
                1-year license revocation and a $125 civil penalty. The
                penalties and fines for refusing to submit to a chemical test
                are separate from, and in addition to, the penalties and fines
                for alcohol or drug related convictions.
              </p>
            </div>
          </div>
        </div>

        <div className="leandra_law">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              LEANDRA’S LAW
            </h2>
            <p>
              Section 1192 of the NYS Vehicle and Traffic Law provides penalties
              when a person drives while intoxicated or while his or her ability
              is impaired by drugs or a combination of drugs and alcohol. In
              December, 2009, the Vehicle and Traffic Law, as well as NYS Penal
              and Executive laws, were amended to include increased penalties
              for a motorist who is in violation of these laws when a child 15
              years of age or younger is a passenger in the vehicle. This
              established “Leandra’s Law”, named in honor of 11-year old Leandra
              Rosado, who was killed in an automobile crash in October, 2009 in
              a vehicle driven by the intoxicated mother of her friend.
            </p>
            <h4 className="mt-3 mb-3">What is “Leandra’s Law”?</h4>
            <p>
              While all persons who drive while intoxicated pose a danger to
              others, the person who knowingly chooses to drive under the
              influence of alcohol or drugs with children in the car has made a
              conscious decision to put those children at risk of harm or death.
              An adult can take steps to avoid riding with an impaired driver.
              But a child may not have that choice, particularly when the driver
              is a parent or guardian. This amendment to Section 1192 created a
              new aggravated driving while intoxicated offense for a person who
              operates a vehicle while intoxicated by alcohol, impaired by
              drugs, or a combination of both{" "}
              <span>
                while a child who is 15 years of age or younger is a passenger
                in the vehicle.
              </span>
              This is a class E felony (maximum sentence of up to four years). A
              person convicted of aggravated DWI must install and maintain an
              ignition interlock device. The applicable Penal Law penalties are
              increased by one felony class level if the operator causes the
              death of, or serious physical injury to, such child. In addition,
              all individuals convicted of misdemeanor offenses (§1192 (2) and
              (3)) will be required to install and maintain an ignition
              interlock device.
            </p>
            <video
              id="video"
              width="100%"
              height="400"
              autoplay={true}
              onEnded={() => {
                sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
              }}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={Leandra} type="video/mp4" />
            </video>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              PROVISIONS OF LEANDRA’S LAW
            </h2>

            <ul className="p-0">
              <li>
                Driving under the influence with a child aged 15 or younger in
                the vehicle is considered a criminal offense under Leandra’s
                Law. Law enforcement must report incidents where a child or
                other individual in the vehicle has been killed, seriously
                injured, or was present during the arrest. If the driver is the
                child's parent, guardian, or custodian, the officer must notify
                the Department of Social Services.
              </li>

              <li>
                Driving while intoxicated with a child in the vehicle is
                classified as a Class E felony, carrying a potential prison
                sentence of up to four years. In addition to fines or
                imprisonment, the court must order probation or conditional
                discharge, requiring the installation of an ignition interlock
                device on any vehicle owned or operated by the offender for at
                least six months. Those charged with aggravated driving while
                intoxicated with a child cannot have the charge reduced to a
                non-criminal violation.
              </li>

              <Row>
                <Col md={6} xs={12}>
                  <li>
                    Amendments to the Penal Law ensure that those convicted of
                    driving while intoxicated or aggravated driving while
                    intoxicated face the full range of penalties associated with
                    misdemeanors or Class E felonies. They are also required to
                    undergo probation or conditional discharge to enforce the
                    use of ignition interlock devices.
                  </li>
                </Col>

                <Col md={6} xs={12}>
                  <div
                    className="provision_law_img"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <img src={ProvisionLaw} alt="Image" />
                  </div>
                </Col>
              </Row>

              <li>
                <h3>Additional amendments include:</h3>
                <ul>
                  <li>
                    <h4>
                      <b>Vehicular Assault (First Degree)</b>
                    </h4>
                    <p>
                      A Class D felony (up to seven years) now covers those who
                      commit second-degree vehicular assault, causing serious
                      injury to a child in the vehicle.
                    </p>
                  </li>

                  <li>
                    <h4>
                      <b>Aggravated Vehicular Assault</b>
                    </h4>
                    <p>
                      A Class C felony (up to 15 years) includes reckless
                      driving in addition to second-degree vehicular assault
                      causing serious injury to a child.
                    </p>
                  </li>

                  <li>
                    <h4>
                      <b>Vehicular Manslaughter (First Degree)</b>
                    </h4>
                    <p>
                      A Class C felony (up to 15 years) now applies to
                      individuals who commit second-degree vehicular
                      manslaughter, resulting in the death of a child in the
                      vehicle.
                    </p>
                  </li>

                  <li>
                    <h4>
                      <b>Aggravated Vehicular Homicide</b>
                    </h4>
                    <p>
                      A Class B felony (up to 25 years) now applies to those who
                      commit vehicular manslaughter, drive recklessly, and cause
                      the death of a child.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  Under the Executive Law, individuals released on parole or
                  conditional release following convictions for these offenses,
                  including aggravated driving while intoxicated with a child in
                  the vehicle, must install and maintain an ignition interlock
                  device as a condition of their release.
                </p>
              </li>
            </ul>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              EXAMPLES OF INDIVIDUALS IN VIOLATION OF LEANDRA'S LAW AND THEIR
              REPERCUSSIONS
            </h2>

            <ul className="p-0">
              <li>
                Example 1: Bob is a 32-year-old father who goes out for a night
                of drinking with friends. After drinking a few too many beers,
                he gets behind the wheel to drive home with his 8-year-old son,
                Timmy, in the car. A police officer pulls him over for a routine
                traffic stop and suspects that he may be under the influence of
                alcohol. The officer requests a chemical test, which shows that
                Bob's BAC is .08%. According to Leandra's Law, this is a
                violation and Bob is charged with aggravated DWI, which is a
                class E felony. He faces up to four years in prison and must
                install and maintain an ignition interlock device on his car.
              </li>

              <li>
                Example 2: Jane is a 25-year-old mother who goes out for a night
                of drinking with friends. After drinking a few too many
                cocktails, she gets behind the wheel to drive home with her
                4-year-old daughter, Emily, in the car. A police officer pulls
                her over for a routine traffic stop and suspects that she may be
                under the influence of alcohol. The officer requests a chemical
                test, which shows that Jane's BAC is .09%. According to
                Leandra's Law, this is a violation and Jane is charged with
                aggravated DWI, which is a class E felony. She faces up to four
                years in prison and must install and maintain an ignition
                interlock device on her car.
              </li>

              <li>
                Example 3: Mark is a 45-year-old businessman who goes out for a
                night of drinking with colleagues. After drinking a few too many
                glasses of wine, he gets behind the wheel to drive home with his
                15-year-old son, David, in the car. A police officer pulls him
                over for a routine traffic stop and suspects that he may be
                under the influence of alcohol. The officer requests a chemical
                test, which shows that Mark's BAC is .10%. According to
                Leandra's Law, this is a violation and Mark is charged with
                aggravated DWI, which is a class E felony. He faces up to four
                years in prison and must install and maintain an ignition
                interlock device on his car.
              </li>
            </ul>
          </Container>
        </div>

        <div className="class_law">
          <div className="traffic_law pb-5">
            <Container>
              <h2
                className="same_traffic_head"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                NYS VEHICLE AND TRAFFIC LAW §1192
                <span className="d-block">
                  OPERATING A MOTOR VEHICLE WHILE UNDER THE INFLUENCE OF ALCOHOL
                  OR DRUGS
                </span>
              </h2>
              <p className="main_para mt-3 mb-5">
                Section 1192 of the Vehicle and Traffic Law is very explicit in
                relation to drinking and driving, and the penalties are quite
                severe. A BAC of greater than .05 percent but not more than .07
                percent can be relevant evidence to determine alcohol
                impairment. A BAC of greater than .07 percent but less than .08
                percent can be primary evidence in determining whether the
                ability to operate a motor vehicle was impaired. Driving a motor
                vehicle with a blood alcohol content (BAC) of .08 percent or
                greater is prima facie evidence of intoxication.
              </p>
              <h3 className="h5p-content-note">
                Note: Click on each photo to view an example of a person
                committing each offense.
              </h3>
              <div id="h5p_content_navigate">
                <Container>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face1Image}
                        alt="Image 1"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(1)}
                        style={{ maxWidth: "100%" }}
                        data-index={1}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while ability impaired.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face2Image}
                        alt="Image 2"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(2)}
                        style={{ maxWidth: "100%" }}
                        data-index={2}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while intoxicated; per se.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face3Image}
                        alt="Image 3"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(3)}
                        style={{ maxWidth: "100%" }}
                        data-index={3}
                      />
                      <h2 className="title-text-wrapper">
                        Aggravated driving while intoxicated. Per se.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face4Image}
                        alt="Image 4"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(4)}
                        style={{ maxWidth: "100%" }}
                        data-index={4}
                      />
                      <h2 className="title-text-wrapper">
                        Aggravated driving while intoxicated. With a child.
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face5Image}
                        alt="Image 5"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(5)}
                        style={{ maxWidth: "100%" }}
                        data-index={5}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while intoxicated.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face6Image}
                        alt="Image 6"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(6)}
                        style={{ maxWidth: "100%" }}
                        data-index={6}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while ability impaired by drugs.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face18Image}
                        alt="Image 7"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(7)}
                        style={{ maxWidth: "100%" }}
                        data-index={7}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while ability impaired by the combined influence
                        of drugs or of alcohol and any drug or drugs.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face8Image}
                        alt="Image 8"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(8)}
                        style={{ maxWidth: "100%" }}
                        data-index={8}
                      />
                      <h2 className="title-text-wrapper">
                        Commercial motor vehicles; per se – level l.{" "}
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face9Image}
                        alt="Image 9"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(9)}
                        style={{ maxWidth: "100%" }}
                        data-index={9}
                      />
                      <h2 className="title-text-wrapper">
                        Commercial motor vehicles; per se – level ll.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face10Image}
                        alt="Image 10"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(10)}
                        style={{ maxWidth: "100%" }}
                        data-index={10}
                      />
                      <h2 className="title-text-wrapper">Where applicable.</h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face11Image}
                        alt="Image 11"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(11)}
                        style={{ maxWidth: "100%" }}
                        data-index={11}
                      />
                      <h2 className="title-text-wrapper">
                        Effect of prior out-of-state conviction.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face12Image}
                        alt="Image 12"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(12)}
                        style={{ maxWidth: "100%" }}
                        data-index={12}
                      />
                      <h2 className="title-text-wrapper">
                        Effect of prior finding of having consumed alcohol.
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face13Image}
                        alt="Image 13"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(13)}
                        style={{ maxWidth: "100%" }}
                        data-index={13}
                      />
                      <h2 className="title-text-wrapper">
                        Conviction of a different charge.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face14Image}
                        alt="Image 14"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(14)}
                        style={{ maxWidth: "100%" }}
                        data-index={14}
                      />
                      <h2 className="title-text-wrapper">
                        Plea bargain limitations.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face15Image}
                        alt="Image 15"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(15)}
                        style={{ maxWidth: "100%" }}
                        data-index={15}
                      />
                      <h2 className="title-text-wrapper">
                        No person other than an operator of a commercial motor
                        vehicle may be charged with or convicted of a violation
                        of subdivision five or six of this section.
                      </h2>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                      <img
                        src={face16Image}
                        alt="Image 16"
                        className="face-char-img"
                        onClick={() => handleFaceImageClick(16)}
                        style={{ maxWidth: "100%" }}
                        data-index={16}
                      />
                      <h2 className="title-text-wrapper">
                        Driving while intoxicated or while ability impaired by
                        drugs--serious physical injury or death or child in the
                        vehicle.
                      </h2>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
          </div>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              Penalties for alcohol or drug-related violations
            </h2>
            <p className="mt-3">
              Driving while intoxicated is a crime. Your judgment, coordination
              and ability to drive a vehicle change when you consume any amount
              of alcohol. The level of impairment depends on five conditions
            </p>
            <ul className="p-0">
              <li>the amount of alcohol you drink</li>
              <li>
                the amount of food you eat before or while you drink alcohol
              </li>
              <li>the length of time you drink alcohol</li>
              <li>your body weight</li>
              <li>your gender</li>
            </ul>
            <p>
              There is no quick method to become sober. The best method is to
              wait until your body absorbs the alcohol. The average rate that
              your body processes alcohol is approximately one drink per hour.
            </p>

            <h5 className="mt-5 mb-3">
              Types of alcohol and drug-related violations in New York State:
            </h5>

            <Col>
              <div className="definition_drug odd_color">
                <h6>Driving While Intoxicated (DWI)</h6>

                <p>
                  .08 Blood Alcohol Content (BAC) or higher or other evidence of
                  intoxication. For drivers of commercial motor vehicles: .04
                  BAC or other evidence of intoxication.{" "}
                </p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug even_color">
                <h6>Aggravated Driving While Intoxicated (Aggravated DWI)</h6>

                <p>.18 BAC or higher</p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug odd_color">
                <h6>
                  Driving While Ability Impaired by Alcohol (DWAI/Alcohol)
                </h6>

                <p>
                  More than .05 BAC but less than .07 BAC, or other evidence of
                  impairment.
                </p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug even_color">
                <h6>
                  Driving While Ability Impaired by a Single Drug other than
                  Alcohol (DWAI/Drug)
                </h6>
              </div>
            </Col>
            <Col>
              <div className="definition_drug odd_color">
                <h6>
                  Driving While Ability Impaired by a Combined Influence of
                  Drugs or Alcohol (DWAI/Combination)
                </h6>
              </div>
            </Col>
            <Col>
              <div className="definition_drug even_color">
                <h6>Chemical Test Refusal</h6>
                <p>
                  A driver who refuses to take a chemical test (normally a test
                  of breath, blood or urine).
                </p>
              </div>
            </Col>
            <Col>
              <div className="definition_drug odd_color">
                <h6>Zero Tolerance Law</h6>
                <p>
                  A driver who is less than 21 years of age and who drives with
                  a .02 BAC to .07 BAC violates the Zero Tolerance Law.
                </p>
              </div>
            </Col>

            <p className="mt-5 mb-5">
              In New York State, the penalties for an alcohol or drug-related
              violation include the loss of driving privileges, fines, and a
              possible jail term.
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>VIOLATION</th>
                  <th>MANDATORY FINE</th>
                  <th>MAXIMUM JAIL TERM</th>
                  <th>MANDATORY DRIVER LICENSE ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Aggravated Driving While Intoxicated (AGG DWI)</td>
                  <td>$1,000 - $2,500</td>
                  <td>1 year</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Second AGG DWI in 10 years (E felony)</td>
                  <td>$1,000 - $5,000</td>
                  <td>4 years</td>
                  <td>Revoked for at least 18 months</td>
                </tr>
                <tr>
                  <td>Third AGG DWI in 10 years (D felony)</td>
                  <td>$2,000 - $10,000</td>
                  <td>7 years</td>
                  <td>Revoked for at least 18 months</td>
                </tr>
                <tr>
                  <td>
                    Driving While Intoxicated (DWI) or Driving While Impaired by
                    a Drug (DWAI-Drug)
                  </td>
                  <td>$500 - $1,000</td>
                  <td>1 year</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>Second DWI/DWAI-Drug violation in 10 years (E felony)</td>
                  <td>$1,000 - $5,000</td>
                  <td>4 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>
                    Third DWI or DWAI-Drug violation in 10 years (D felony)
                  </td>
                  <td>$2,000 - $10,000</td>
                  <td>7 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>
                    Driving While Ability Impaired by a Combination of
                    Alcohol/Drugs (DWAI-Combination)
                  </td>
                  <td>$500 - $1,000</td>
                  <td>1 year</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>Second DWAI-Combination in 10 years (E felony)</td>
                  <td>$1,000 - $5,000</td>
                  <td>4 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Third DWAI-Combination in 10 years (D felony)</td>
                  <td>$2,000 - $10,000</td>
                  <td>7 years</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Driving While Ability Impaired by Alcohol (DWAI)</td>
                  <td>$300 - $500</td>
                  <td>15 days</td>
                  <td>Suspended for 90 days</td>
                </tr>
                <tr>
                  <td>Second DWAI violation in 5 years</td>
                  <td>$500 - $750</td>
                  <td>30 days</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>
                    Third or subsequent DWAI within 10 years (Misdemeanor)
                  </td>
                  <td>$750 - $1,500</td>
                  <td>180 days</td>
                  <td>Revoked for at least six months</td>
                </tr>
                <tr>
                  <td>Zero Tolerance Law</td>
                  <td>
                    $125 civil penalty and $100 fee to terminate suspension
                  </td>
                  <td>None</td>
                  <td>Suspended for six months</td>
                </tr>
                <tr>
                  <td>Second Zero Tolerance Law</td>
                  <td>$125 civil penalty and $100 re-application fee</td>
                  <td>None</td>
                  <td>Revoked for one year or until age 21</td>
                </tr>
                <tr>
                  <td>Chemical Test Refusal</td>
                  <td>$500 civil penalty ($550 for commercial drivers)</td>
                  <td>None</td>
                  <td>
                    Revoked for at least one year, 18 months for commercial
                    drivers.
                  </td>
                </tr>
                <tr>
                  <td>
                    Chemical Test Refusal within five years of a previous
                    DWI-related charge/Chemical Test Refusal
                  </td>
                  <td>$750 civil penalty</td>
                  <td>None</td>
                  <td>
                    Revoked for at least 18 months, one-year or until age 21 for
                    drivers under age 21, permanent CDL revocation for
                    commercial drivers.
                  </td>
                </tr>
                <tr>
                  <td>Chemical Test Refusal - Zero Tolerance Law</td>
                  <td>$300 civil penalty and $100 re-application fee</td>
                  <td>None</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>
                    Chemical Test Refusal - Second or subsequent Zero Tolerance
                    Law
                  </td>
                  <td>$750 civil penalty and $100 re-application fee</td>
                  <td>None</td>
                  <td>Revoked for at least one year</td>
                </tr>
                <tr>
                  <td>Driving Under the Influence (Out-of-State)</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>
                    Revoked for at least 90 days. If less than 21 years of age,
                    revoked at least one year.
                  </td>
                </tr>
                <tr>
                  <td>
                    Driving Under the Influence (Out-of State) with any previous
                    alcohol-drug violation
                  </td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>
                    Revoked for at least 90 days (longer term with certain prior
                    offenses). If less than 21 years of age, revoked at least
                    one year or until age 21 (longest term).
                  </td>
                </tr>
              </tbody>
            </table>

            <h5 className="mt-5 mb-3">Additional penalties</h5>
            <ul className="p-0">
              <li>
                Greater penalties can also apply for multiple alcohol or drug
                violations within a 25-year period
              </li>
              <li>
                Surcharges are added to alcohol-related misdemeanors ($260) and
                felonies (generally $400, but varies slightly depending on court
                of conviction)
              </li>
              <li>
                Three or more alcohol or drug-related convictions or refusals
                within 10 years can result in permanent revocation, with a
                waiver request permitted after at least 5 years
              </li>
              <li>
                A driver with an Aggravated DWI violation conviction within the
                prior 10 years will receive a minimum 18-month revocation if
                convicted of DWI, DWAI/Drugs or DWAI/Combination. Also, a driver
                with a prior DWI, Aggravated DWI, DWAI/Drugs or DWAI/Combination
                with the prior 10 years will receive a minimum 18-month
                revocation
              </li>
              <li>
                A driver convicted of an Aggravated DWI, DWI, DWAI/Drug,
                DWAI/combination, vehicular assault and aggravated vehicular
                assault, or vehicular manslaughter and vehicular homicide three
                or more times in the preceding 15 year period is guilty of a
                Class D felon
              </li>
              <li>
                In addition to the penalties for alcohol and drug related
                violations of the NYS Vehicle and Traffic Law, there are
                penalties set forth in the NYS Penal Law relating to driving
                while intoxicated or impaired by drugs.
              </li>
            </ul>

            <h5 className="mt-5 mb-3">
              Vehicular assault in the second degree
            </h5>
            <p>Section 120.03 of the Penal Law </p>
            <p>
              A person commits the crime of vehicular assault in the second
              degree if they seriously injure someone else because they were
              driving under the influence. This includes:
            </p>
            <ul className="p-0">
              <li>
                Driving while intoxicated (DWI) as per Vehicle and Traffic Law
                §1192(2) – driving with a blood alcohol level above the legal
                limit.
              </li>
              <li>
                Driving while intoxicated (DWI) as per §1192(3) – driving under
                the influence of alcohol.
              </li>
              <li>Driving while ability impaired by drugs as per §1192(4).</li>
              <li>
                Driving while ability impaired by a combination of drugs or
                alcohol and drugs as per §1192(4-a).
              </li>
            </ul>
            <p>
              The injury must be a direct result of the driver being intoxicated
              or impaired.
            </p>
            <p>
              Vehicular assault in the second degree is a class E felony. The
              maximum sentence for this crime can be up to 4 years in prison.
            </p>
          </Container>
        </div>

        <div className="provision_law pb-5">
          <Container>
            <h2
              className="same_alcohol_head"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              Crimes Behind the Wheel: Understanding NY’s Laws on Vehicular
              Assault and Manslaughter
            </h2>
            <h5 className="mt-5 mb-3">
              What Happens When Drunk Driving Leads to Injury?
            </h5>
            <p>
              <b>Vehicular Assault in the Second Degree (Penal Law §120.03)</b>
            </p>
            <p>
              Driving under the influence is not just a poor choice; it can lead
              to devastating consequences. When a driver is intoxicated or
              impaired by drugs and causes serious injury to another person,
              they may be charged with{" "}
              <b>vehicular assault in the second degree</b>. This charge applies
              to various forms of impairment:
            </p>
            <ul className="p-0">
              <li>
                Driving with a <b>Blood Alcohol Content (BAC)</b> above the
                legal limit, which is 0.08% in New York (§1192(2)).
              </li>
              <li>
                Driving while under the influence of alcohol to a degree that
                impairs the ability to drive safely (§1192(3)).
              </li>
              <li>Driving while impaired by drugs (§1192(4)).</li>
              <li>
                Driving while impaired by both drugs and alcohol (§1192(4-a)).
              </li>
            </ul>
            <p>
              The injury must be directly caused by the driver’s impaired
              condition. This offense is classified as a <b>Class E Felony</b>,
              which is the lowest level of felony but still carries significant
              penalties. If convicted, the driver faces up to{" "}
              <b>4 years in prison</b>.
            </p>
            <p>
              It’s important to understand that New York law treats any
              impairment seriously, whether it involves alcohol, drugs, or a
              combination of both. Even if a driver believes they are only
              "slightly" impaired, the law makes no distinction once serious
              injury occurs.
            </p>

            <p>
              <strong>Example Scenario:</strong>
            </p>
            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages1}
                  alt="Example 1"
                  className="example-scenario-1"
                />
              </Col>
              <Col md={6}>
                <p>
                  Imagine a driver who has had several drinks at a party. They
                  feel "buzzed" but decide they are capable of driving home. On
                  the way, they fail to stop at a red light and collide with
                  another vehicle, seriously injuring the other driver. Because
                  the driver’s BAC was found to be over the legal limit, they
                  are charged with <b>vehicular assault in the second degree</b>
                  .
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              Is Your Blood Alcohol Level Above 0.18? You Could Face a Higher
              Charge
            </h5>
            <p>
              <b>Vehicular Assault in the First Degree (Penal Law §120.04)</b>
            </p>
            <p>
              For those who believe that driving with a higher <b>BAC</b> is not
              a big deal, think again. When a driver’s BAC is
              <b> 0.18 or more</b>, the charge can escalate from second-degree
              to <b>vehicular assault in the first degree</b>. This elevated
              charge occurs under specific aggravating circumstances, which
              include:
            </p>
            <ul className="p-0">
              <li>
                Driving with a <b>BAC of 0.18 or higher</b>.
              </li>
              <li>
                Driving with a <b>suspended or revoked license</b>.
              </li>
              <li>
                Violating <b>Leandra’s Law</b>, which involves driving
                intoxicated with a child under 15 in the vehicle.
              </li>
            </ul>
            <p>
              The penalties are much harsher because the driver’s condition is
              seen as more reckless, given the level of impairment.{" "}
              <b>Vehicular assault in the first degree</b> is a Class D Felony,
              which carries a maximum sentence of <b>up to 7 years in prison</b>
              .
            </p>

            <h5 className="mt-5 mb-3">Leandra’s Law and Its Impact:</h5>
            <p>
              Enacted in New York in 2009, <b>Leandra’s Law</b> is a strict
              provision that automatically elevates charges when a child under
              15 is in the car during a DWI incident. This law was named after
              11-year-old Leandra Rosado, who tragically died in a car accident
              while a passenger in an intoxicated driver’s vehicle. The law
              reflects society’s increasing concern over endangering children’s
              lives due to drunk driving.
            </p>
            <p>
              <strong>Example Scenario:</strong>
            </p>
            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages2}
                  alt="Example 2"
                  className="example-scenario-2"
                />
              </Col>
              <Col md={6}>
                <p>
                  Consider a driver who has been out with friends and ends up
                  with a <b>BAC of 0.19</b> after drinking heavily. They decide
                  to drive home with their two children in the backseat. On the
                  way, they lose control and hit a pedestrian, causing serious
                  injury. Because of their high BAC and the presence of
                  children, they are charged with{" "}
                  <b>vehicular assault in the first degree</b> under Leandra’s
                  Law, facing up to <b>7 years in prison</b>.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              Reckless Driving Can Mean Much More than a Ticket
            </h5>
            <p>
              <b>Aggravated Vehicular Assault (Penal Law §120.04-a)</b>
            </p>
            <p>
              Recklessness on the road—such as speeding, tailgating, or weaving
              through traffic—is already dangerous. But when reckless driving is
              combined with intoxication or impairment, it can lead to
              <b> aggravated vehicular assault</b>, a more severe charge. The
              law defines reckless driving as acting with a gross disregard for
              the safety of others.
            </p>
            <p>
              <b>
                Several factors can elevate the offense to aggravated vehicular
                assault:
              </b>
            </p>
            <ul className="p-0">
              <li>
                <b>Reckless driving</b> that endangers others while impaired.
              </li>
              <li>
                Having a <b>BAC of 0.18 or higher</b>.
              </li>
              <li>
                Driving with a <b>suspended license</b>.
              </li>
              <li>Causing serious physical injury to more than one person.</li>
              <li>
                <b>Prior convictions for DWI</b>.
              </li>
            </ul>
            <p>
              This offense is a <b>Class C Felony</b>, meaning it carries the
              possibility of <b>up to 15 years in prison</b>. The law takes into
              account not only the driver’s impairment but also the recklessness
              of their behavior.
            </p>
            <p>
              <strong>Example Scenario:</strong>
            </p>

            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages3}
                  alt="Example 3"
                  className="example-scenario-3"
                />
              </Col>
              <Col md={6}>
                <p>
                  A driver with a <b>BAC of 0.20</b> is speeding on the highway,
                  swerving between lanes. They crash into another car, causing
                  multiple injuries to the occupants. Due to their reckless
                  driving and high BAC, the charge is elevated to{" "}
                  <b>aggravated vehicular assault</b>, with the potential for{" "}
                  <b>15 years in prison</b>.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">When Carelessness Leads to Death</h5>
            <p>
              <b>Criminally Negligent Homicide (Penal Law §125.10)</b>
            </p>
            <p>
              Sometimes, a driver’s carelessness can lead to tragic results, and
              when this happens, <b>criminally negligent homicide</b> charges
              may apply. This offense involves causing the death of another
              person through negligence, rather than intent. The law defines
              criminal negligence as failing to perceive a substantial and
              unjustifiable risk that results in death. This means the driver
              did not act with intent to kill, but their failure to act
              responsibly led to a fatal outcome.
            </p>
            <p>
              Criminally negligent homicide is a <b>Class E Felony</b>, with a
              potential sentence of <b>up to 4 years in prison</b>. The key
              difference between criminally negligent homicide and other charges
              like manslaughter is the absence of intent. However, the
              consequences are no less severe for the families of victims.
            </p>
            <p>
              <strong>Example Scenario:</strong>
            </p>

            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages4}
                  alt="Example 4"
                  className="example-scenario-4"
                />
              </Col>
              <Col md={6}>
                <p>
                  A driver, distracted by their phone, veers off the road and
                  strikes a pedestrian. The pedestrian dies from their injuries.
                  Although the driver did not intend to cause harm, their
                  failure to pay attention constitutes{" "}
                  <b>criminal negligence</b>, leading to{" "}
                  <b>criminally negligent homicide charges</b>.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              The Price of a Fatal Mistake on the Road
            </h5>
            <p>
              <b>
                Vehicular Manslaughter in the Second Degree (Penal Law §125.12)
              </b>{" "}
            </p>
            <p>
              When a fatal accident is caused by a driver under the influence of
              alcohol or drugs, the charge often escalates to{" "}
              <b>vehicular manslaughter in the second degree</b>. This charge is
              used when the driver’s actions, whether due to impairment or
              negligence, directly result in someone’s death. For example, this
              could include:
            </p>
            <ul className="p-0">
              <li>
                Driving with a <b>BAC over 0.08%</b>.
              </li>
              <li>
                Driving under the influence of <b>drugs</b>.
              </li>
              <li>
                Operating a vehicle with dangerous cargo or materials while
                impaired.
              </li>
            </ul>
            <p>
              This offense is classified as a <b>Class D Felony</b> and carries
              a maximum sentence of <b>up to 7 years in prison</b>. Unlike
              criminally negligent homicide,{" "}
              <b>vehicular manslaughter in the second degree </b>
              specifically involves impairment by drugs or alcohol.
            </p>
            <p>
              <strong>Example Scenario:</strong>
            </p>
            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages5}
                  alt="Example 5"
                  className="example-scenario-5"
                />
              </Col>
              <Col md={6}>
                <p>
                  A truck driver carrying flammable materials has been drinking
                  and is found to have a BAC of 0.12. They lose control of their
                  vehicle, causing an explosion that results in a bystander’s
                  death. The driver is charged with vehicular manslaughter in
                  the second degree due to their intoxication and the hazardous
                  nature of the accident.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              When the Stakes Are Even Higher: What Makes Vehicular Manslaughter
              First Degree?
            </h5>
            <p>
              <b>
                Vehicular Manslaughter in the First Degree (Penal Law §125.13)
              </b>
            </p>
            <p>
              Certain aggravating circumstances can elevate a{" "}
              <b>second-degree vehicular manslaughter charge</b> to{" "}
              <b>first-degree vehicular manslaughter</b>. This is more severe,
              as the driver’s behavior shows heightened recklessness or repeated
              offenses. These factors include:
            </p>
            <ul className="p-0">
              <li>
                Driving with a <b>suspended or revoked license</b>.
              </li>
              <li>
                Having a <b>prior DWI conviction</b> within the last 10 years.
              </li>
              <li>
                <b>Causing the death of multiple people.</b>
              </li>
              <li>
                Driving with a <b>BAC over 0.18%</b>.
              </li>
            </ul>
            <p>
              As a{" "}
              <b>Class C Felony, vehicular manslaughter in the first degree </b>
              can lead to <b>up to 15 years in prison</b>.
            </p>
            <p>
              <strong>Example Scenario:</strong>
            </p>

            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages6}
                  alt="Example 6"
                  className="example-scenario-6"
                />
              </Col>
              <Col md={6}>
                <p>
                  A driver with a prior DWI conviction is caught driving with a
                  <b> BAC of 0.20</b>, leading to a fatal crash that claims the
                  lives of two pedestrians. The driver faces{" "}
                  <b>vehicular manslaughter in the first degree</b>, carrying a
                  much harsher sentence than the second-degree charge.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              The Most Serious Charge: Aggravated Vehicular Homicide
            </h5>
            <p>
              <b>Aggravated Vehicular Homicide (Penal Law §125.14)</b>
            </p>
            <p>
              The most severe of all vehicular crimes,{" "}
              <b>aggravated vehicular homicide</b> involves recklessness,
              intoxication, and fatal consequences. The charge is escalated when
              multiple aggravating factors are present, such as:
            </p>
            <ul className="p-0">
              <li>
                <b>Reckless driving</b> combined with a <b>BAC over 0.18%</b>.
              </li>
              <li>
                Driving with a <b>suspended license</b> due to prior violations.
              </li>
              <li>
                <b>Causing the death of more than one person.</b>
              </li>
            </ul>
            <p>
              This offense is a <b>Class B Felony</b>, carrying the harshest
              penalties, including <b>up to 25 years in prison</b>. Aggravated
              vehicular homicide is reserved for the most egregious cases of
              reckless and impaired driving that lead to fatal outcomes.
            </p>
            <p>
              <strong>Example Scenario:</strong>
            </p>

            <Row>
              <Col md={6}>
                <img
                  src={exampleScenarioImages7}
                  alt="Example 7"
                  className="example-scenario-7"
                />
              </Col>
              <Col md={6}>
                <p>
                  A driver with a <b>BAC of 0.22</b> and a suspended license
                  causes a multi-car pileup, resulting in several deaths. Given
                  the recklessness and multiple fatalities, they are charged
                  with
                  <b> aggravated vehicular homicide</b>, facing{" "}
                  <b>25 years in prison</b>.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              Ignition Interlocks: A Lifeline or a Burden?
            </h5>
            <p>
              <b>
                Driving While Intoxicated Offenses (Penal Law §§60.36 & 60.21)
              </b>{" "}
            </p>
            <p>
              For drivers convicted of <b>DWI offenses</b>, courts often require
              the installation of an <b>ignition interlock device</b> as part of
              the sentence. This device prevents the vehicle from starting
              unless the driver provides a breath sample that registers a{" "}
              <b>BAC below the legal limit</b>. It serves as a preventive
              measure to stop repeat offenses. Courts may impose this condition
              alongside other penalties, such as fines or imprisonment
            </p>

            <p>
              <strong>Example Scenario:</strong>
            </p>
            <Row>
              <Col md={6} className="example-scenario-8-div">
                <img
                  src={exampleScenarioImages8}
                  alt="Example 8"
                  className="example-scenario-8"
                />
              </Col>
              <Col md={6}>
                <p>
                  A driver convicted of a DWI must install an{" "}
                  <b>ignition interlock device</b> in their car for one year.
                  They must blow into the device before starting the vehicle,
                  ensuring they are sober. Failing to comply with this
                  requirement can result in additional penalties or extensions
                  of the interlock period.
                </p>
              </Col>
            </Row>

            <h5 className="mt-5 mb-3">
              How Executive Law Amends Parole Conditions for Serious Offenders
            </h5>
            <p>
              <b>Related Executive Law Amendment (2009)</b>
            </p>
            <p>
              For offenders convicted of serious vehicular crimes such as
              <b> vehicular assault, vehicular manslaughter</b>, or{" "}
              <b>felony DWI</b>, the law imposes strict parole conditions. This
              includes the mandatory installation of an{" "}
              <b>ignition interlock device</b> for the entire duration of their
              parole or conditional release. This ensures that repeat offenders
              are closely monitored to prevent further violations. Additionally,
              drivers whose licenses are revoked are prohibited from driving any
              vehicle during their parole period.
            </p>
          </Container>
        </div>

        <div className="class_law">
          <div className="traffic_law">
            <Container>
              <h2
                className="high_risks_head"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                THE HIGH RISKS OF USING OTHER DRUGS
              </h2>

              <Row>
                <Col md={8} xs={12}>
                  <div className="definition_drug">
                    <h5>DEFINITION OF A DRUG:</h5>

                    <p>
                      Any taken substance taken that will produce a
                      physiological effect when ingested or otherwise introduced
                      into the body.
                    </p>
                    <p>There are many drugs that alter perception. </p>
                  </div>
                </Col>

                <Col md={4} xs={12}>
                  <div
                    className="risk_drug pt-md-5 pt-lg-0"
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                  >
                    <img src={Risk} alt="Image" />
                  </div>
                </Col>
              </Row>
              <div className="tpyes_drug">
                <Row>
                  <Col md={12} xs={12}>
                    <h6>Types of drugs:</h6>
                  </Col>

                  <Col md={3} xs={6}>
                    <div
                      className="drug_even drug_content_div"
                      data-aos="zoom-in-right"
                      data-aos-duration="2000"
                    >
                      <p>HERBAL</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div
                      className="drug_odd drug_content_div"
                      data-aos="zoom-in-right"
                      data-aos-duration="2000"
                    >
                      <p>OVER THE COUNTER</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div
                      className="drug_even drug_content_div"
                      data-aos="zoom-in-right"
                      data-aos-duration="2000"
                    >
                      <p>PRESCRIPTION</p>
                    </div>
                  </Col>

                  <Col md={3} xs={6}>
                    <div
                      className="drug_odd drug_content_div"
                      data-aos="zoom-in-right"
                      data-aos-duration="2000"
                    >
                      <p>ILLICIT</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div className="drug_class mt-5">
            <Container>
              <h4>The classes of drugs:</h4>

              <ul className="p-0">
                <li>
                  Stimulants (nicotine, caffeine, cocaine/crack, and
                  amphetamines)
                </li>

                <li>Depressants</li>

                <li>
                  Hallucinogens (ex. LSD, psilocybin, ecstasy, and peyote)
                </li>

                <li>Narcotics</li>

                <li>Tranquilizers</li>

                <li>
                  Marijuana is a distinct category and has properties and
                  effects similar to depressants and stimulants
                </li>

                <li>
                  Some over-the-counter drugs may be as dangerous as
                  prescription and illegal drugs. For example, antihistamine
                  contained in many cold remedies, cough medicines, allergy
                  medications and decongestants may cause drowsiness.
                </li>
              </ul>

              <h5 className="mt-3 mb-4">
                Read the precautions on the labels of all substances prior to
                using them, especially if you intend to drive.
              </h5>

              <p>
                “Potentiation” or “synergism” are terms used to describe the
                combined effect of two drugs that interact differently than the
                sum of their individual effects. The combined effect is often
                unpredictable.
              </p>
              <p>
                Tolerance is the body’s ability to withstand the effects of
                certain drugs. As a result, the user must take greater amounts
                to sustain a predictable "high".
              </p>
            </Container>
          </div>

          <div className="drug_class mt-5">
            <Container>
              <h4>HOW CERTAIN DRUGS AFFECT DRIVING:</h4>

              <ul className="p-0">
                <li>
                  Alcohol is the most widely used and abused drug in the world
                  and is a depressant. When consumed, it can impair judgment,
                  slow reflexes and hamper eye-hand coordination. If a person is
                  stopped by the police while driving under the influence of
                  alcohol, they may face penalties such as fines, license
                  revocation, higher insurance premiums and a possible jail
                  sentence.
                </li>

                <li>
                  Oxycontin is a commonly abused narcotic and is a pain killer.
                  When consumed, it can cause drowsiness and impair judgment. If
                  a person is stopped by the police while driving under the
                  influence of Oxycontin, they may face penalties such as fines,
                  license revocation, higher insurance premiums and a possible
                  jail sentence.
                </li>

                <li>
                  Marijuana is a commonly used illegal drug and has properties
                  and effects similar to depressants and stimulants. When
                  consumed, it can impair judgment, slow reflexes and hamper
                  eye-hand coordination. If a person is stopped by the police
                  while driving under the influence of marijuana, they may face
                  penalties such as fines, license revocation, higher insurance
                  premiums and a possible jail sentence.
                </li>

                <li>
                  Cocaine/crack is a commonly used illegal drug and a stimulant.
                  When consumed, it can cause irritability, lack of
                  concentration, and an overestimation of abilities. If a person
                  is stopped by the police while driving under the influence of
                  cocaine/crack, they may face penalties such as fines, license
                  revocation, higher insurance premiums and a possible jail
                  sentence.
                </li>

                <li>
                  Psilocybin, also known as magic mushrooms, is a naturally
                  occurring psychedelic substance that can cause hallucinations,
                  altered perception of time, and changes in mood and thought.
                  When consumed, it can impair a person's ability to drive,
                  making it difficult to focus and react to the road. A person
                  under the influence of psilocybin may also experience a
                  distorted sense of reality, which can be dangerous when
                  operating a vehicle.
                </li>

                <li>
                  Ecstasy, also known as MDMA, is a synthetic drug that acts as
                  both a stimulant and a hallucinogen. It can cause increased
                  energy, euphoria, and emotional warmth, but it can also impair
                  a person's ability to drive. Ecstasy can cause confusion,
                  disorientation, and an altered perception of time, which can
                  make it difficult to react to unexpected situations on the
                  road.
                </li>

                <li>
                  LSD, also known as acid, is a powerful psychedelic drug that
                  can cause hallucinations, altered perception of time, and
                  changes in mood and thought. When consumed, it can impair a
                  person's ability to drive, making it difficult to focus and
                  react to the road. A person under the influence of LSD may
                  also experience a distorted sense of reality, which can be
                  dangerous when operating a vehicle. Additionally, since the
                  effects of LSD can last for several hours, it can be dangerous
                  to drive even after the person believes the effects have worn
                  off.
                </li>

                <li>
                  It is important to note that certain over-the-counter drugs
                  may also cause drowsiness and impair driving abilities, and it
                  is essential to read the precautions on the labels of all
                  substances prior to using them, especially if you intend to
                  drive. Also, It is also important to note that the combined
                  effect of two drugs that interact differently than the sum of
                  their individual effects is often unpredictable, which is
                  known as "Potentiation" or "Synergism".
                </li>
              </ul>

              <p>
                The decision to drink and subsequently to drive is based on two
                variables: THE INDIVIDUAL and THE SITUATION. Each of these
                variables has two categories: low risk and high risk. Simply
                put, a LOW RISK PERSON, in a LOW RISK SITUATION can make the
                decision to drink moderately; a HIGH-RISK PERSON, in a HIGH-RISK
                SITUATION should decide not to drink.
              </p>
              <p>
                The decision to drive should be made ONLY when one’s ability and
                judgment is not impaired by alcohol or other drugs. Note that
                there has been no “safe” level of consumption established for
                substances such as marijuana or cocaine.
              </p>

              <div className="table_title">
                <h5>Decision Matrix</h5>
              </div>
              <table className="table add-h5p-class-1">
                <thead>
                  <tr>
                    <th className="title">Individuals</th>
                    <th className="title">Situations</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>People who are low risk:</p>
                      <img
                        src={peopleWhoAreLowRiskImage}
                        height="250px"
                        alt="Image"
                        onClick={() =>
                          handleImage1Click(
                            `
                  <ul className="swal-content-list">
                        <li style="text-align: left;">are in good physical health</li>
                        <li style="text-align: left;">have no emotional disorders</li>
                        <li style="text-align: left;">
                          have no history of alcohol-related problems or of
                          alcoholism in their family
                        </li>
                        <li style="text-align: left;">are not under stress</li>
                      </ul>
                  `
                          )
                        }
                      />
                    </td>
                    <td>
                      <p>Situations that are low risk:</p>
                      <img
                        src={situationsThatAreLowRiskImage}
                        height="250px"
                        alt="Image"
                        onClick={() =>
                          handleImage2Click(
                            `
                  <ul className="swal-content-list">
                        <li style="text-align: left;">
                          a “safe environment,” where little judgment or
                          physical coordination is or will be required
                        </li>
                        <li style="text-align: left;">
                          where the quantity used is small enough not to cause
                          intoxication or impairment. (There is no established
                          safe level of consumption for substances such as
                          marijuana or cocaine.)
                        </li>
                      </ul>
                  `
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>People who are high risk:</p>
                      <img
                        src={peopleWhoAreHighRiskImage}
                        height="250px"
                        alt="Image"
                        onClick={() =>
                          handleImage3Click(
                            `
                  <ul className="swal-content-list">
                        <li style="text-align: left;">
                          have certain physical disorders, of which they may not
                          even be aware{" "}
                        </li>
                        <li style="text-align: left;">are experiencing emotional stress or disorder</li>
                        <li style="text-align: left;">
                          are from a family with a history of alcohol-related
                          problems or drug addiction
                        </li>
                        <li style="text-align: left;">have a sensitivity to alcohol</li>
                        <li style="text-align: left;">
                          are women who are pregnant Particular at-risk
                          populations are:
                        </li>
                        <li style="text-align: left;">Children</li>
                        <li style="text-align: left;">Adolescents</li>
                        <li style="text-align: left;">The elderly</li>
                      </ul>
                  `
                          )
                        }
                      />
                    </td>
                    <td>
                      <p>Situations that are high risk:</p>
                      <img
                        src={situationsThatAreHighRiskImage}
                        height="250px"
                        alt="Image"
                        onClick={() =>
                          handleImage4Click(
                            `
                  <ul className="swal-content-list">
                        <li style="text-align: left;">
                          where judgment and physical coordination are critical
                        </li>
                        <li style="text-align: left;">
                          where use averages more than two standard drinks a day
                        </li>
                        <li style="text-align: left;">
                          when the quantity consumed is sufficient to cause
                          impairment (BAC reaches .02 percent or greater)
                        </li>
                        <li style="text-align: left;">a time of unusual stress or emotional upheaval</li>
                        <li style="text-align: left;">
                          when taking medication, such as sedatives,
                          psychoactive drugs, tranquilizers or antihistamine
                        </li>
                      </ul>
                  `
                          )
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3 className="h5p-content-note">
                Note: Please click on all above images and read content before
                go further.
              </h3>
            </Container>
          </div>
        </div>
      </Container>
    </div>
  );
};
