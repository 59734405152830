import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container, Card, Accordion } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import rules from "../../../../Images/module5/rules.png";
import rules1 from "../../../../Images/module5/rules1.jpg";
import terse_img from "../../../../Images/module5/terse_img.png";
import dri_exp from "../../../../Images/module5/dri_exp.png";
import way_strt from "../../../../Images/module5/way_strt.png";
import scl_bus from "../../../../Images/module5/scl_bus.png";
import traffic_light from "../../../../Images/module5/traffic_light.jpg";
import flashing_light from "../../../../Images/module5/flashing_light.png";
import traffic_notwor from "../../../../Images/module5/traffic_notwor.png";
import traffice_officer from "../../../../Images/module5/traffice_officer.png";
import Man from "../../../../Images/module5/officer1.png";
import Stop from "../../../../Images/module5/m54.png";
import Yield from "../../../../Images/module5/m55.png";
import Regulation from "../../../../Images/module5/m56.png";
import Warning from "../../../../Images/module5/m57.png";
import Railroad from "../../../../Images/module5/m58.png";
import WorkArea from "../../../../Images/module5/m59.png";
import LargeVehicle from "../../../../Images/module5/largevehicle.mp4";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFive.css";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";
import TaskList from "./TaskList";

const TRAFFIC_SIGNS = [
  {
    header: "STOP SIGN ",
    headerSpan: "(red with white letter)",
    img: Stop,
    details:
      "Come to a full stop, yield the right of way to vehicles and pedestrians in or approaching the intersection. Go when it is safe. You must come to a full stop before the stop line, if there is one.",
  },
  {
    header: "Yield Signs ",
    headerSpan: "(red and white with red letters)",
    img: Yield,
    details:
      "Slow down as you approach the intersection. Prepare to stop and yield the right of way to vehicles and pedestrians in or approaching the intersection. You must come to a full stop at a YIELD sign if traffic conditions require it. Approach with caution and be prepared to stop; otherwise, proceed with care.",
  },
  {
    header: "Regulation signs ",
    headerSpan: "(white with black and/or red letters or symbols)",
    img: Regulation,
    details:
      "These signs give you information about rules for traffic direction, lane use, turning, speed, parking, and other special requirements",
  },
  {
    header: "Warning signs ",
    headerSpan: "(yellow with black letters or sumbols)",
    img: Warning,
    details:
      "you are approaching an especially hazardous location or a place where there is a special rule. Be especially cautions when you see a warning sign.",
  },
  {
    header: "Railroad crossing ",
    headerSpan: "(Yellow with black letters 'RR' and 'X' symbol)",
    img: Railroad,
    details:
      "There is a railroad crossing ahead. you should use caution, and you may have to stop. Most buses and some trucks must stop rail road crossings, even when a train is not approaching and warning lights are not activated.",
  },
  {
    header: "Work area signs ",
    headerSpan: "(orange with black letters or symbols)",
    img: WorkArea,
    details:
      "People are working on or near the roadway, and traffic may be controlled by flag person. A work area speed limit of 35 MPH may be posted.",
  },
];

export const ModuleFivePart1 = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <Container>
        <div className="module5_course same_width_module mt-md-4 mb-md-4">
          <div className="container">
            <div className="mdu5_page1">
              <h2>MODULE 5 - Part 1</h2>
              <img
                src={rules}
                alt=""
                data-aos="fade-up"
                data-aos-duration="1000"
              />
              <h3 data-aos="fade-in" data-aos-duration="1000">
                RULES OF THE ROAD
              </h3>
              <h4 data-aos="slide-up" data-aos-duration="3000">
                RIGHT-OF-WAY RULES
              </h4>

              <div className="row">
                <div className="col-md-6">
                  <ul data-aos="slide-right" data-aos-duration="1000">
                    <li>
                      A driver approaching an intersection must yield the right
                      of way to traffic that is already lawfully using the
                      intersection.
                    </li>
                    <li>
                      If a driver approaching from the opposite direction
                      reaches an intersection at about the same time you do, the
                      driver turning left must yield to approaching traffic
                      going straight or turning right.
                    </li>
                    <li>
                      At intersections not controlled by signs or signals, or
                      where two or more drivers stop at STOP signs at the same
                      time and they are at right angles to one another, the
                      driver on the left must yield the right of way to the
                      driver on the right.
                    </li>
                    <li>
                      A vehicle entering a roadway from a driveway, alley,
                      private road or any other place that is not a roadway must
                      stop and yield the right of way to traffic on the roadway,
                      and to pedestrians.
                    </li>
                    <li>
                      Drivers must yield to pedestrians who are legally using
                      marked or unmarked crosswalks.
                    </li>
                  </ul>
                </div>

                <div className="col-md-6">
                  <ul data-aos="slide-left" data-aos-duration="1000">
                    <li>
                      A driver may not enter an intersection if the traffic
                      ahead is backed up and it is impossible to get all the way
                      through the intersection. The driver should wait until
                      traffic ahead clears, to avoid blocking the intersection.
                    </li>
                    <li>
                      A driver entering a traffic circle, sometimes called a
                      rotary or roundabout, must yield the right of way to
                      drivers already in the circle.
                    </li>
                    <li>
                      Drivers must pull over and stop for an emergency vehicle,
                      even if it is approaching from the opposite direction.
                    </li>
                    <li>
                      Drivers must stop their vehicle before reaching a school
                      bus displaying red “cross- over” lights, and may proceed
                      only when signalled by a police officer or the school bus
                      driver, or until the bus resumes motion, or the
                      “cross-over” lights are turned off.
                    </li>
                    <li>
                      If you have the right-of-way, do not think of it as a
                      complete right. Be prepared to yield the right-of-way to
                      other highway users. To wait a few seconds for another
                      driver is far better than to risk a crash.
                    </li>
                  </ul>
                </div>
              </div>

              <img
                src={rules1}
                alt=""
                data-aos="zoom-in-left"
                data-aos-duration="2000"
              />
            </div>
          </div>

          <div className="mdu5_page2">
            <h2 data-aos="slide-right" data-aos-duration="3000">
              INTERSECTIONS
            </h2>
            <p>The majority of crashes occur at intersections.</p>

            <h3>Intersection rules:</h3>

            <div className="row">
              <div className="col-md-6">
                <Card className="QuizCollapse">
                  <h6>Stop signs and red lights</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        Drivers must come to a full stop before entering a
                        crosswalk, and yield the right of way to vehicles and
                        pedestrians in the intersection. Proceed only when it is
                        both safe and legal.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card className="QuizCollapse">
                  <h6>Right turn on red</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        If a right turn on red is not permitted, a sign stating
                        as much will be located on the right side of the road;
                        sometimes, there is an additional sign next to the
                        traffic light. (Please note: Right on Red is prohibited
                        in cities with a population of one million or more.)
                      </li>
                      <li>
                        When turning right on red is permitted, drivers who make
                        a right turn on red must stop completely and follow the
                        rules stated in.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card className="QuizCollapse">
                  <h6>Arrow traffic light</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        The arrow designates lane position; the colour tells a
                        driver what to do.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card className="QuizCollapse">
                  <h6>Lane positioning</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        Vehicle placement puts you in a position to execute a
                        maneuver and helps communicate your intentions to
                        others.
                        <br />
                        For example, if you’re going to make a left turn from a
                        one-way street, you are to position your vehicle in the
                        furthest left lane. When making a right turn, ideally,
                        you should position your vehicle three to five feet from
                        the right curb.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card className="QuizCollapse">
                  <h6>Left turn from a middle lane</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        Many roads now have a middle lane, which is to be used
                        for making a left turn. This lane was created to enable
                        traffic to continue unimpeded, by permitting a driver to
                        place the vehicle in the middle lane while waiting for a
                        gap in traffic to make a left turn.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-md-6">
                <img
                  src={terse_img}
                  alt=""
                  srcset=""
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
            </div>
            <div
              className="rem_sec"
              data-aos="slide-right"
              data-aos-duration="2000"
            >
              <div className="container">
                <h4>Remember:</h4>
                <p>
                  Lane changing requires the use of signals and a turn of your
                  head to check the blind spots. Also, when turning, expand your
                  scanning to look for pedestrians, bicyclists, or other
                  potential hazards that are about to cross or enter your path.
                </p>
              </div>
            </div>
          </div>

          <div className="mdu5_page3">
            <h2 data-aos="slide-left" data-aos-duration="3000">
              DRIVING ON AN EXPRESSWAY
            </h2>
            <img
              className="dri_exp"
              src={dri_exp}
              alt=""
              srcset=""
              data-aos="zoom-in"
              data-aos-duration="2000"
            />
            <div className="container">
              <div className="ent_sec">
                <div className="row">
                  <div className="col-md-6">
                    <h4>Entering:</h4>
                    <ul>
                      <li>
                        An expressway entrance ramp is typically used for
                        accelerating to the proper speed, unless there is a stop
                        sign, yield sign or traffic light on the ramp. After
                        entering the expressway, you must execute a proper lane
                        change to blend in with traffic.
                      </li>
                      <li>
                        Allow enough space between your vehicle and other
                        vehicles when entering or exiting a highway, or when
                        changing lanes.
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-6">
                    <h4>While on the expressway:</h4>
                    <ul>
                      <li>
                        Under ideal conditions, you should merge only when you
                        can maintain a space cushion of at least 2 seconds
                        between your vehicle and the vehicle in front of you,
                        AND at least 2 second between your vehicle and the
                        vehicle behind you.
                      </li>
                      <li>
                        In adverse conditions, such as heavy rain or snow, you
                        will need to allow additional space between you and the
                        other vehicles.
                      </li>
                    </ul>

                    <h4>Exiting:</h4>
                    <ul>
                      <li>
                        Exit ramps are used for slowing down. Unless the exit
                        ramp is short, do not apply the brakes while still on
                        the expressway.
                      </li>
                      <li>Once you are on the exit ramp, slow down.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2 col-3">
                  <img src={way_strt} alt="" srcset="" />
                </div>

                <div className="col-md-10 col-9">
                  <div className="way_con">
                    <h3
                      className="text-uppercase"
                      data-aos="slide-left"
                      data-aos-duration="1000"
                    >
                      {" "}
                      One-WAY STREETS
                    </h3>
                    <p>How do you identify a one-way street?</p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>The “one-way” sign.</li>
                          <li>Regulations signs facing you on the left.</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>
                            Parked cars on both sides of the street, all facing
                            the same way.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="back_up">
                    <h3 data-aos="slide-left" data-aos-duration="2000">
                      HOW DO YOU BACK UP A VEHICLE SAFELY?
                    </h3>
                    <ul>
                      <li>Shift into reverse.</li>
                      <li>
                        Turn your head and look directly in the path the car
                        will travel,
                      </li>
                      <li>
                        Turn the steering wheel in the direction you want the
                        vehicle to go.
                      </li>
                    </ul>

                    <p data-aos="slide-left" data-aos-duration="3000">
                      Glancing to the side and into the mirrors is helpful to
                      assure that there is nothing in your way. Other vehicle
                      equipment may also be helpful, i.e. rear-view cameras and
                      sensors. However, these should not replace turning your
                      head to look in the direction you are traveling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mdu5_page4">
            <h2 data-aos="slide-right" data-aos-duration="3000">
              PASSING OTHER VEHICLES
            </h2>
            <div className="row">
              <div className="col-md-3">
                <div
                  className="p4_box"
                  data-aos="slide-up"
                  data-aos-duration="2000"
                >
                  <p>
                    When passing other vehicles, or changing lanes to avoid
                    hazards, do so with caution and only when necessary.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="p4_box"
                  data-aos="slide-up"
                  data-aos-duration="3000"
                >
                  <p>
                    You may not exceed the speed limit to pass another vehicle.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="p4_box"
                  data-aos="slide-up"
                  data-aos-duration="2000"
                >
                  <p>
                    Use directional or hand signals, as the law requires, at
                    least 100 feet before making a lane change.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div
                  className="p4_box"
                  data-aos="slide-up"
                  data-aos-duration="3000"
                >
                  <p>
                    Never pass a vehicle which has stopped at a crosswalk to
                    allow a pedestrian to cross.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Card className="QuizCollapse">
                  <h6>Passing on the Left</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        The left lane is usually used for passing other
                        vehicles. That’s why it is often called the “passing
                        lane.”
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card className="QuizCollapse">
                  <h6>You may NOT pass a vehicle on the left if:</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>Your lane has a solid yellow center line.</li>
                      <li>
                        You cannot safely return to the right lane before
                        reaching a solid yellow centre line for the right lane.
                      </li>
                      <li>
                        You cannot safely return to the right lane before any
                        approaching vehicle comes within 200 feet of you.
                      </li>
                      <li>
                        You are approaching a curve or the crest of a hill on a
                        two-way road and cannot see around or over it.
                      </li>
                      <li>
                        You are within 100 feet of a railroad crossing, a
                        bridge, tunnel or viaduct on a two-way roadway.
                      </li>
                      <li>Passing will interfere with oncoming traffic.</li>
                    </ul>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-md-6">
                <Card className="QuizCollapse">
                  <h6>Passing on the Right</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        You should usually pass other vehicles on the left, but
                        passing on the right is allowed in certain situations.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card className="QuizCollapse">
                  <h6>You MAY pass on the right if:</h6>
                  <Card.Body className="timepara">
                    <ul>
                      <li>
                        The vehicle ahead of you indicates that it is making a
                        left turn.
                      </li>
                      <li>
                        You are driving on a one-way road that is marked for two
                        or more lanes or is wide enough for two or more lanes,
                        and passing is not restricted by signs.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <div className="rile_des">
                  <h4>Rules for Being Passed</h4>
                  <p>
                    If another vehicle passes you on the left, slow down
                    slightly and stay to the right. When the vehicle has safely
                    passed, and is well ahead of you, resume your normal speed.
                    If you find that many vehicles are passing you on the right,
                    you should move into the “slower” right lane and allow them
                    to pass you on the left.
                  </p>
                </div>
              </div>
            </div>

            <div className="scl_bus">
              <h2 data-aos="slide-right" data-aos-duration="3000">
                SCHOOL BUSES
              </h2>
              <img
                src={scl_bus}
                alt=""
                srcset=""
                data-aos="slide-left"
                data-aos-duration="3000"
              />
              <p>
                When a school bus stops and flashes its red light(s), traffic
                approaching from either direction must stop before reaching the
                bus. You should stop at least 20 feet away from the bus. You
                must stop for a school bus even when it is on the opposite side
                of a divided highway.
              </p>
            </div>
          </div>

          <div className="mdu5_page5">
            <div className="container">
              <div className="tra_light">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  TRAFFIC SIGNS
                </h2>
                <p>
                  Traffic signs tell you about traffic rules, special hazards,
                  where you are, how to get where you’re going and where
                  services are available.
                </p>

                <p className="traffic_color_bg">
                  The color of traffic signs gives clues to the type of
                  information they provide:
                </p>

                <Row className="mt-5 mb-5 tra_signs">
                  <Col md={4} xs={12}>
                    <div className="man_light_img">
                      <img src={Man} alt="Image" />
                    </div>
                  </Col>

                  <Col md={8} xs={12}>
                    {TRAFFIC_SIGNS.map((r, i) => (
                      <Row key={i} className="tra_sign_block">
                        <Col md={3} xs={3}>
                          <div className="sign_img">
                            <img src={r.img} alt="Image" width="80%" />
                          </div>
                        </Col>
                        <Col md={9} xs={9}>
                          <p>
                            <div className="tra_sign_header">
                              <span>{r.header}</span>
                              {r.headerSpan}
                            </div>
                            {r.details}
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
                {/* <H5PPlayerUI
                  ref={h5pPlayer}
                  contentId={"3456085232"}
                  loadContentCallback={contentService.getPlay}
                  onInitialized={onPlayerInitialized}
                  onxAPIStatement={(statement, context, event) => {}}
                /> */}
              </div>
            </div>

            <div class="h5p-iframe module-5-part-1" id="h5p_content_navigate">
              <h3>
                Note - Match the Signs with the Correct Descriptions. Click on
                “Check Answer” once you are done.
              </h3>
              {/* <iframe
                src="https://h5p.org/h5p/embed/1228904"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
                title="Module 5 Traffic Signs"
              ></iframe>
              <script
                src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js"
                charset="UTF-8"
              ></script> */}

              <TaskList />
            </div>
          </div>

          <div className="mdu5_page5">
            <div className="container">
              <div className="tra_light">
                <h2 data-aos="slide-right" data-aos-duration="3000">
                  TRAFFIC LIGHTS
                </h2>
                <p>
                  Traffic lights are usually red, yellow and green from top to
                  bottom, or left to right.
                </p>
                <p>
                  At some intersections, there are single red, yellow or green
                  lights.
                </p>
                <p>Some traffic lights are steady, others flash.</p>
                <p>Some are circular, and some are arrows.</p>
                <p>Here are what various traffic lights mean:</p>
              </div>
            </div>

            <div className="maintra_Div">
              <div className="row">
                <div className="col-md-5">
                  <div className="steady_red">
                    <h2 data-aos="slide-down" data-aos-duration="3000">
                      STEADY RED
                    </h2>
                    <p>Stop. Do not go until the light is green.</p>
                    <p>Unless prohibited, you may make a:</p>
                    <p>Right turn on red, or</p>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="tra_lig_img">
                    <img
                      src={traffic_light}
                      alt=""
                      // srcset=""
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="tra_para">
                    <p>
                      Left turn on red ONLY from a one-way street to another
                      one-way street.
                    </p>
                    <p>
                      Before you make any turn on red, you must come to a full
                      stop and yield the right of way to oncoming traffic and
                      pedestrians.
                    </p>
                    <p>
                      You may not make a turn at a red light if there is a NO
                      TURN ON RED sign posted, or if another sign, signal or
                      pavement marking prohibits the turn. Also, turning on a
                      red light is not allowed in New York City unless a sign is
                      posted permitting it. The driver of a school bus carrying
                      pupils may not turn on any red light. Always watch for
                      pedestrians in the crosswalks when turning on red.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flashing_div">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="law_traffic">
                      <img src={flashing_light} alt="" srcset="" />
                    </div>
                  </div>

                  <div className="col-md-8">
                    <p>
                      <span> FLASHING RED:</span> Means the same as a STOP sign:
                      Stop, yield the right of way, and go when it is safe.
                    </p>

                    <p>
                      <span>RED ARROW</span>: Do not go in the direction of the
                      arrow until the red arrow goes out and a green light or
                      arrow goes on. A right or left turn on red is not
                      permitted at a red arrow.
                    </p>
                    <p>
                      <span>STEADY YELLOW: </span> The light is changing from
                      green to red. Be ready to stop.
                    </p>
                    <p>
                      <span>FLASHING YELLOW: </span> Drive with caution.
                    </p>
                    <p>
                      <span>YELLOW ARROW: </span> The protection of a green
                      arrow is ending. Be prepared to stop.
                    </p>
                    <p>
                      <span>STEADY GREEN: </span> Go, but yield the right of way
                      to other traffic.
                    </p>
                    <p>
                      <span>GREEN ARROW: </span> You may go in the direction of
                      the arrow, but you must yield the right of way to other
                      traffic at the intersection as required by law.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mdu5_page6">
            <div className="container not_wor">
              <h3>What happens if the traffic lights are not working?</h3>
              <p>
                State law requires that, if the traffic lights or controls are
                out of service/malfunctioning when you approach an intersection,
                you must come to a stop as you would for a stop sign. You must
                then proceed according to the rules of right of way, unless you
                are directed to proceed by a traffic officer.
              </p>
            </div>
            <img
              src={traffic_notwor}
              alt=""
              srcset=""
              data-aos="slide-left"
              data-aos-duration="3000"
            />
            <div className="container mt-5">
              <h3>Pavement Markings</h3>
              <p>
                Lines and symbols on the roadway divide lanes and inform you of
                when you may pass other vehicles/change lanes, of which lanes to
                use for turns, and of where you must stop for signs/traffic
                signals.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="1000">
                SINGLE BROKEN LINE:
              </h4>
              <p>
                You may pass other vehicles or change lanes, if you can do so
                safely and not interfere with traffic.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="2000">
                SOLID LINE WITH BROKEN LINE:
              </h4>
              <p>
                If you’re on the side with the solid line, you may not pass
                other vehicles or cross the line except to make a left turn into
                a driveway. If you're on the side with the broken line, you may
                pass if it is safe to do so while not interfering with traffic.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="3000">
                DOUBLE SOLID LINES:
              </h4>
              <p>You may not pass, or change lanes.</p>
              <h4 data-aos="slide-right" data-aos-duration="1000">
                SINGLE SOLID LINE:
              </h4>
              <p>
                You may pass other vehicles or change lanes, but you should do
                so only if obstructions in the road make it necessary, or
                traffic conditions require it.
              </p>
              <h4 data-aos="slide-right" data-aos-duration="2000">
                STOP AND CROSSWALK LINES:
              </h4>
              <p>
                When required to stop because of a sign or light, you must stop
                before reaching the stop line, if there is one. If there is not,
                then you must stop before reaching the crosswalk.
              </p>
              <h3>Traffic Officers</h3>
              <p>
                Directions given by traffic officers take precedence over signs,
                signals or pavement markings. Among those authorized to direct
                traffic are police officers, peace officers (such as on-duty
                auxiliary or fire police), and highway work area flag persons.
              </p>
            </div>
            <div className="tra_officer">
              <img
                src={traffice_officer}
                alt=""
                srcset=""
                data-aos="zoom-in"
                data-aos-duration="3000"
              />
            </div>
          </div>
          {/* <div className="mdu5_page11">
            <div className="abo_safety">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12">
                    <img
                      src={logo}
                      alt=""
                      srcset=""
                      data-aos="slide-right"
                      data-aos-duration="3000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};
