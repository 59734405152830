import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../Images/module4/logo.png";
import Search from "../../../../Images/module4/search.png";
import Traffic from "../../../../Images/module4/traffic.png";
import Signal from "../../../../Images/module4/signal.png";
import Execute from "../../../../Images/module4/execute.png";
import Strategyvideo from "../../../../Images/module4/strategy.mp4";
import SEE from "../../../../Images/module4/b11.png";
import Hydro from "../../../../Images/module4/hydro.jpg";
import SkidCar from "../../../../Images/module4/b15.png";
import RearWheel from "../../../../Images/module4/b16.jpg";
import RearSkid from "../../../../Images/module4/b17.jpg";
import FrontSkid from "../../../../Images/module4/b18.jpg";
import Skid from "../../../../Images/module4/skid.mp4";
import Skids from "../../../../Images/module4/skid.jpg";
import SkidsSliderComponent from "./SkidsSliderComponent";
import SkidsSliderComponentCopy from "./SkidsSliderComponentCopy";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleFour.css";

export const ModuleFour = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);
  const [videoOneStatus, setVideoOneStatus] = useState(false);
  const [videoTwoStatus, setVideoTwoStatus] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  useEffect(() => {
    if (videoTwoStatus && videoOneStatus) {
      sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
    }
  }, [videoOneStatus, videoTwoStatus]);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  var video = document.getElementById("video");
  var video2 = document.getElementById("video2");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }

    if (video2) {
      var supposedCurrentTime = 0;
      video2.addEventListener("timeupdate", function () {
        if (!video2.seeking) {
          supposedCurrentTime = video2.currentTime;
        }
      });
      // prevent user from seeking
      video2.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video2.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video2.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video2.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video, video2]);

  return (
    <div className="module4_course module_font same_width_module mb-md-4">
      <Container>
        <div className="driver_skill">
          <Container>
            <div
              className="driver_skill_content"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="2000"
            >
              <h1>
                <span>skills of</span>a safe driver
              </h1>
              <p>module 4</p>
            </div>
            {/* <div className="module_logo">
              <img src={Logo} alt="Image" />
            </div> */}
          </Container>
        </div>

        <div className="ribbon_position">
          <div
            className="ribbon_main"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <div className="ribbon_bg ribbon">
              <h2>s.e.e. strategy</h2>
            </div>
          </div>
        </div>

        <div className="strategy_content mt-5 mb-5 pt-md-3">
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <p>
                  A three-step process used to help analyze the surroundings,
                  anticipate potential problems, and make appropriate judgements
                  to then apply them correctly in varied driving situations.
                </p>

                <div className="strategy_buttons mt-md-4 mb-4">
                  <span className="btn_blue btn">
                    <span>S</span>earch
                  </span>

                  <span className="btn_dark_blue btn_blue btn">
                    <span>E</span>valuate
                  </span>

                  <span className="btn_blue btn">
                    <span>e</span>xecute
                  </span>
                </div>

                <p className="text-center">
                  The S.E.E. strategy helps you improve your decision making!
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="vehicle_search mt-4">
          <div
            className="search_car_img"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <img src={Search} alt="Image" />
          </div>

          <div className="search_content">
            <Row>
              <Col
                lg={{ span: 8, offset: 4 }}
                md={{ span: 8, offset: 5 }}
                xs={12}
                className="pl-lg-4"
              >
                <div className="">
                  <h3>SEARCH</h3>

                  <ul className="p-0">
                    <li>
                      Search aggressively ahead, to the sides and behind your
                      vehicle, to identify factors that could cause increased
                      risk and to avoid potential hazards before they arise.
                    </li>

                    <li>
                      How assertively you search, and how much time and space
                      you have, can eliminate or reduce dangerous situations.
                      Searching provides valuable information to help you make
                      good driving decisions.
                    </li>

                    <li>
                      Check your mirrors frequently and use head checks to
                      monitor blind spots. Focus even more on finding potential
                      escape routes in or around intersections (especially
                      intersections with limited visibility), shopping areas and
                      school or construction zones.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="factor_search mt-md-3">
          <Container>
            <Row>
              <Col md={8} xs={12}>
                <div className="factor_search_content">
                  <h2 className="same_heading">
                    WHAT FACTORS ARE YOUR SEARCHING FOR?
                  </h2>
                  <span className="d-block mb-3">
                    Search for factors such as:
                  </span>

                  <div className="factor_content">
                    <Row>
                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">
                            Oncoming traffic that may turn left in front of you
                          </p>
                        </div>
                      </Col>

                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">
                            Traffic coming from the left and the right
                          </p>
                        </div>
                      </Col>

                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">Traffic approaching from behind</p>
                        </div>
                      </Col>

                      <Col md={6} xs={12}>
                        <div className="factor_content_detail">
                          <p className="m-0">Hazardous road conditions</p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <p className="mt-3">
                    Be especially alert in areas with limited visibility.
                    Visually busy surroundings can potentially hide you from
                    other drivers.
                  </p>
                </div>
              </Col>

              <div className="factor_search_img">
                <img
                  src={Traffic}
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                  alt="Image"
                />
              </div>
            </Row>
          </Container>
        </div>

        <div className="drive_evaluate mt-md-3">
          <Container>
            <Row>
              <Col xl={10} lg={10} md={12} xs={12}>
                <h3 className="mt-md-0 mt-4">EVALUATE</h3>
                <ul className="p-0">
                  <li>
                    Think about how hazards can interact to create risks for
                    you.
                  </li>
                  <li>
                    Anticipate potential problems and have a plan ready to
                    reduce the risk of situations that may arise.
                  </li>
                  <li>
                    Don’t think of evaluating as ‘guessing’, think of it as
                    reading the situation to minimize risks of collisions.
                  </li>
                </ul>

                <h2 className="mt-xl-5 mt-lg-0 pt-md-5 same_heading">
                  WHAT HAZARDS SHOULD YOU WATCH FOR?
                </h2>
              </Col>

              <div className="drive_evaluate_img">
                <img
                  src={Signal}
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  alt="Image"
                />
              </div>

              <Col xl={12} lg={12} md={12} xs={12}>
                <div className="hazard_watch">
                  <Row>
                    <Col xl={4} lg={4} md={12} xs={12}>
                      <div className="hazard_watch_content">
                        <h3 className="mt-md-0 mt-4">
                          ROADS & SURFACE CHARACTERISTICS
                        </h3>
                        <h4 className="mt-md-0 mt-4">
                          Keep an eye out for potholes, guardrails, bridges,
                          location of telephone poles, streetlights and trees.
                        </h4>
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={12} xs={12}>
                      <div className="hazard_watch_content">
                        <h3 className="mt-md-0 mt-4">
                          Traffic Control Devices
                        </h3>
                        <h4 className="mt-md-0 mt-4">
                          Look for traffic signals, including regulatory signs,
                          warning signs and pavement markings, to help you
                          evaluate circumstances ahead.
                        </h4>
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={12} xs={12}>
                      <div className="hazard_watch_content">
                        <h3 className="mt-md-0 mt-4">
                          Other Vehicles and pedestrians
                        </h3>
                        <h4 className="mt-md-0 mt-4">
                          Other vehicles and pedestrians may move into your path
                          and increase the likelihood of a crash.
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="module_execute mt-5">
          <div
            className="module_execute_img"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <img src={Execute} alt="Image" />
          </div>

          <div className="module_execute_content">
            <Row>
              <Col
                lg={{ span: 7, offset: 5 }}
                md={{ span: 7, offset: 5 }}
                xs={12}
              >
                <div className="">
                  <h3>
                    EXECUTE -{" "}
                    <span> CARRYING OUT YOUR DECISIONS TO MINIMIZE RISK </span>
                  </h3>
                  <p className="mb-2">
                    To create more space and minimize harm from any hazard:
                  </p>
                  <ul className="p-0">
                    <li>
                      Communicate your presence and your intentions with
                      directional signals, lights and/or your horn.
                    </li>

                    <li>
                      Adjust your speed by accelerating, slowing, or stopping.
                    </li>

                    <li>
                      Adjust your position and/or direction by changing lanes,
                      if necessary.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="example_strategy mt-md-5 mb-5 pb-md-5 mt-0">
          <Container>
            <Row>
              <Col md={12} xs={12}>
                <h2 className="same_heading">
                  AN EXAMPLE OF THE S.E.E. STRATEGY
                </h2>

                <p>
                  Sarah is navigating through heavy rush hour traffic on the
                  highway. As she approaches an upcoming exit, she notices
                  congestion ahead and immediately engages the S.E.E. strategy.
                  She begins by searching the road ahead, using her peripheral
                  vision to scan for potential hazards such as merging vehicles,
                  sudden stops, or construction zones. Sarah also checks her
                  mirrors to ensure there are no vehicles in her blind spots
                  before considering any lane changes. After completing her
                  search, Sarah evaluates the traffic patterns, taking into
                  account the behavior of nearby drivers and adjusting her speed
                  to maintain a safe following distance. She carefully assesses
                  the situation, noting any erratic movements or potential risks
                  that may affect her route. Finally, based on her observations,
                  Sarah executes her plan, deciding to remain in her current
                  lane and maintain a steady speed to flow with the traffic. She
                  anticipates upcoming lane changes and exits, preparing to
                  signal and merge when necessary. By diligently applying the
                  S.E.E. strategy, Sarah successfully navigates through the
                  congested traffic, making informed decisions to ensure her
                  safety and the safety of others on the road.
                </p>
                <div className="mb-4">
                  <img src={SEE} alt="Image" width="100%" />
                </div>
                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    setVideoOneStatus(true);
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Strategyvideo} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="module_plan mt-5">
          <Container>
            <div className="ribbon_main">
              <div
                className="ribbon_bg ribbon"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>HYDROPLANING</h2>
              </div>
            </div>

            <Row className="mt-5 pt-md-5">
              <Col md={8} xs={12}>
                <div className="hydro_content_left">
                  <span className="d-block">
                    Roads are at their slickest during the first five to ten
                    minutes of a rainfall.
                  </span>

                  <p className="mt-3 mb-3">
                    This occurs because the water mixes with surface dirt and
                    oil to form a slippery film, greatly reducing your tires'
                    ability to grip the road. The tires of a vehicle will begin
                    to skim along the wet surface of the road, much like a
                    water-skier zipping across the surface of a lake.
                  </p>

                  <p>
                    Hydroplaning is when tires completely lose contact with the
                    road and are riding on a thin film of water. Hydroplaning
                    limits your ability to control your car.
                  </p>
                </div>
              </Col>
              <Col md={4} xs={12}>
                <div className="hydro_img">
                  <img src={Hydro} alt="Image" />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={9} xs={12}>
                <h2 className="same_heading">
                  HOW DO YOU PREVENT HYDROPLANING?
                </h2>
              </Col>
            </Row>

            <Row
              className="mt-3 hydro_margin"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>Invest in good tires with deep tread.</p>
                </div>
              </Col>

              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>Always have properly inflated tires.</p>
                </div>
              </Col>

              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>
                    Reduce speed to about one-third when driving on wet
                    roadways.
                  </p>
                </div>
              </Col>

              <Col lg={3} md={6} xs={12}>
                <div className="prevent_content text-center">
                  <p>Avoid driving through standing water.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className="avoid_skid mt-5 mb-5">
          <Container>
            <div className="ribbon_main">
              <div
                className="ribbon_bg ribbon"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>HOW DO YOU AVOID SKIDS?</h2>
              </div>
            </div>

            <ul className="p-0 mt-5">
              <li>
                Brake early, carefully and gently on roads that are wet, snowy
                or icy.
              </li>

              <li>
                Be aware of what type of brakes the vehicle you are driving is
                equipped with. Most cars come equipped with an anti-lock braking
                system (ABS).
              </li>

              <li>
                A driver should not pump the brakes when the car has ABS; the
                brakes in cars with ABS automatically pump themselves.
              </li>
            </ul>
          </Container>
          <Container className="mt-5">
            <div className="ribbon_main">
              <div
                className="ribbon_bg ribbon"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>IF you feel like you are skidding:</h2>
              </div>
            </div>

            <ul className="p-0 mt-5">
              <li>
                You must use steady, firm brake pressure if you are in a skid.
                If your vehicle does not have ABS, pump your brakes in slow
                steady strokes when in a skid. Allow the wheels to keep rolling.
              </li>

              <li>
                If your wheels start to lock up, ease off the brake pedal.
              </li>

              <li>
                As you slow down, you may also want to shift into a lower gear.
              </li>
            </ul>

            <div className="skid_car">
              <img src={SkidCar} alt="Image" width="80%" />
            </div>
          </Container>

          <Container className="mt-5 rear_wheel_skid">
            <div className="ribbon_main">
              <div
                className="ribbon_bg ribbon"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>If your rear wheels start to skid:</h2>
              </div>
            </div>
            <Row>
              <Col lg={8} md={8} xs={8}>
                <ul className="p-0 mt-5">
                  <li>
                    Turn the steering wheel in the direction the vehicle is
                    trying to go.
                  </li>

                  <li>
                    If your rear wheels are sliding left, steer left. if they're
                    sliding right, steer right.
                  </li>

                  <li>
                    If your rear wheels start sliding the other way as you
                    recover, ease the steering wheel towards that side.
                  </li>

                  <li>
                    You might have to steer left and right a few times to get
                    your vehicle completely under control.
                  </li>
                </ul>
              </Col>
              <Col lg={4} md={4} xs={4}>
                <div className="rear_wheel">
                  <img src={RearWheel} alt="Image" width="95%" />
                </div>
              </Col>
            </Row>
            <p>
              If your vehicle has ABS, keep your foot on the brake pedal with
              even pressure. If your vehicle does not have ABS, pump the pedal
              gently, pumping more rapidly only as your car slows down. Braking
              hard with non-anti-lock brakes will make the skid worse.
            </p>

            <div className="rear_skid">
              <img src={RearSkid} alt="Image" width="50%" />
            </div>
          </Container>
          <Container className="mt-5 front_wheel_skid">
            <div className="ribbon_main">
              <div
                className="ribbon_bg ribbon"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>If your front wheels start to skid:</h2>
              </div>
            </div>
            <ul className="p-0 mt-5">
              <li>
                Take your foot of the accelerator and shift to neutral or push
                in the clutch, but do not immediately try to steer.
              </li>

              <li>
                As the wheels skid sideways, they will slow the vehicle and
                traction will return.
              </li>

              <li>
                As traction returns, steer in the direction you want to go.
              </li>

              <li>
                Then put the transmission in drive or release the clutch, and
                accelerate gently.
              </li>
            </ul>
            <div className="front_wheel_img">
              <img src={FrontSkid} alt="Image" width="50%" />
            </div>
            <p className="skids_para">
              NOTE: When sleet, freezing rain and snow start to fall, remember
              that bridges, ramps and overpasses are likely to freeze first, and
              that slippery spots may remain after road crews have cleared the
              highways.
            </p>
          </Container>
          <Container className="mt-5 pb-4">
            <p className="skids_para">
              The below video will explain how to avoid and handle skids. Click
              the Play button to start the video.
            </p>

            <video
              id="video2"
              width="100%"
              height="400"
              autoplay={true}
              onEnded={() => {
                setVideoTwoStatus(true);
              }}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={Skid} type="video/mp4" />
            </video>
          </Container>
        </div> */}

        <div className="avoid_skid mt-5 mb-5">
          {/* <iframe
            src="https://h5p.org/h5p/embed/1227481"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
            title="Module 4 - HOW DO YOU AVOID SKIDS?"
          ></iframe>
          <script
            src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js"
            charset="UTF-8"
          ></script> */}

          <div className="avoid_skid_h5p" id="h5p_content_navigate">
            {/* <SkidsSliderComponent totalOuterSlides={6} /> */}
            <SkidsSliderComponentCopy totalOuterSlides={6} />
          </div>

          <Container className="mt-5 pb-4">
            <p className="skids_para">
              The below video will explain how to avoid and handle skids. Click
              the Play button to start the video.
            </p>

            <video
              id="video2"
              width="100%"
              height="400"
              autoplay={true}
              onEnded={() => {
                setVideoTwoStatus(true);
              }}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={Skid} type="video/mp4" />
            </video>
          </Container>
        </div>
      </Container>
    </div>
  );
};
