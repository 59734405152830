import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Card, Modal } from "react-bootstrap";
import "./UserSecurityQuestion.css";
import { isBrowser, isMobile } from "react-device-detect";
import {
  tableofContentDetailAction,
  tableofContentQuizDetailAction,
  tableofContentSecurityDetailAction,
  userForgotPasswordAction,
  getModuleByIdAction,
  getSecurityModuleByIdAction,
  securityQuestionAnswerVerifyAction,
  logoutUserAction,
  socketEmitStopModuleAction,
  socketEmitStartModuleAction,
  getUserDetailByTokenAction,
  checkUserSecurityQuestionDoneOrNotAction,
  checkUserAction,
  socketEmitResetAllAction,
  userPaymentStatusCheckAction,
  socketMarkModuleComplete,
  socketDisconnectAction,
  typing_dna_session_verified_userAction,
  socketResetCourse,
  socketResetModule,
  socketLockCourse,
} from "../../../Redux/Actions";
import swal from "sweetalert";
import time_clock from "../../../Images/time_clock.svg";
import {
  secondToMinuteOrHourConvert,
  setItemInSessionStorage,
} from "../../../Utils/Util";



const UserSecurityQuestionPopup = ({course_id,module_id,completion_time}) => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };
  window.document.onkeydown = function (event) {
    if (event.keyCode == 116) {
      event.preventDefault();
    }
    if (event.metaKey && event.keyCode == 82) {
      event.preventDefault();
    }
    if (event.metaKey && event.keyCode == 87) {
      event.preventDefault();
    }
  };
  const history = useHistory();
  const dispatch = useDispatch();


  //varibale declare
  const socket = useSelector((state) => {
    return state.userSocketioReducer?.socketConnectSuccess?.socketInstance;
  });
  // const module_time_obj = useSelector((state) => {
  //   return state.userSocketioReducer?.socketTimerSuccessResponse;
  // });
  // const timeUpObj = useSelector((state) => {
  //   return state.userSocketioReducer?.socketTimerUpResponse;
  // });

  // const module_time = useSelector((state) => {
  //   return state.userSocketioReducer?.socketTimerSuccessResponse?.time;
  // });

 

 
  // const module_list = useSelector((state) => {
  //   return state.tableofContentReducer?.tableofContentDetailList?.data;
  // }); 

  const security_question_list = useSelector((state) => {
    return state.tableofContentReducer?.tableofContentSuccessResult?.data?.questions;
  }); 

 

  const [questionGroup, setQuestionGroup] = useState([]);

 
 
  const shuffleArray = (array) => {
    let shuffledArray = [...array]; // Create a copy of the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
    }
    return shuffledArray;
  };
 
  const getSecurityQuestions = async () => {
    const shuffledQuestions = shuffleArray(security_question_list);  // Shuffle the questions array
    setQuestionGroup(shuffledQuestions);  // Store the shuffled questions
  };
  

  useEffect(()=>{
    course_id && getSecurityQuestions()
  },[course_id])


  const [userResponse, setUserResponse] = useState([]);
  const [verificationResponse, setVerificationResponse] = useState([]);
  const [userResult, setUserResult] = useState("fail");
  const [validated, setValidated] = useState(false);
  const [answered_all, set_answered_all] = useState(false);
  const [answered_number, set_answered_number] = useState(0);
  const [send_answer_once, set_send_answer_once] = useState(false);
  // const [completion_time, set_completion_time] = useState();

  const [name, set_name] = useState("");


  //  handle multiple_choice questions
  const handleMultipleChoice = (question_id, option) => {
    if (userResponse.some((id) => id.security_question_id == question_id)) {
      let newResponse = [...userResponse];

      let qu_index = newResponse.findIndex(
        (id) => id.security_question_id == question_id
      );
      newResponse[qu_index] = { ...newResponse[qu_index], answer: option };
      setUserResponse(newResponse);
    } else {
      let responseObj = { security_question_id: question_id, answer: option };
      userResponse.push(responseObj);
    }
  };

  // handle custom_answer questions
  const handleCustomAnswer = (e, question_id, type) => {
    e.preventDefault();
    const customAns = e.target.value;
    if (userResponse.some((id) => id.security_question_id == question_id)) {
      let newResponse = [...userResponse];
      let qu_index = newResponse.findIndex(
        (id) => id.security_question_id == question_id
      );
      newResponse[qu_index] = { ...newResponse[qu_index], answer: customAns };
      setUserResponse(newResponse);
    } else {
      let responseObj = {
        security_question_id: question_id,
        answer: customAns,
      };
      userResponse.push(responseObj);
    }
  };

  // handle truth_or_false questions
  const handleTruthFalse = (e, question_id, type) => {
    const name = e.target.name;
    const value = e.target.value;

    let answer = value;

    if (userResponse.some((id) => id.security_question_id == question_id)) {
      let newResponse = [...userResponse];

      let qu_index = newResponse.findIndex(
        (id) => id.security_question_id == question_id
      );
      newResponse[qu_index] = { ...newResponse[qu_index], answer: answer };
      setUserResponse(newResponse);
    } else {
      let responseObj = { security_question_id: question_id, answer: answer };
      userResponse.push(responseObj);
    }
  };

  // handle Response
  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    if (userResponse?.length === 0) {
      const errorMsg = "Please answer the question before submitting";
      swal({
        title: "Error!",
        text: errorMsg,
        icon: "error",
        timer: 6000,
      });
      return;
    }
    swal("Are you sure to submit your response?", {
      buttons: {
        cancel: "Cancel",
        button: {
          text: "Submit",
          value: true,
        },
      },
      timer: 5000,
      icon: "info",
    }).then((value) => {
      if (value) {
        handleResponseSubmitApi(userResponse);
      }
    });
  };

  const handleResponseSubmitApi = async (userresponse) => {
    try {
      let user_response = {
        module_id: module_id,
        answers: [...userresponse],
        flag : true
      };
  
      const response = await dispatch(securityQuestionAnswerVerifyAction(user_response));
      const verificationResponse = response?.payload;
  
      if (verificationResponse) {
        if (verificationResponse.data) {
          const { code, data, message } = verificationResponse.data;
  
          if (code === 200) {
            const { verified } = data;
  
            if (verified) {
              swal({
                title: "Verified Successfully",
                text: message,
                icon: "success",
                timer: 5000,
              }).then(() => {
                setItemInSessionStorage("failedQuestionList", JSON.stringify([]));
                sessionStorage.setItem('securityPopupVisible', 'false');
                handleClose();
              });
            }
          } else if (code === 400) {
            const failedQuestions = getFailedQuestionList();
            const attemptsLeft = 3 - failedQuestions.length;
  
            if (attemptsLeft > 1) {
              swal({
                title: "Error!",
                text: message,
                icon: "error",
                timer: 5000,
              }).then(() => {
                setFailedSecurityQuestionAttempt(userresponse[0].security_question_id);
                setUserResponse([]);
              });
            } else {
              swal({
                title: "Error!",
                text: "Sorry, you are prohibited from completing the course due to consecutive failed attempts. You need to restart the course from the beginning",
                icon: "error",
                timer: 360000,
              }).then(() => {
                dispatch(socketEmitStopModuleAction(socket, module_id));
                dispatch(socketLockCourse(socket, course_id));
                setItemInSessionStorage("failedQuestionList", JSON.stringify([]));
                history.push(`/user/dashboard/${course_id}`);
              });
            }
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000,
        }).then(() => {
          history.push(`/user/signin/${course_id}`);
          dispatch(socketDisconnectAction(socket));
          dispatch(logoutUserAction());
        });
      }
    }
  };

  const setFailedSecurityQuestionAttempt = (id) => {
    let failedList = getFailedQuestionList();
    failedList.push(id);
    setItemInSessionStorage("failedQuestionList", JSON.stringify(failedList));
  };
  
  // Get the list of failed attempts
  const getFailedQuestionList = () => {
    let list = sessionStorage.getItem("failedQuestionList");
    if (!list || list === "" || JSON.parse(list) === undefined || !Array.isArray(JSON.parse(list))) {
      list = [];
    } else {
      list = JSON.parse(list);
    }
    return list;
  };

  const questionIndex = getFailedQuestionList().length;


  const getQuestions = (que) => {

    if (que.type === "multiple_choice") {
      return (
        <div key={`multi_choice-${que.id}`}>
          <h5>{que.question ? que.question : "Security Question"}</h5>
          <div className="custom-radio-wrap">
            {que.options
              ? que.options.map((opt, index) => {
                  return (
                    <div key={`mutil_sceuity-${opt.id}`} className="form-group">
                      <input
                        id={`${que.id}_${opt.id}_${index}`}
                        type="radio"
                        name="custom-radio-btn"
                        onChange={() => {
                          handleMultipleChoice(que.id, opt.id);
                        }}
                      />
                      <label
                        className="custom-radio"
                        htmlFor={`${que.id}_${opt.id}_${index}`}
                      ></label>
                      <span className="label-text">{opt.option}</span>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      );
    }
  
    if (que.type === "custom_answer") {
      return (
        <div key={`custom_answer-${que.id}`}>
          <h5>{que.question ? que.question : "Security Question"}</h5>
          <div className="custom-radio-wrap">
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control
                  as="input"
                  required
                  placeholder="your answer will be here"
                  rows={3}
                  col={4}
                  onChange={(e) => {
                    handleCustomAnswer(e, que.id, que.type);
                  }}
                />
              </Form.Group>
            </Form.Row>
          </div>
        </div>
      );
    }
  
    if (que.type === "truth_or_false") {
      return (
        <div key={`truth_false-${que.id}`}>
          <h5>{que.question ? que.question : "Security Question"}</h5>
          <div className="custom-radio-wrap">
            <div className="form-group">
              <input
                id={`${que.id}_true`}
                type="radio"
                name="custom-radio-btn"
                value={true}
                onChange={(e) => {
                  handleTruthFalse(e, que.id, que.type);
                }}
              />
              <label className="custom-radio" htmlFor={`${que.id}_true`}></label>
              <span className="label-text">True</span>
            </div>
            <div className="form-group">
              <input
                id={`${que.id}_false`}
                type="radio"
                name="custom-radio-btn"
                value={false}
                onChange={(e) => {
                  handleTruthFalse(e, que.id, que.type);
                }}
              />
              <label className="custom-radio" htmlFor={`${que.id}_false`}></label>
              <span className="label-text">False</span>
            </div>
          </div>
        </div>
      );
    }
  };
  
 



const [seconds, setSeconds] = useState(60);
const [show, setShow] = useState(false);

useEffect(() => {
  let interval;
  if (show && seconds > 0) {
    interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
  } else if (seconds === 0) {
    clearInterval(interval); // Clear the interval before navigating
    history.push(`/user/dashboard/${course_id}`);
  }

  return () => clearInterval(interval); // Cleanup on component unmount
}, [seconds, show, course_id]);

// Function to get a random time between 5 seconds and completion_time - 5 seconds
const getRandomTime = (completion_time) => {
  const minTime = 5; 
  const maxTime = completion_time - 5;
  
  return Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime; 
};

// Second useEffect to trigger the popup at a random time
useEffect(() => {
  if (typeof completion_time === 'number' && !isNaN(completion_time)) {
    const randomTimeInSeconds = getRandomTime(completion_time);
    console.log('randomTimeInSeconds ===== ', randomTimeInSeconds);

    const randomTimeInMilliseconds = randomTimeInSeconds * 1000;

    const timer = setTimeout(() => {
      setShow(true);
    }, randomTimeInMilliseconds);

    return () => clearTimeout(timer); // Clean up the timeout when the component unmounts
  }
}, [completion_time]);

// Handle closing the popup
const handleClose = () => setShow(false);

 
  return (

    <div>
    <div className="noHeaderTop welcomeScreen mt-10">
      <Modal
        show={show}
        animation={false}
        className="custom-modal-size"
        dialogClassName="modal-lg modal-dialog-centered"  // added modal-dialog-centered
      >

        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col xl={12} lg={12} md={12} sm={12}>
              <Card className="p-xl-5 pt-lg-5 pb-lg-5 pt-md-5 pb-md-5 pt-5 pb-5">
                <Card.Body>
                  <h5 className="licensing_text mb-4">Biometric Authentication</h5>
                  <Row>
                    <Col xl={11} lg={11} md={12} sm={12}>

          <Row className="justify-content-md-center d-row-flex">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className="mb-md-3 mb-4 mt-lg-3">Security Question</h1>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="md-right time">
              <div className="timeDiv">
                <span> Time Remaining </span>
                <p>
                  <img src={time_clock} className="pr-2" />
                  {seconds
                    ? secondToMinuteOrHourConvert(seconds)
                    : `0 hr 0 min 0 sec `}
                </p>
              </div>
            </Col>
          </Row>
          <Container fluid >
          <Form
            noValidate
            validated={validated}
            onSubmit={handleResponseSubmit}
          >
            <Row className="justify-content-md-center">
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="UserSecurityQuestion_container">
                  <p>
                    You have{" "}
                    {seconds
                      ? secondToMinuteOrHourConvert(seconds)
                      : `0 hr 0 min 0 sec `}{" "}
                    to answer the following Question correctly
                  </p>
                  <div className="progressBar"></div>
                  {/* {questionGroup &&
                    questionGroup.length > 0 &&
                    getQuestions(questionGroup[0])} */}
                       {questionGroup &&
                    questionGroup.length > 0 &&
                    questionIndex < 3 &&
                    getQuestions(questionGroup[questionIndex])}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} className="text-center">
                <Button
                  variant="primary"
                  className="btnSign mt-4 mb-3 pl-5 pr-5 pt-1 pb-1"
                  type="submit"
                  name="next"

                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          </Container>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  </div>

  );
};

export default UserSecurityQuestionPopup;
