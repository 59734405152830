import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import audio_play from "../../../Images/auto_play.svg";
import time_clock from "../../../Images/time_clock.svg";
import downloadblue from "../../../Images/downloadblue.svg";
import file from "../../../Images/file.svg";
import download_icon from "../../../Images/download_icon.svg";
import audioplay1 from "../../../Images/audioplay1.svg";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import "./Module.css";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "./services/ContentService";
import { useDispatch, useSelector } from "react-redux";
import {
  getH5PAction,
  getModuleByIdAction,
  getTableofContentListAction,
  loadingAction,
  logoutUserAction,
  markCourseModuleCompleteAction,
  socketConnectAction,
  socketDisconnectAction,
  socketEmitResetAllAction,
  socketEmitStartModuleAction,
  socketEmitStopModuleAction,
  socketMarkModuleComplete,
  tableofContentDetailAction,
  tableofContentListAction,
  tableofContentQuizDetailAction,
  tableofContentSecurityDetailAction,
} from "../../../Redux/Actions";
import { html, css } from "./ModuleConstant";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "react-bootstrap";

import {
  MODULE_GRAPE_JS,
  MODULE_QUIZ,
  secondToMinuteOrHourConvert,
} from "../../../Utils/Util";
import { StaticModuleWrapper } from "./StaticModules/StaticModuleWrapper";
import useLogoutOnIdle from "../../../hooks/useIdleTimeout";
import TypingDnaAuthenticUserCheckPopUp from "../TypingDna/TypingDnaAuthenticUserCheckPopUp";
import UserSecurityQuestionPopup from "../UserSecurityQuestion/UserSecurityQuestionPopup";

let audio;

const Module = () => {
  window.document.onkeydown = function (event) {
    if (event.keyCode == 116) {
      event.preventDefault();
    }
    if (event.metaKey && event.keyCode == 82) {
      event.preventDefault();
    }
    if (event.metaKey && event.keyCode == 87) {
      event.preventDefault();
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  // logout user if idle for more than idleTimeout Amount
  const { isIdle } = useLogoutOnIdle({
    logoutCallback: () => {
      dispatch(logoutUserAction());
      history.push(`/user/signin/${params?.course_id}`);
    },
    // idleTimeOut: 1, // in minutes
  });

  // window.history.pushState(null, null, window.location.href);
  // window.onpopstate = function (e) { window.history.pushState('/user/tableofcontent')}

  const [loading, setLoading] = useState(true);
  const socket = useSelector((state) => {
    return state.userSocketioReducer?.socketConnectSuccess?.socketInstance;
  });
  const module_time_obj = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerSuccessResponse;
  });
  const timeUpObj = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerUpResponse;
  });
  const [timerCompleted, setTimerCompleted] = useState(false);
  // const module_id = useSelector(state=>{ return state.tableofContentReducer?.tableofContentDetailId?.data?.id});
  const start_module = useSelector((state) => {
    return state.userSocketioReducer?.socketTimerSuccessResponse;
  });

  const module_id = params?.module_id;
  const course_id = params?.course_id;
  const module_list = useSelector((state) => {
    return state.tableofContentReducer?.tableofContentDetailList?.data;
  });

  const [last_module_check, set_last_module_check] = useState(false);
  const [current_module_index, set_current_module_index] = useState("");

  const [content_id, set_content_id] = useState("");
  const [grapesjsData, setGrapesjsData] = useState([]);
  const [module_name, set_module_name] = useState("");
  const [audio_path, set_audio_path] = useState("");
  const [audio_player, set_audio_player] = useState();
  const [complete_only_once, set_complete_only_once] = useState(false);
  const [audio_paused, set_audio_paused] = useState(false);
  const [completion_time, set_completion_time] = useState();
  const [gethtml, setGetHtml] = useState(html);
  const [getcss, setGetCss] = useState(css);
  const [customModalShow, setcustomModalShow] = useState(false);
  const handlecustomModalClose = () => setcustomModalShow(false);
  const handlecustomModalShow = () => setcustomModalShow(true);
  const [documentList, setDocumentList] = useState([]);
  const [module_status, set_module_status] = useState("");
  const [timer_started, set_timer_started] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);
  const onPlayerInitialized = () => {
    setLoading(false);
  };
  const [showH5P, setShowH5P] = useState(false);
  const handleClose = () => setShowH5P(false);
  const handleShow = () => setShowH5P(true);
  const [interval, set_interval] = useState(1000);
  const [h5p_present, set_h5p_present] = useState(false);
  const [context_h5p, set_context_h5p] = useState("");
  const [static_module, set_static_module] = useState(true);
  const [sequence_number, set_sequence_number] = useState("");
  const [subModuleId, setSubModuleId] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (sessionStorage.getItem("userAccessToken")) {
      if (module_id) {
        let id = module_id;
        set_timer_started(false);
        getCourseModule(id);
      }
      return () => {
        set_content_id("");
        dispatch(socketEmitStopModuleAction(socket, module_id));
        if (audio) {
          audio.pause();
          audio = null;
        }
      };
    }
  }, [params, socket]);

  useEffect(() => {
    getTableofContent(course_id);
    sessionStorage.removeItem("VIDEO_PLAYED");
    sessionStorage.removeItem("HAS_VIDEO");
  }, [course_id, module_id]);

  useEffect(() => {
    if (timer_started) {
      if (!complete_only_once) {
        if (
          module_time_obj.module_id == module_id &&
          module_time_obj.time <= 0
        ) {
          //This was making course status COMPLETED without clicking on next button, which results checked on table of content screen and still the course starts from
          // dispatch(socketMarkModuleComplete(socket, module_id))
          setTimerCompleted(true);
          set_complete_only_once(true);
        } else if (
          timeUpObj &&
          timeUpObj.module_id === module_id &&
          timeUpObj.timeSpent >= completion_time
        ) {
          setTimerCompleted(true);
          set_complete_only_once(true);
        }
      }
    }
  }, [module_time_obj, timeUpObj]);

  useEffect(() => {
    getH5PId();
  }, [gethtml]);

  const handleAudio = () => {
    if (audio) {
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    } else {
      if (audio_path) {
        audio = new Audio(audio_path);
        set_audio_player(audio);
        audio.play();
      }
    }
  };

  const getH5PId = () => {
    const h5pElement = document?.querySelector(".h5p");
    if (h5pElement) {
      const id = h5pElement?.getAttribute("h5pid");
      if (id) {
        set_h5p_present(true);
        return id;
      }
    } else {
      set_h5p_present(false);
      return null;
    }
  };

  const getTableofContent = async (id) => {
    try {
      const response = await dispatch(getTableofContentListAction(id));
      const getTableofContentListSuccess = response?.payload;

      if (getTableofContentListSuccess) {
        if (getTableofContentListSuccess.data) {
          if (getTableofContentListSuccess.data.code == 200) {
            dispatch(
              tableofContentListAction(
                getTableofContentListSuccess.data.data?.rows
              )
            );
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        console.log(err);
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000,
        });
      }
    }
  };
  // get module by id
  const getCourseModule = async (id) => {
    try {
      const response = await dispatch(getModuleByIdAction(id));
      const courseModuleResponse = response?.payload;
      if (courseModuleResponse) {
        if (courseModuleResponse.data) {
          if (courseModuleResponse.data.code == 200) {
            sessionStorage.setItem('securityPopupVisible', 'true')
            sessionStorage.setItem('random', Math.random() < 0.5 ? 0 : 1);
            const data = courseModuleResponse.data;
            const {
              name,
              completion_time,
              sub_module_id,
              sequence_number,
              audio_description_file_path,
              course_module_documents,
              users,
              course_id,
              static_module,
            } = data.data;
            set_completion_time(completion_time);
            if (users[0]?.user_course_module?.status) {
              let status = users[0]?.user_course_module?.status;
              set_module_status(status);
              if (status === "IN_PROGRESS") {
                dispatch(socketEmitStartModuleAction(socket, id));
                set_timer_started(true);
              } 
            } else {
              dispatch(socketEmitStartModuleAction(socket, id));
              set_timer_started(true);
            }
            setTimerCompleted(false);
            set_complete_only_once(false);
            setDocumentList(course_module_documents);
            setGrapesjsData(data);
            set_module_name(name);
            set_static_module(static_module);
            set_sequence_number(sequence_number);
            setSubModuleId(sub_module_id);
            set_audio_path(audio_description_file_path);
            const html_data = data?.data["gjs-html"];
            const css_data = data?.data["gjs-css"];
            setGetHtml(html_data);
            setGetCss(css_data);
            const h5pid = await getH5PId();
            if (h5pid) {
              set_content_id(h5pid);
            }
          
         
          } else {
            swal({
              title: "Error!",
              text: courseModuleResponse.data.message,
              icon: "error",
              timer: 6000,
            });
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.code === 401) {
        swal({
          title: "Error!",
          text: err?.response?.data?.err,
          icon: "error",
          timer: 5000,
        }).then(() => {
          dispatch(socketDisconnectAction(socket));
          dispatch(logoutUserAction());
          history.push(`/user/signin/${params?.course_id}`);
        });
      }
    }
  };

  const handleGrapesjs = () => {
    var html = (
      <>
        <div className="grapesjsContent">
          <style>{getcss ? getcss : ""}</style>
          <div
            onCopy={handleChange}
            onCut={handleChange}
            onPaste={handleChange}
            onContextMenu={handleChange}
            onSelect={handleChange}
          >
            {gethtml ? ReactHtmlParser(gethtml) : <></>}
            {content_id ? handleh5p() : ""}
          </div>
        </div>
      </>
    );
    return html;
  };

  const handleh5p = () => {
    if (content_id) {
      try {
        return (
          <H5PPlayerUI
            ref={h5pPlayer}
            contentId={content_id}
            loadContentCallback={contentService.getPlay}
            onInitialized={onPlayerInitialized}
            onxAPIStatement={(statement, context, event) => {
              set_context_h5p(context);
            }}
          />
        );
      } catch (e) {
        swal({
          title: "Error!",
          text: e?.message,
          icon: "error",
          timer: 6000,
        });
      }
    }
  };

  const handleDownload = async (doc) => {
    var url = doc.url;
    var name = doc.name;
    if (url) {
      saveAs(url, name);
    }
  };

  const handleDownloadAll = async () => {
    var count = 0;
    let urls = [...documentList];

    urls.forEach((e, i) => {
      var nameString = e.url;
      var nameArray = nameString.split("/");
      var name = nameArray[nameArray.length - 1];
      count++;

      saveAs(nameString, name);
    });
  };

  // +================================================
  // +++++++++++++++++ Navigation ++++++++++++++++++++
  // +================================================

  const handleBackButton = () => {
    if (timer_started) {
      dispatch(socketEmitStopModuleAction(socket, module_id));
    }
    handleNavigateThroughCompletedModule("prev");
  };

  const handleNextButton = () => {
    const VIDEO_PLAYED = sessionStorage.getItem("VIDEO_PLAYED");
    const HAS_VIDEO = sessionStorage.getItem("HAS_VIDEO");
    const HAS_H5P_ACTIVITY = sessionStorage.getItem("HAS_H5P_ACTIVITY");
    const HAS_H5P_ACTIVITY_SUBMIT = sessionStorage.getItem("HAS_H5P_ACTIVITY_SUBMIT");
    if (timerCompleted || module_status == "COMPLETED") {
      if (
        module_status !== "COMPLETED" &&
        VIDEO_PLAYED !== "COMPLETED" &&
        HAS_VIDEO
      ) {
        swal({
          title: "Attention!",
          text: "Please watch all the videos in the Module",
          icon: "error",
          timer: 6000,
        });
      } else {

        console.log(' HAS_H5P_ACTIVITY ===== ',HAS_H5P_ACTIVITY);
        console.log(' HAS_H5P_ACTIVITY_SUBMIT ===== ',HAS_H5P_ACTIVITY_SUBMIT);
        console.log(' H5P_ACTIVITY CONDITION ===== ',HAS_H5P_ACTIVITY && !HAS_H5P_ACTIVITY_SUBMIT);

        if (HAS_H5P_ACTIVITY === "true") {
          if (HAS_H5P_ACTIVITY_SUBMIT === "true" && HAS_H5P_ACTIVITY_SUBMIT === "true") {
            console.log(' activity done --> goto next');
            handleNavigateThroughCompletedModule("next");
          } else {
            console.log(' --> activity not completed');
            swal({
              title: "Attention!",
              text: "You must click all required items before continuing.",
              icon: "error",
              timer: 6000,
            });
            var element = document.getElementById("h5p_content_navigate");
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }
        } else {
          console.log(' no activity --> goto next');
          handleNavigateThroughCompletedModule("next");
        }
      }
    } else {
      swal({
        title: "Attention!",
        text: "Please wait till the timer reaches 00:00:00",
        icon: "error",
        timer: 6000,
      });
    }
  };

  const handleNavigateThroughCompletedModule = (key) => {
    if (module_list) {
      const grapeJsModules = module_list.filter(
        (m) => m.module_type === MODULE_GRAPE_JS
      );
      let currentIndex = grapeJsModules.findIndex((m) => m.id === module_id);
      if (key === "next") {
        currentIndex += 1;
      }
      if (key === "prev") {
        currentIndex -= 1;
      }
      const currentModule = grapeJsModules[currentIndex];

      if (currentIndex > grapeJsModules.length - 1 || currentIndex < 0) {
        if (currentIndex < 0) {
          history.push(`/user/tableofcontent/${course_id}`);
          return;
        }
        const prevMod = grapeJsModules[currentIndex - 1];
        if (
          prevMod?.users &&
          prevMod?.users?.length > 0 &&
          prevMod?.users?.[0]?.user_course_module?.status === "COMPLETED"
        ) {
          history.push(`/user/tableofcontent/${course_id}`);
        } else {
          handleModuleNavigation();
        }
      } else {
        if (
          currentModule?.users &&
          currentModule?.users?.length > 0 &&
          currentModule?.users?.[0]?.user_course_module?.status === "COMPLETED"
        ) {
          handleNavigation(
            currentModule.module_type,
            currentModule.id,
            currentModule.sub_module_id
          );
        } else {
          handleModuleNavigation();
        }
      }
    }
  };

  const handleModuleNavigation = (e) => {
    dispatch(loadingAction(true));
    console.log(`??? trigger`);
    if (module_list) {
      var list = [...module_list];
      let current_sequence_number = sequence_number;

      // Check quizes for current module with help of sequence no.

      const currentModuleQuizes = list.filter(
        (l) =>
          Math.trunc(l.sequence_number) === current_sequence_number &&
          l.module_type === MODULE_QUIZ
      );

      if (currentModuleQuizes.length > 0 && module_status !== "COMPLETED") {
        const { id, sub_module_id, module_type } = currentModuleQuizes[0];
        handleNavigation(module_type, id, sub_module_id);
      } else {
        if (timer_started) {
          dispatch(socketEmitStopModuleAction(socket, module_id));
          dispatch(
            socketMarkModuleComplete(socket, module_id, () => {
              getTableofContent(course_id);
            })
          );
        }
        let getNextSequenceModule;
        while (
          !getNextSequenceModule &&
          current_sequence_number < list[list.length - 1].sequence_number + 1
        ) {
          let obj = list.find(
            (l) => l.sequence_number === current_sequence_number + 1
          );
          if (
            !obj?.users ||
            obj?.users?.length === 0 ||
            obj?.users?.[0]?.user_course_module?.status !== "COMPLETED"
          ) {
            getNextSequenceModule = obj;
          }
          current_sequence_number++;
        }
        if (getNextSequenceModule) {
          const { id, sub_module_id, module_type } = getNextSequenceModule;
          handleNavigation(module_type, id, sub_module_id);
        } else {
          history.push(`/user/CourseComplete/${course_id}`);
        }
      }
    }
  };

  const handleNavigation = (module_type, new_module_id, sub_id) => {
    dispatch(tableofContentQuizDetailAction(sub_id));
    dispatch(tableofContentSecurityDetailAction(sub_id));

    if (module_type == "quiz") {
      history.push(`/user/userquiz/${course_id}/${new_module_id}/${sub_id}`);
    } else if (module_type == "grapejs") {
      history.push(`/user/module/${course_id}/${new_module_id}`);
    } else if (module_type == "security_questions") {
      history.push(`/user/usersecurityquestion/${course_id}/${new_module_id}`);
    } else if (module_type == "typing_dna") {
      history.push(
        `/user/typingDnaAuthenticUserCheck/${course_id}/${new_module_id}`
      );
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  //---------------------- getSecurityQuestions-----------------------

  const storedShow = sessionStorage.getItem('securityPopupVisible');
  
  const randomChoice = sessionStorage.getItem('random');
  const popUp = () => {
    // Retrieve randomChoice from sessionStorage and parse it to a number
    let randomChoice = Number(sessionStorage.getItem('random'));
  
    // If randomChoice is not set in sessionStorage, generate a random choice (0 or 1)
    if (isNaN(randomChoice)) {
      randomChoice = Math.floor(Math.random() * 2); // Generate either 0 or 1 randomly
      sessionStorage.setItem('random', randomChoice); // Store random choice in sessionStorage
    }
  
    // Render the appropriate component based on randomChoice
    if (randomChoice === 1) {
      return (
        <UserSecurityQuestionPopup 
          course_id={course_id}
          module_id={module_id}
          completion_time={completion_time}
        />
      );
    } else if (randomChoice === 0) {
      return (
        <TypingDnaAuthenticUserCheckPopUp 
          course_id={course_id}
          module_id={module_id}
          completion_time={completion_time}
        />
      );
    }
  
    return null; // Default return in case randomChoice is invalid
  };

  return (
    <div>
      { storedShow == "true" && randomChoice ? popUp(randomChoice) : '' }
      <div className="mainSection coursemodule_main grapse_row mt-3 pb-4">
        <Container className="pt-3 pb-3">
          <Row className="justify-content-md-center d-row-flex">
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="pl-md-2 pr-md-2 pl-0 pr-0 mob_overflow"
            >
              <div className="courseDescription mt-3">
                <div className="courseHeadPara mt-3">
                  <Row
                    className="d-row-flex mb-3"
                    style={{ justifyContent: "space-between" }}
                  >
                    <Col
                      xl={3}
                      lg={3}
                      md={2}
                      sm={3}
                      xs={3}
                      className="text-left pl-md-2 pl-0 pr-0"
                    >
                      <h5 className="mb-md-3 mb-4 mt-lg-3">
                        {module_name ? module_name : "Module Name"}
                      </h5>
                    </Col>
                    <Col
                      xl={9}
                      lg={8}
                      md={10}
                      sm={9}
                      xs={9}
                      className="text-left pl-md-2 pr-md-2 pl-0 pr-2"
                    >
                      <Row
                        className="justify-content-end d-row-flex"
                        style={{ alignItems: "center" }}
                      >
                        {documentList.length > 0 && (
                          <Col
                            xl={4}
                            lg={5}
                            md={5}
                            sm={6}
                            xs={5}
                            className="md-right time d-xs-none"
                          >
                            <>
                              <div className="timeDiv text-center pl-lg-2 d-sm-block d-none">
                                <Button
                                  className="pl-lg-0"
                                  onClick={handlecustomModalShow}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    <img src={downloadblue} className="pr-1" />{" "}
                                    Learning Materials
                                  </div>
                                </Button>
                              </div>
                              <div className="timeDiv text-center d-sm-none d-block">
                                <Button
                                  className=""
                                  onClick={handlecustomModalShow}
                                >
                                  <img src={downloadblue} className="" />
                                </Button>
                              </div>
                            </>
                          </Col>
                        )}
                        <Col
                          xl={4}
                          lg={4}
                          md={4}
                          sm={6}
                          xs={7}
                          className="md-right time mt-md-0 pl-0 pr-0"
                        >
                          <>
                            <div className="timeDiv d-md-block d-none">
                              <span> Time Remaining </span>
                              <p>
                                <img src={time_clock} className="pr-2" />
                                {module_time_obj.module_id == module_id
                                  ? secondToMinuteOrHourConvert(
                                      module_time_obj.time
                                    )
                                  : `0 hr 0 min 0 sec `}
                              </p>
                            </div>

                            <div className="timeDiv d-md-none d-block">
                              <span> Time Remaining </span>
                              <p>
                                <img src={time_clock} className="pr-2" />
                                {module_time_obj.module_id == module_id
                                  ? secondToMinuteOrHourConvert(
                                      module_time_obj.time
                                    )
                                  : `0 hr 0 min 0 sec `}
                              </p>
                            </div>
                          </>
                        </Col>
                        {audio_path && audio_path !== "" ? (
                          <Col
                            xl={3}
                            lg={3}
                            md={3}
                            sm={12}
                            xs={12}
                            className="text-md-right text-center px-md-3 mt-2 px-0 px-sm-0"
                          >
                            <>
                              <img
                                className="audio_button d-block"
                                style={{ float: "right" }}
                                src={audio_play}
                                onClick={() => {
                                  handleAudio();
                                }}
                              />
                            </>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div
          className="courseDescription mt-3"
          onSelect={handleChange}
          onCopy={handleChange}
        >
          {/* {handleGrapesjs()} */}
          {static_module ? (
            <StaticModuleWrapper
              module_sequence_number={sequence_number}
              subModuleId={subModuleId}
            />
          ) : (
            handleGrapesjs()
          )}
        </div>

        <Container className="pt-3 pb-3">
          <Row className="justify-content-md-center d-row-flex">
            <Col xl={12} lg={12} md={11} sm={12} xs={12} className="">
              <Row className="justify-content-md-center d-row-flex">
                <Col xl={6} lg={6} md={6} sm={6} xs={6} className="text-right">
                  <Button
                    name="back"
                    variant="primary"
                    className="btnSign mt-4 mb-3 pl-5 pr-5 pt-1 pb-1"
                    onClick={(e) => handleBackButton(e)}
                  >
                    Back
                  </Button>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6} className="text-left">
                  <Button
                    variant="primary"
                    className="btnSign mt-4 mb-3 pl-5 pr-5 pt-1 pb-1"
                    type="submit"
                    name="next"
                    onClick={(e) => handleNextButton()}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* modal */}
        <Modal
          className="add_usermodal modal_download"
          show={customModalShow}
          onHide={handlecustomModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Download Learning Materials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <table className="table table-borderless user_table w-100">
                <tbody>
                  {documentList ? (
                    documentList.map((doc, i) => {
                      return (
                        <tr key={`module-${i}`}>
                          <td>
                            <img src={file} className="mr-3" />
                            {doc.name ? doc.name : i}
                          </td>
                          <td>
                            <a onClick={(e) => handleDownload(doc)}>
                              <img src={download_icon} className="" />
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <> </>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btnSame mt-4 add_user"
              type="submit"
              onClick={handleDownloadAll}
            >
              Download All
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Module;
