import React from "react";
import { ModuleTen } from "./ModuleTen";
import { ModuleNinePart1 } from "./ModuleNinePart1";
import { ModuleNinePart2 } from "./ModuleNinePart2";
import { ModuleEightPart1 } from "./ModuleEightPart1";
import { ModuleEightPart2 } from "./ModuleEightPart2";
import { ModuleSeven } from "./ModuleSeven";
import { ModuleSix } from "./ModuleSix";
import { ModuleFivePart1 } from "./ModuleFivePart1";
import { ModuleFivePart2 } from "./ModuleFivePart2";
import { ModuleFour } from "./ModuleFour";
import { ModuleThree } from "./ModuleThree";
import { ModuleTwo } from "./ModuleTwo";
import { ModuleOne } from "./ModuleOne";
import { ModuleZero } from "./ModuleZero";

export const StaticModuleWrapper = ({
  module_sequence_number,
  subModuleId,
}) => {
  // console.log('Static module')
  // console.log(module_sequence_number)

  const getModuleWithSequenceNumber = () => {
    if (subModuleId === "cf208c09-d17f-45bc-ae85-659f6adf62b8") {
      return <ModuleZero />;
    } else if (subModuleId === "7f683de1-5d99-47b2-91b0-d83ce2f14abb") {
      return <ModuleOne />;
    } else if (subModuleId === "7c059d9d-7263-47ec-9a3e-776f23d34f30") {
      return <ModuleTwo />;
    } else if (subModuleId === "a293b48d-56ed-40d9-b94e-fee6c18d01c4") {
      return <ModuleThree />;
    } else if (subModuleId === "6e206c0d-5d9e-44ad-b800-e2445692f0e4") {
      return <ModuleFour />;
    } else if (
      subModuleId === "647576a4-2b90-46ff-b871-f6af680c78a7" ||
      subModuleId === "35bf4fc4-66f1-440a-a6aa-d9fc972b1247"
    ) {
      return <ModuleFivePart1 />;
    } else if (subModuleId === "eda87460-f5cc-48ea-96d6-7dfdf4d97670") {
      return <ModuleFivePart2 />;
    } else if (subModuleId === "829f60b9-fc3f-4e61-827a-c151baba8045") {
      return <ModuleSix />;
    } else if (subModuleId === "fd9fcdc4-545d-412e-9fcc-d0f166e659e9") {
      return <ModuleSeven />;
    } else if (
      subModuleId === "2866b6c7-df5c-4712-94c7-05ab106175d1" ||
      subModuleId === "60984871-8f83-4ce6-882d-91efff7287c2"
    ) {
      return <ModuleEightPart1 />;
    } else if (subModuleId === "0c336106-0046-42de-9707-485273fd7a75") {
      return <ModuleEightPart2 />;
    } else if (
      subModuleId === "39db9fde-d054-46e9-8b89-b6007062a443" ||
      subModuleId === "1ac71ff7-bfec-4cdf-b5a5-11088130fb0d"
    ) {
      return <ModuleNinePart1 />;
    } else if (subModuleId === "9dfa12fc-16dc-4d89-ad16-cabfe701b8fb") {
      return <ModuleNinePart2 />;
    } else if (subModuleId === "a2821016-8c44-475c-a351-1dc9ae1d3275") {
      return <ModuleTen />;
    }
  };

  return (
    <div>
      {getModuleWithSequenceNumber()}
      {/* Static module */}
    </div>
  );
};
