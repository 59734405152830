import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Intro from "../../../../Images/module3/1.jpg";
import img2 from "../../../../Images/module3/2.png";
import img3 from "../../../../Images/module3/3.jpg";
import img5 from "../../../../Images/module3/5.jpg";
import main from "../../../../Images/module3/main.png";
import img6 from "../../../../Images/module3/7.jpg";
import img9 from "../../../../Images/module3/9.jpg";
import dot from "../../../../Images/module3/dot.png";
import tips_img from "../../../../Images/module3/tips_img.jpg";
import Blindspot from "../../../../Images/module3/blind-spot.mp4";

import AOS from "aos";
import "aos/dist/aos.css";

import "./ModuleThree.css";

export const ModuleThree = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  return (
    <div>
      <div className="module3_Cou same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module3_course">
            <Container>
              <div className="con_mod">
                <Row>
                  <Col md={6} xs={12}>
                    <div className="intro_img">
                      <h3>Habits of a</h3>

                      <h2>SAFE DRIVER</h2>

                      <div className="border_div2"></div>
                      <h4>Module 3</h4>
                      <div className="module_suspension_img">
                        <div
                          className="common_head2"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          What is defensive <br /> driving?
                        </div>
                      </div>
                      <p>
                        Defensive driving is defined as driving to prevent
                        crashes, driving carefully, making allowances for other
                        drivers, and allowing for changes in the highway
                        environment.
                      </p>
                    </div>
                  </Col>

                  <Col md={6} xs={12}>
                    <div className="dri_con">
                      <h2>
                        A driving habit is an action <br /> taken regularly.
                      </h2>

                      <Row>
                        <Col lg={4} md={4} xs={7}>
                          <div className="law_traffic">
                            <img
                              className="calendar"
                              src={img2}
                              alt=""
                              srcset=""
                            />
                          </div>
                        </Col>

                        <Col lg={8} md={6} xs={12}>
                          <h3>
                            An example of this is <br /> wearing a safety belt.
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="OwTo_Sec">
                <h2 data-aos="zoom-in-left" data-aos-duration="2000">
                  HOW TO AVOID AND PREVENT CRASHES
                </h2>
                <Row>
                  <Col lg={4} md={12} xs={12}>
                    <img
                      className="car_img"
                      src={img3}
                      alt=""
                      srcset=""
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    />
                  </Col>

                  <Col lg={8} md={12} xs={12}>
                    <ul>
                      <li>
                        Using all of your senses and developing good perceptual
                        skills.
                      </li>
                      <li>Making correct decisions and acting accordingly.</li>
                      <li>Driving carefully to minimize errors.</li>
                      <li>
                        Making allowances for the lack of skills or bad
                        attitudes of other drivers.{" "}
                      </li>
                      <li>
                        Allowing for changing weather and road conditions or the
                        actions of pedestrians and other drivers.{" "}
                      </li>
                      <li>
                        Conceding the right of way to prevent a crash, when
                        necessary.
                      </li>
                      <li>
                        Recognising a crash-producing situation far enough in
                        advance to prevent it.{" "}
                      </li>
                      <li>
                        Always wearing a seat belt to keep the driver behind the
                        wheel, where they can best control the vehicle.{" "}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div className="module3_page2">
            <Container>
              <div className="page2_con">
                <div
                  className="common_head3"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  What is a blind spot?
                </div>

                <p className="blind_spot_para">
                  This video will explain what a blind spot is and show you what
                  to do in a given situation. Click the Play button to start the
                  video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Blindspot} type="video/mp4" />
                </video>
                <ul className="mt-3">
                  <li>
                    To respond correctly to a situation on the road, a driver
                    must first recognize any possible hazards. However, there
                    are times when a driver's view is blocked or limited,
                    creating blind spots. These are areas that cannot be seen
                    using only the side or rear-view mirrors. Typically, blind
                    spots are found on both sides of the vehicle, near the rear
                    corners. To stay safe, drivers should turn their heads to
                    physically check these areas before making any moves. It's
                    important to understand that all mirrors have limitations
                    and cannot show everything.
                  </li>

                  <li>
                    Simply seeing a potential hazard is not enough; drivers need
                    to understand the risks involved. Perception means not only
                    noticing a situation but also grasping the danger it may
                    pose.
                  </li>
                </ul>
                <br />
                <h2 data-aos="zoom-in-left" data-aos-duration="2000">
                  360° OF VISION
                </h2>
                <img
                  src={img5}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                />
              </div>
            </Container>
          </div>

          <div className="module3_page3">
            <Container>
              <div
                className="common_head4"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                The Space Cushion System
              </div>

              <div className="page3_con">
                <h3>Look far ahead and be alert to potential hazards</h3>
                <p>
                  If visibility is less than ideal, you may need to slow down to
                  allow yourself more reaction time and stopping distance. For
                  example, on a clear day you might be able to see a ¼ mile
                  (1320 feet) ahead, whereas on a foggy day you might see only
                  100 feet ahead.
                </p>

                <Row>
                  <Col md={7} xs={12} className="mt-1">
                    <h3>Get the big picture</h3>
                    <p>Look at everything on both sides of the highway.</p>

                    <h3>Keep your eyes moving</h3>
                    <p>Use scanning techniques.</p>

                    <h3>Leave yourself an out</h3>
                    <p>Plan where you will steer or leave room to stop.</p>
                  </Col>

                  <Col md={5} xs={12}>
                    <div className="main_img law_traffic">
                      <img src={main} alt="" srcset="" />
                    </div>
                  </Col>
                </Row>
                <h3>Make sure other highway users see you</h3>
                <p className="ays_wdth">
                  Always use directional signals while changing lanes or turning
                  and make eye contact when possible.
                </p>

                <h4>
                  All drivers need to use their vision to see the traffic scene,
                  and to search for any conflicts or potential collisions.
                  Drivers must not see merely what they expect to see, but what
                  is actually there. For example, crashes between two-wheel
                  vehicles and cars are often due to the driver "not seeing" the
                  motorcyclist or bicyclist. Another example is an automobile
                  driver who travels over a railroad crossing every day without
                  ever seeing a train; eventually, the driver may stop looking
                  for a train. The defensive driver is always prepared for
                  something unexpected to happen.
                </h4>
              </div>

              <div
                className="common_head4"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                Distracted Driving
              </div>
              <p className="para">
                What bad behaviors do drivers engage in and why should we avoid
                them while driving?
              </p>

              <Row>
                <Col md={5} xs={12}>
                  <div className="dis_img">
                    <img
                      src={img6}
                      alt=""
                      srcset=""
                      data-aos="fade-left"
                      data-aos-duration="2000"
                    />
                  </div>
                </Col>

                <Col md={7} xs={12}>
                  <div className="dis_con">
                    <ul>
                      <li>
                        Driving is a complex mental task that requires your full
                        attention.
                      </li>
                      <li>
                        Distractions can be visual, manual or cognitive, and can
                        cause crashes, resulting in injury, death, or property
                        damage.
                      </li>
                      <li>
                        Taking your attention away from the road is dangerous.
                      </li>
                    </ul>

                    <p>
                      As a driver, you are responsible for operating your
                      vehicle in a manner that does not pose a threat to yours
                      or others' safety
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module3_page4">
            <Container>
              <div
                className="page4_bg"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <Container>
                  <p>
                    A driver’s primary task is to safely operate the motor
                    vehicle - this requires the driver’s full attention. Drivers
                    should avoid, or at least minimize, other activities while
                    driving. With preparation and common sense, you can plan
                    ahead and avoid unnecessary activities while driving and
                    avoid a crash.
                  </p>
                </Container>
              </div>

              <div
                className="common_head5"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                Tips for avoiding distracted driving
              </div>

              <div className="sec_bg">
                <Row>
                  <Col lg={5} md={12} xs={12}>
                    <img src={tips_img} alt="" srcset="" />
                  </Col>

                  <Col lg={7} md={12} xs={12}>
                    <div className="dis_con bg_t">
                      <ul>
                        <li>
                          Before you drive, prepare yourself with everything you
                          need for your trip.
                        </li>
                        <li>
                          Use optional equipment and accessories only when
                          necessary.
                        </li>
                        <li>
                          Delegate some activities. For example, you can ask a
                          passenger to adjust the climate in the car for you.
                        </li>
                        <li>
                          Avoid speaking on the phone. If the driver must use a
                          cell phone, the driver is required to use a hands-free
                          device.
                        </li>
                        <li>
                          If you need to do something, it is best to pull off
                          the road. If you cannot do so, wait for a low-risk
                          situation, such as while waiting at a traffic light,
                          or when traffic is minimal.
                        </li>
                        <li>
                          Practice good visual habits. Don’t look at passengers
                          when talking to them. Glance quickly at the radio when
                          changing stations.
                        </li>
                        <li>
                          Keep a clear head and focus on your surroundings.
                          Don’t daydream.
                        </li>
                        <li>
                          Avoid drinking, eating, and smoking while driving.
                        </li>
                      </ul>

                      {/* <p>As a driver, you are responsible for operating your vehicle in a manner that does not pose a threat to yours or others' safety</p> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
};
