import React, { useEffect, useState } from "react";
import "./ModuleSeven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import right_of_way from "../../../../Images/Module9/right_of_way.jpg";
import logo from "../../../../Images/Module9/logo.png";
import share_the_road from "../../../../Images/Module9/share_the_road.png";
import age_of_stu from "../../../../Images/Module9/age_of_stu.jpg";
import passing from "../../../../Images/Module9/passing.jpg";
import average from "../../../../Images/Module9/average.jpg";
import consequences from "../../../../Images/Module9/consequences.png";
import fine from "../../../../Images/Module9/fine.png";
import home from "../../../../Images/Module9/Home.png";
import bike1 from "../../../../Images/Module9/bike1.jpg";
import bike2 from "../../../../Images/Module9/bike2.jpg";
import bike3 from "../../../../Images/Module9/bike3.jpg";
import bike4 from "../../../../Images/Module9/bike4.jpg";
import bike5 from "../../../../Images/Module9/bike5.jpg";
import bike6 from "../../../../Images/Module9/bike6.jpg";
import bike7 from "../../../../Images/Module9/bike7.jpg";
import bike8 from "../../../../Images/Module9/bike8.jpg";
import bike9 from "../../../../Images/Module9/bike9.jpg";
import bike10 from "../../../../Images/Module9/bike10.jpg";
import bike11 from "../../../../Images/Module9/bike11.jpg";
import bike12 from "../../../../Images/Module9/bike12.jpg";
import bike_racing from "../../../../Images/Module9/bike_racing.png";
import bus from "../../../../Images/Module9/bus.png";
import sch_bus from "../../../../Images/Module9/sch_bus.png";
import bus1 from "../../../../Images/Module9/bus1.png";
import Bus_safe from "../../../../Images/Module9/m92.png";
import Train from "../../../../Images/Module9/m929.png";
import Rightway from "../../../../Images/Module9/rightway.mp4";
import Newbiker from "../../../../Images/Module9/newbiker.mp4";
import Highway from "../../../../Images/Module9/highway.jpg";
import SafetyTechnologySliderComponent from "./SafetyTechnologySliderComponent";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../services/ContentService";

import "./ModuleNine.css";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

export const ModuleNinePart2 = () => {
  const [scroll, setScroll] = useState(false);

  const contentService = new ContentService("/h5p");
  const h5pPlayer = React.useRef(H5PPlayerUI);

  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    setLoading(false);
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module9_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module9_page1">
            <div className="higway_bg">
              <Container>
                <Row>
                  <Col lg={5} md={7} xs={12}>
                    <h2 data-aos="slide-right" data-aos-duration="3000">
                      RECENT LAWS AND HIGHWAY SAFETY CONCERNS
                    </h2>
                    <h3 data-aos="fade-up" data-aos-duration="3000">
                      Module 9 - Part 2
                    </h3>
                  </Col>

                  {/* <img src={Highway} alt="image" className="highway_background"/> */}
                </Row>
              </Container>
            </div>
             
            <div className="rig_of_way">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  Right of Way Rules and Traffic Laws Every Driver Should Know
                </h2>
                <p>
                  This video will discuss important issues about Right of Way.
                  Click the Play button to start the video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Rightway} type="video/mp4" />
                </video>
                <p clsssName="mt-5">
                  <span>TURNING LEFT (VTL-1141)</span>
                  <br />
                  When you're turning left at an intersection or into a
                  driveway, alley, or private road, you must let any oncoming
                  vehicles pass first if they are already in the intersection or
                  approaching closely.
                </p>

                <p>
                  <span>MOVE OVER LAW (VTL-1144-a)</span>
                  <br />
                  If you see an emergency vehicle stopped on the side of the
                  road, you need to either switch lanes or slow down to give
                  them space and avoid any danger.
                </p>

                <p>
                  <span>ENTERING A TRAFFIC CIRCLE (VTL-1145)</span>
                  <br />
                  When you’re approaching a traffic circle or rotary, you must
                  yield to vehicles already in the circle unless a sign tells
                  you otherwise.
                </p>

                <p>
                  <span>PEDESTRIANS IN CROSSWALKS (VTL-1151-a)</span>
                  <br />
                  If traffic lights aren't working, you must slow down or stop
                  for pedestrians crossing the street at a crosswalk unless
                  there's a pedestrian tunnel or bridge.
                </p>
                <h3>TRAIN CROSSINGS (VTL-1170)</h3>

                <p>
                  When approaching a railroad crossing, stop at least 15 feet
                  from the tracks if there's any sign of a train coming.
                </p>

                <h3>VEHICLES STOPPING AT RAILROAD CROSSINGS (VTL-1171)</h3>
                <p>
                  Some vehicles, like buses and trucks carrying hazardous
                  materials, must always stop at railroad crossings, look, and
                  listen for trains, and only cross when it's safe.
                </p>

                <h3>PASSING A SCHOOL BUS (VTL-1174)</h3>
                <p>
                  If a school bus has its red lights flashing, you must stop and
                  wait until the bus starts moving again or the driver signals
                  you to pass.
                </p>

                <h3>BLOCKING AN INTERSECTION (VTL-1175)</h3>
                <p>
                  Never enter an intersection if there isn't enough space on the
                  other side for your vehicle. You shouldn’t block traffic.
                </p>

                <h3>BLOCKING A RAILROAD CROSSING (VTL-1176)</h3>
                <p>
                  When approaching a railroad crossing, make sure there's enough
                  room for your vehicle to completely clear the tracks before
                  crossing. Don’t stop on the tracks.
                </p>

                <div className="train_img">
                  <img src={Train} alt="Image" />
                </div>
              </Container>
            </div>
            <div className="page9_con some_pad">
              <Container>
                <h3>
                  <strong>
                    {" "}
                    CERTAIN VEHICLES MUST STOP AT ALL RAILROAD GRADE CROSSINGS
                    (VTL-1171){" "}
                  </strong>
                </h3>
              </Container>

              <div
                className="train_stop"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <Container>
                  <Row>
                    <Col md={5}>
                      <p>
                        Specific vehicles, including buses, school buses, and
                        those carrying hazardous materials, must stop at
                        railroad grade crossings, listen and look for
                        approaching trains, and proceed only when safe.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="page10_con some_pad">
              <Container>
                <h2>OVERTAKING AND PASSING SCHOOL BUS (VTL-1174)</h2>
                <ul>
                  <li>
                    Motorists encountering a stopped school bus with activated
                    red visual signals must stop until the bus resumes motion or
                    is signaled to proceed.
                  </li>
                  <li>
                    Clarifies terms such as "public highway" and provides
                    context for certain regulations.
                  </li>
                </ul>
                <h2>OBSTRUCTING TRAFFIC AT INTERSECTION (VTL-1175)</h2>
                <ul>
                  <li>
                    Prohibits drivers from obstructing traffic at intersections,
                    especially when insufficient space exists on the opposite
                    side for their vehicle.
                  </li>
                </ul>

                <h2>OBSTRUCTING HIGHWAY-RAILROAD GRADE CROSSINGS (VTL-1176)</h2>
                <ul>
                  <li>
                    Drivers must ensure there is adequate clearance and space on
                    the opposite side of a railroad crossing before proceeding,
                    preventing obstruction.
                  </li>
                </ul>
              </Container>
            </div>
            <div className="dis_driving">
              <Container>
                <h3 data-aos="slide-right" data-aos-duration="2000">
                  DISTRACTED DRIVING
                </h3>
                <h5>
                  Driving is a full-time responsibility. Your performance as a
                  safe driver deponds on being aware of:
                </h5>
                <ul>
                  <li data-aos="zoom-in" data-aos-duration="1000">
                    Your surroundings
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="2000">
                    Weather and road conditions
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="3000">
                    Your vehicle's condition
                  </li>
                </ul>

                <ul>
                  <li data-aos="zoom-in" data-aos-duration="1000">
                    Other traﬃc and drivers
                  </li>
                  <li data-aos="zoom-in" data-aos-duration="2000">
                    Your own physical and mental condition (are you alert?)
                  </li>
                </ul>

                <p>
                  When you are operating a motor vehicle, you need to minimize
                  anything that would interfere with your ability to concentrate
                  on driving safely. Yours and your passengers' safety depends
                  on you.
                </p>

                <p>
                  New York State has a number of laws that address distracted
                  driving. Use of an electronic handheld device, for instance,
                  can result in ﬁnes of $50 to $450 PLUS add 5 points on your
                  driving record. ”Electronic handheld devices” include (but are
                  not limited to) handheld cell phones, tablets, computers,
                  navigation systems, and gaming devices. The severity of these
                  penalties underscores the danger of distracted driving. But
                  the penalties shouldn’t be the only reason to comply with the
                  law – think about how much risk is involved in activities that
                  distract us from driving.
                </p>
              </Container>
            </div>
            <div className="safe_tech_deve some_pad">
              <Container>
                <p>
                  Anything that would interfere with your ability to focus on
                  the business of safe driving should be avoided whenever
                  possible.
                </p>
                {/* <p>
                  Click the arrows view a presentation about some examples of
                  the Safety Technology Developments and Features available in
                  some vehicles.
                </p>
                <H5PPlayerUI
                  ref={h5pPlayer}
                  contentId={"1349986157"}
                  loadContentCallback={contentService.getPlay}
                  onInitialized={onPlayerInitialized}
                  onxAPIStatement={(statement, context, event) => {}}
                /> */}

                {/* <h1 data-aos="slide-right" data-aos-duration="2000">
                  SAFETY TECHNOLOGY DEVELOPMENTS
                </h1>
                <p>
                  Today’s vehicles have more safety features than ever before.
                  Some of these features may impact how you drive. As a driver,
                  you need to know what, (if any), features your vehicle has and
                  how they work. The best source for this information would be
                  the vehicle’s owners’ manual or the manufacturer’s website.
                  Here’s a list of some of the more common features, and how
                  they may impact your driving.
                </p>

                <Row>
                  <Col md={6} xs={12}>
                    <h3 data-aos="slide-right" data-aos-duration="1000">
                      Airbags.
                    </h3>
                    <p>
                      Airbags work in conjunction with seat belts to protect the
                      driver/passengers in the event of a collision. The airbag
                      inﬂates automatically upon impact, (under the right
                      conditions), to create a cushion that absorbs much of the
                      impact energy between the driver/passengers and the
                      interior of the vehicle. The airbag then starts to deﬂate
                      almost immediately. It is crucial that the driver and
                      passengers wear their seat belts at all times.
                    </p>
                    <h3 data-aos="slide-right" data-aos-duration="2000">
                      Antilock Braking Systems (ABS).
                    </h3>
                    <p>
                      ABS prevents the wheels from locking under hard braking
                      and slippery conditions, thereby helping the driver
                      maintain steering and directional control.
                    </p>
                    <h3 data-aos="slide-right" data-aos-duration="3000">
                      Traction Control.
                    </h3>
                    <p>
                      Similar to ABS, this electronically controlled system
                      limits wheel-spin while under acceleration, thereby
                      helping the driver maintain control and minimize skids.
                    </p>
                    <h3 data-aos="slide-right" data-aos-duration="1000">
                      Forward Collision Warning.
                    </h3>
                    <p>
                      System that alerts the driver, (often through a visual as
                      well as audio alert), that a collision with an object in
                      front of the vehicle is imminent.
                    </p>
                    <h3 data-aos="slide-right" data-aos-duration="2000">
                      Automatic Emergency Braking.
                    </h3>
                    <p>
                      Automatically applies the brakes when it detects an
                      impending collision with an object directly in the
                      vehicle’s path to prevent or minimize a collision.
                    </p>
                    <h3 data-aos="slide-right" data-aos-duration="3000">
                      Pedestrian Detection.
                    </h3>
                    <p>
                      Alerts the driver when pedestrians are deemed to be a
                      hazard. May work in conjunction with the brakes of the
                      vehicle to avoid collisions with pedestrians.
                    </p>
                    <h3 data-aos="slide-right" data-aos-duration="1000">
                      Daytime Running Lights.
                    </h3>
                    <p>
                      A passive system that illuminates the headlights on the
                      vehicle at all times – even in daylight. This helps make
                      the vehicle more visible from a distance.
                    </p>
                  </Col>

                  <Col md={6} xs={12}>
                    <h3 data-aos="slide-left" data-aos-duration="1000">
                      Electronic Stability Control (ESC).
                    </h3>
                    <p>
                      This system helps keep the vehicle on its intended path
                      during a turn, to avoid sliding or skidding, by applying
                      the brakes on one or more wheels and/or reducing engine
                      power.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="2000">
                      Tire Pressure Monitor.
                    </h3>
                    <p>
                      Monitors the air pressure in the tires, and displays an
                      alert when one or more tire is not properly inﬂated.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="3000">
                      Lane Departure Warnings.
                    </h3>
                    <p>
                      Alerts the driver when it detects the vehicle is moving
                      out of the lane without the driver using a directional
                      signal.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="1000">
                      Lane Keeping Assist.
                    </h3>
                    <p>
                      A system that, when it detects that the vehicle is
                      straying from its lane, will apply some input on the
                      steering wheel to move back into the lane the vehicle was
                      in.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="2000">
                      Blind Spot Warning.
                    </h3>
                    <p>
                      This system alerts the driver when there is another
                      vehicle alongside of the driver’s vehicle. The alerts can
                      include a warning light, sound, or a combination of the
                      two.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="3000">
                      Rearview Cameras (a.k.a. backup cameras).
                    </h3>
                    <p>
                      When the transmission is in reverse, a rear-facing camera
                      transmits live images of what is behind the vehicle to the
                      driver. Most display images on a monitor in the dash or
                      rear-view mirror.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="1000">
                      Rear Cross-Traﬃc alert.
                    </h3>
                    <p>
                      A system that, when the vehicle is backing up, alerts the
                      driver when hazards approach the vehicle from the sides.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="2000">
                      Adaptive Cruise Control.
                    </h3>
                    <p>
                      Adjusts the set speed of the cruise control when it
                      detects other vehicles ahead that are moving more slowly
                      to avoid collisions.
                    </p>
                    <h3 data-aos="slide-left" data-aos-duration="3000">
                      LATCH (Lower Anchors and Tethers for Children).
                    </h3>
                    <p>
                      Allows for secure installation of child seats in vehicles.
                    </p>
                  </Col>
                </Row> */}

                <div
                  className="SafetyTechnologySlider_h5p"
                  id="h5p_content_navigate"
                >
                  {/* <iframe
                    src="https://h5p.org/h5p/embed/1233727"
                    frameborder="0"
                    allowfullscreen="allowfullscreen"
                    allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
                    title="module 9 safety technology"
                  ></iframe>
                  <script
                    src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js"
                    charset="UTF-8"
                  ></script> */}

                  <SafetyTechnologySliderComponent totalOuterSlides={6} />
                </div>

                <div className="but_img">
                  <h2 data-aos="slide-right" data-aos-duration="1000">
                    WHAT YOU, AS A DRIVER, MUST BE AWARE OF:
                  </h2>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000">
                      Not all vehicles are equipped with these features. You
                      need to be familiar with your vehicle and its features.
                    </li>
                    <li data-aos="fade-up" data-aos-duration="2000">
                      These features may not always work. Depending on
                      conditions, the system may not function well. The lens on
                      a Rearview Camera, for example, can become obstructed by
                      dirt or water – making it diﬃcult to see clearly.
                    </li>
                  </ul>
                  <p>
                    These systems can help you be a safer driver, but they can’t
                    replace you. It’s up to you to be alert and drive
                    defensively.
                  </p>
                </div>
              </Container>
              <img
                src={bus1}
                alt=""
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
