import React, { useEffect, useState } from "react";
import "./ModuleSeven.css";
import AOS from "aos";
import "aos/dist/aos.css";
import car from "../../../../Images/module7/car.png";
import LOGO_Horizontal from "../../../../Images/module7/LOGO_Horizontal.png";

import driver_beh from "../../../../Images/module7/driver_beh.png";
import arrow from "../../../../Images/module7/arrow.png";
import aggr_dri from "../../../../Images/module7/aggr_dri.png";
import time from "../../../../Images/module7/time.png";
import headphone from "../../../../Images/module7/headphone.png";
import setting from "../../../../Images/module7/setting.png";
import man from "../../../../Images/module7/man.png";
import officer from "../../../../Images/module7/officer.png";
import panel_law from "../../../../Images/module7/panel_law.png";
import coute from "../../../../Images/module7/coute.png";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import Strategies from "../../../../Images/module7/strategies.mp4";

export const ModuleSeven = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("HAS_VIDEO", true);
  }, []);

  var video = document.getElementById("video");
  useEffect(() => {
    if (video) {
      var supposedCurrentTime = 0;
      video.addEventListener("timeupdate", function () {
        if (!video.seeking) {
          supposedCurrentTime = video.currentTime;
        }
      });
      // prevent user from seeking
      video.addEventListener("seeking", function () {
        // guard agains infinite recursion:
        // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
        var delta = video.currentTime - supposedCurrentTime;
        if (Math.abs(delta) > 0.01) {
          console.log("Seeking is disabled");
          video.currentTime = supposedCurrentTime;
        }
      });
      // delete the following event handler if rewind is not required
      video.addEventListener("ended", function () {
        // reset state in order to allow for rewind
        supposedCurrentTime = 0;
      });
    }
  }, [video]);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module5_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module7_page1">
            <div className="bg_col">
              <div className="mod7_logo">
                <img
                  src={LOGO_Horizontal}
                  alt=""
                  srcset=""
                  data-aos="slide-right"
                  data-aos-duration="2000"
                />
              </div>
              <div className="module7">
                <Container>
                  <Row>
                    <Col md={6} xs={12}>
                      <div
                        className="titl_1"
                        data-aos="zoom-in"
                        data-aos-duration="3000"
                      >
                        <h2>Road Rage and Aggressive Driving</h2>
                        <p>Module 7</p>
                      </div>
                    </Col>

                    <Col md={6} xs={12}>
                      <div className="img_1">
                        <img
                          src={car}
                          alt=""
                          srcset=""
                          data-aos="zoom-in"
                          data-aos-duration="2000"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="road_def">
                    <h2 data-aos="slide-up">Road rage definition:</h2>
                    <ul className="pl-0">
                      <li data-aos="slide-left" data-aos-duration="1000">
                        Road rage is an emotional state of anger or hostility,
                        resulting from an incident involving the use of a motor
                        vehicle, which escalates into violent criminal acts, or
                        threats and attempts of violent acts. Road rage may
                        include provocative behavior intended to intimidate,
                        harass others, or instill fear in them.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="2000">
                        Aggressive driving is not road rage. However, aggressive
                        driving can escalate into road rage. Aggressive driving
                        generally involves traffic infractions (such as speeding
                        or passing a stop sign) while road rage generally
                        involves committing crimes.
                      </li>
                      <li data-aos="slide-left" data-aos-duration="3000">
                        Road rage contributes to violent and dangerous driving
                        conditions.
                      </li>
                    </ul>
                  </div>

                  <Row className="pb-5">
                    <Col md={5} xs={12}>
                      <div className="driver_beh_img">
                        <img
                          src={driver_beh}
                          alt=""
                          data-aos="zoom-in"
                          data-aos-duration="2000"
                        />
                      </div>
                    </Col>

                    <Col md={7} xs={12}>
                      <div className="behav">
                        <h2 data-aos="slide-up" data-aos-duration="1000">
                          The following behaviors constitute road rage:
                        </h2>
                        <ul className="d-flex">
                          <li
                            className="law_traffic"
                            style={{ position: "relative", top: "4rem" }}
                          >
                            <img src={arrow} alt="" />
                          </li>
                          <li
                            className="behaviour_rage"
                            data-aos="slide-left"
                            data-aos-duration="1000"
                          >
                            <span>Driver behaviors -</span> Yelling, cursing,
                            excessive horn honking, rude or obscene gestures and
                            threats which may lead to:
                          </li>
                        </ul>
                        <ul className="d-flex">
                          <li
                            className="law_traffic"
                            style={{ position: "relative", top: "5rem" }}
                          >
                            <img src={arrow} alt="" />
                          </li>
                          <li
                            className="behaviour_rage"
                            data-aos="slide-left"
                            data-aos-duration="1000"
                          >
                            <span>Vehicular actions -</span> Cutting off,
                            extremely close tailgating, blocking maneuvers,
                            pursuing or chasing, running another vehicle off the
                            road, deliberate ramming or bumping of another
                            vehicle which may lead to:
                          </li>
                        </ul>
                        <ul className="d-flex">
                          <li
                            className="setUl behaviour_rage1"
                            data-aos="slide-left"
                            data-aos-duration="1000"
                          >
                            <span>Actions outside the vehicle -</span> Exiting
                            the vehicle to threaten, frighten, attack, fight and
                            hurt another motorist, pedestrian, cyclist, etc.
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            <div className="str">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  Strategies for avoiding road rage
                </h2>

                <p className="strategy_para_road">
                  The following video will give you some tips and strategies for
                  avoiding road rage. Click the Play button to view the video.
                </p>

                <video
                  id="video"
                  width="100%"
                  height="400"
                  autoplay={true}
                  onEnded={() => {
                    sessionStorage.setItem("VIDEO_PLAYED", "COMPLETED");
                  }}
                  controls
                  controlsList="nodownload noplaybackrate"
                >
                  <source src={Strategies} type="video/mp4" />
                </video>
              </Container>
            </div>

            <div className="agg_beh">
              <Container>
                <Row>
                  <Col md={7} xs={12}>
                    <h2
                      className="text-left"
                      data-aos="slide-right"
                      data-aos-duration="2000"
                    >
                      Aggressive behavior and road rage
                    </h2>
                    <ul
                      className="pl-0"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <li>
                        Aggressive drivers respond to a stressful situation with
                        hostility.
                      </li>
                      <li>
                        People who engage in aggressive driving are more
                        affected by mood than other drivers. Being in a bad mood
                        appears to have an adverse effect on driving behavior.
                      </li>
                      <li>
                        Rage causes blood pressure to rise in the average
                        driver.
                      </li>
                      <li>
                        Facial muscles tighten while experiencing anger or road
                        rage.
                      </li>
                      <li>
                        The average driver can reduce road rage potential by
                        reducing stress while driving.
                      </li>
                    </ul>
                  </Col>
                  <Col md={7} xs={12}>
                    <h2
                      className="text-left"
                      data-aos="slide-right"
                      data-aos-duration="2000"
                    >
                      Hazards of Driving Under the Influence of Road Rage
                    </h2>
                    <ul
                      className="pl-0"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <li>
                        <b>
                          <i>Impaired Judgment: </i>
                        </b>{" "}
                        Rage can cloud a driver's judgment, leading to rash
                        decisions like tailgating, speeding, or weaving through
                        traffic.
                      </li>
                      <li>
                        <b>
                          <i>Distracted Driving: </i>
                        </b>{" "}
                        Anger can distract a driver from focusing on the road.
                        Shouting, making gestures, or obsessing over an incident
                        can divert attention from the primary task of driving,
                        increasing the risk of accidents.
                      </li>
                      <li>
                        <b>
                          <i>Increased Collision Risk: </i>
                        </b>{" "}
                        Aggressive behaviors, like cutting off other drivers,
                        not signaling lane changes, or braking suddenly, can
                        lead to serious collisions.
                      </li>
                      <li>
                        <b>
                          <i>Reduced Reaction Time: </i>
                        </b>{" "}
                        A driver consumed by rage might not react quickly enough
                        to sudden obstacles, brake lights, or other unexpected
                        events.
                      </li>
                      <li>
                        <b>
                          <i>Ignoring Traffic Rules: </i>
                        </b>{" "}
                        An angry driver might speed, run red lights, or ignore
                        stop signs, compromising safety.
                      </li>
                    </ul>
                  </Col>
                  <Col md={7} xs={12}>
                    <h2
                      className="text-left"
                      data-aos="slide-right"
                      data-aos-duration="2000"
                    >
                      Hazards of Exiting the Vehicle Under Road Rage
                    </h2>
                    <ul
                      className="pl-0"
                      data-aos="slide-right"
                      data-aos-duration="1000"
                    >
                      <li>
                        <b>
                          <i>Physical Confrontations: </i>
                        </b>{" "}
                        Tempers can flare beyond verbal arguments, leading to
                        physical altercations. Such confrontations can result in
                        injuries or even fatalities.
                      </li>
                      <li>
                        <b>
                          <i>Vulnerability to Passing Traffic: </i>
                        </b>{" "}
                        Exiting a vehicle on a busy road or highway exposes the
                        enraged individual to the dangers of passing traffic.
                      </li>
                      <li>
                        <b>
                          <i>Escalation: </i>
                        </b>{" "}
                        Confronting another driver can escalate the situation,
                        drawing in other drivers or bystanders and creating a
                        larger, more hazardous scene.
                      </li>
                      <li>
                        <b>
                          <i>Legal Consequences: </i>
                        </b>{" "}
                        Engaging in physical confrontations or threatening
                        behavior can lead to arrest, legal penalties, or
                        potential lawsuits.
                      </li>
                    </ul>
                  </Col>
                  <Col md={5} xs={12}>
                    <img
                      src={aggr_dri}
                      alt=""
                      data-aos="zoom-out"
                      data-aos-duration="1000"
                    />
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="sugge_sec_bg">
              <Container>
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  Suggestions for reducing stress:
                </h2>
                <Row>
                  <Col md={3} xs={12}>
                    <div
                      className="sugge_box"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <img src={time} alt="" />
                      <p>Leave plenty of time to get to your destination.</p>
                    </div>
                  </Col>

                  <Col
                    md={3}
                    xs={12}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={headphone} alt="" />
                    <p>Listen to relaxing music in your car.</p>
                  </Col>

                  <Col
                    md={3}
                    xs={12}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={setting} alt="" />
                    <p>
                      Make sure your car is regularly serviced and in good
                      working order.
                    </p>
                  </Col>

                  <Col
                    md={3}
                    xs={12}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <img src={man} alt="" />
                    <p>Make your vehicle comfortable.</p>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <div className="penalties">
                <h2 data-aos="slide-right" data-aos-duration="2000">
                  Penalties and Charges for Road Rage
                </h2>
                <h4>VEHICLE AND TRAFFIC LAW</h4>
                <Row>
                  <Col md={5} xs={12}>
                    <img className="law_traffic" src={officer} alt="" />
                  </Col>

                  <Col md={7} xs={12}>
                    <h3>Under Section 1212 Vehicle and Traffic Law,</h3>
                    <p>
                      Reckless Driving is a misdemeanor and carries a penalty of
                    </p>
                    <h5 data-aos="slide-left" data-aos-duration="2000">
                      5 POINTS.
                    </h5>
                    <h6>Source- AAA Foundation for Traffic Safety</h6>
                  </Col>
                </Row>
              </div>
            </Container>

            <div className="penal_law">
              <h2>PENAL LAW</h2>
              <Container>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="penal_law_con">
                      <h3>Criminally Negligent Homicide</h3>
                      <Card className="QuizCollapse">
                        <h6>Section 125.10 of Penal Law</h6>
                        <Card.Body className="timepara">
                          <ul>
                            <li>
                              "A person is guilty of criminally negligent
                              homicide when, with criminal negligence, he causes
                              the death of another person." (In the case of road
                              rage, this would result through the use of a motor
                              vehicle)
                            </li>
                            <li>
                              Criminally negligent homicide is a Class E felony
                              with a maximum sentence of 4 years imprisonment.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col md={6} xs={12}>
                    <div className="penal_law_con">
                      <h3>Assault in the First Degree</h3>
                      <Card className="QuizCollapse">
                        <h6>Section 120.10 of Penal Law</h6>
                        <Card.Body className="timepara">
                          <ul>
                            <li>
                              "A person is guilty of assault in the first degree
                              when, with intent to cause serious physical injury
                              to another person, he causes such injury to such
                              person or to a third person by means of a deadly
                              weapon or a dangerous instrument (in this case a
                              motor vehicle) or, under circumstances evincing a
                              depraved indifference to human life, he recklessly
                              engages in conduct which creates a grave risk of
                              death to another person, and thereby causes
                              serious physical injury to another person."
                            </li>
                            <li>
                              Assault in the first degree is a Class B violent
                              felony with a maximum sentence of 25 years
                              imprisonment.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Container>

              <div className="panel_law_img">
                <img
                  src={panel_law}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
              </div>

              <Container>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="penal_law_con">
                      <h3>Assault in the Second Degree</h3>
                      <Card className="QuizCollapse">
                        <h6>Section 120.05 of Penal Law</h6>
                        <Card.Body className="timepara">
                          <ul>
                            <li>
                              “A person is guilty of assault in the second
                              degree when, with intent to cause serious physical
                              injury to another person, he causes such injury to
                              such person or to a third person; or, with intent
                              to cause physical injury to another person, he
                              causes such injury to such person or to a third
                              person by means of a deadly weapon or a dangerous
                              instrument.”
                            </li>
                            <li>
                              Assault in the second degree is a Class D violent
                              felony with a maximum sentence of 7 years
                              imprisonment.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col md={6} xs={12}>
                    <div className="penal_law_con">
                      <h3>Assault in the Third Degree</h3>
                      <Card className="QuizCollapse">
                        <h6>Section 120.00 of the Penal Law</h6>
                        <Card.Body className="timepara">
                          <ul>
                            <li>
                              “A person is guilty of assault in the third degree
                              when ,with intent to cause physical injury to
                              another person, he causes such injury to such
                              person or to a third person; or, he recklessly
                              causes physical injury to another person; or, with
                              criminal negligence, he causes physical injury to
                              another person by means of a deadly weapon or a
                              dangerous instrument.”
                            </li>
                            <li>
                              Assault in the third degree is a Class A
                              misdemeanor with a maximum sentence of 1 year
                              imprisonment.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Container>

              <div className="panel_law_img">
                <img
                  src={coute}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
              </div>

              <Container>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="penal_law_con">
                      <h3>Manslaughter in the First Degree</h3>
                      <Card className="QuizCollapse">
                        <h6>Section 125.20 of the Penal Law</h6>
                        <Card.Body className="timepara">
                          <ul>
                            <li>
                              ”A person is guilty of manslaughter in the first
                              degree when, with intent to cause serious physical
                              injury to another person, he causes the death of
                              such person or of a third person.”
                            </li>
                            <li>
                              Manslaughter in the first degree is a Class B
                              violent felony with a maximum sentence of 25 years
                              imprisonment.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col md={6} xs={12}>
                    <div className="penal_law_con">
                      <h3>Manslaughter in the Second Degree</h3>
                      <Card className="QuizCollapse">
                        <h6>Section 125.15 of the Penal Law</h6>
                        <Card.Body className="timepara">
                          <ul>
                            <li>
                              A person is guilty of manslaughter in the second
                              degree when, "He recklessly causes the death of
                              another person."
                            </li>
                            <li>
                              Manslaughter in the second degree is a Class C
                              felony with a maximum sentence of 15 years
                              imprisonment.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Container>

              <Container>
                <Row>
                  <Col md={12} xs={12}>
                    <div className="penal_law_con">
                      <h3>Fines for Felony Convictions</h3>
                      <ul>
                        <li>
                          For felony convictions, a fine of up to $5,000 may be
                          levied in addition to the term of imprisonment. A
                          felony conviction also carries a mandatory surcharge
                          of $300 and victim assistance fee of $25.
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>

              <div
                className="law_sec"
                data-aos="slide-right"
                data-aos-duration="1000"
              >
                <Container>
                  <h2>THE VEHICLE AND TRAFFIC LAW SECTION 510 (2):</h2>
                  <p>
                    Requires the revocation of the driver's license of anyone
                    convicted of a homicide or assault arising out of the
                    operation of a motor vehicle (or motorcycle), or criminal
                    negligence in the operation of a motor vehicle resulting in
                    death, whether the conviction was had in this state or
                    elsewhere.
                  </p>
                </Container>
              </div>
              <div className="fot_logo">
                <img
                  src={LOGO_Horizontal}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
