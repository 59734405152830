import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import Intro from "../../../../Images/module/intro.png";
import Purpose from "../../../../Images/module/purpose.png";
import Traffic from "../../../../Images/module/traffic1.png";
import Bell from "../../../../Images/module/bell.png";
import Watch from "../../../../Images/module/watch.png";
import Vehicletraffic from "../../../../Images/module/vehicle_law.png";
import Pdf from "../../../../Images/module/pdf.png";
import Http from "../../../../Images/module/http.png";
import Learnrule from "../../../../Images/module/learn_rule.png";
import Probation from "../../../../Images/module/probation.png";
import Man from "../../../../Images/module/man.png";
import Driving from "../../../../Images/module/driving_school.png";
import Logo from "../../../../Images/logo.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./StaticModule.css";

export const ModuleOne = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [activeId, setActiveId] = useState("");

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <div className="module1_course same_width_module mt-md-4 mb-md-4">
        <Container>
          <div className="module_intro">
            <Container>
              <Row>
                <Col md={6} xs={12}>
                  <div className="intro_img text-center">
                    <img src={Intro} data-aos="fade-up" alt="Image" />
                  </div>
                </Col>

                <Col md={6} xs={12}>
                  <div
                    className="intro_content"
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                  >
                    <h1>
                      Introduction to the
                      <span className="mt-2 d-md-block d-none">
                        pre-licensing <br /> course
                      </span>
                      <span className="mt-2 d-md-none d-block">
                        pre-licensing course
                      </span>
                    </h1>
                    <h4 className="text-uppercase mt-3">Module 1</h4>
                  </div>
                </Col>
              </Row>
              <div className="border_div"></div>
            </Container>
          </div>

          <div className="module_objective mt-3 mb-3">
            <Container>
              <h2
                className="common_head"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                objectives
              </h2>
              <Row>
                <Col md={6} xs={12}>
                  <ul className="pl-0 module_list">
                    <li>
                      Identify the purpose, mission, and goals of the Course.
                    </li>
                    <li>
                      Summarize New York State Vehicle and Traffic Law
                      requirements for pre-licensing course.
                    </li>
                  </ul>
                </Col>

                <Col md={6} xs={12}>
                  <ul className="p-0 module_list">
                    <li>Recall the rules for the learner permit holders.</li>
                    <li>
                      Recall the rules of the probationary period for new
                      drivers in New York.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module_purpose mt-3 mb-3">
            <Container>
              <Row>
                <Col lg={6} md={12} xs={12}>
                  <div className="purpose_img">
                    <img
                      src={Purpose}
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                      alt="Image"
                    />
                  </div>
                </Col>

                <Col lg={6} md={12} xs={12}>
                  <h2
                    className="common_head mt-md-5"
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                  >
                    purpose of the <br /> pre-licensing course
                  </h2>
                  <p> To help the new driver : </p>
                  <ul className="pl-0 module_list">
                    <li>Become aware of the driver's responsibilities.</li>
                    <li>Understand the "rules of the road".</li>
                    <li>
                      Use safe driving concepts and crash avoidance techniques.
                    </li>
                    <li>
                      Recognize the risk of driving when sleepy or under the
                      influence of alcohol.
                    </li>
                    <li>Learn about current safety concerns and laws.</li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          <div
            className="module_requirement pb-md-3"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <Container>
              <h2 className="pt-4 mb-3">
                new york state vehicle and traffic law requirements for
                pre-licensing courses
              </h2>

              <Row>
                <Col md={6} xs={12} className="law_div_border">
                  <div className="law_div">
                    <Row>
                      <Col md={2} xs={3}>
                        <div className="law_div_img law_traffic">
                          <img src={Traffic} alt="Image" />
                        </div>
                      </Col>

                      <Col md={10} xs={9}>
                        <div className="law_div_content">
                          <p>
                            You will receive a course completion confirmation
                            email from Online Traffic Safety, once you
                            successfully complete the course.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="law_div">
                    <Row>
                      <Col md={2} xs={3}>
                        <div className="law_div_img law_bell">
                          <img src={Bell} alt="Image" />
                        </div>
                      </Col>

                      <Col md={10} xs={9}>
                        <div className="law_div_content">
                          <p>
                            The NY DMV will be notified that you have completed
                            the course. It may take up to 24-48 hours to be
                            added to your driver record.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md={6} xs={12}>
                  <ul className="pl-0 module_list mt-md-4">
                    <li>
                      After 24-48 hours, once your electronic record is updated,
                      you will be able to schedule a road test
                    </li>
                    <li>
                      You will not need to provide a Completion Certificate to
                      the NY DMV when you take your road test. The DMV will
                      already have your completion records on file
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module_valid mt-4">
            <Container>
              <h2
                className="common_head mb-4"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                how long is this course valid for?
              </h2>
              <Row>
                <Col md={6} xs={12}>
                  <div className="module_valid_detail">
                    <Row>
                      <Col md={2} xs={3}>
                        <div className="module_valid_img calendar mt-md-0 mt-2">
                          <img src={Watch} alt="Image" />
                        </div>
                      </Col>

                      <Col md={10} xs={9}>
                        <p>
                          The course is valid for one year from the date of
                          completion.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module_vehicle_law mt-3">
            <Container>
              <Row>
                <Col md={6} xs={12}>
                  <div className="vehicle_traffic">
                    <h3>vehicle & traffic law</h3>

                    <img
                      src={Vehicletraffic}
                      data-aos="fade-right"
                      data-aos-duration="2000"
                      alt="Image"
                    />
                  </div>
                </Col>

                <Col md={6} xs={12}>
                  <h2
                    className="common_head mt-2"
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                  >
                    mission of the <br /> pre-licensing course
                  </h2>

                  <p className="mt-4">
                    The Pre-Licensing Course is a program designed to educate
                    New York State's new drivers by promoting safe, courteous,
                    defensive and alcohol/drug-free driving.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module_goals mt-3">
            <Container>
              <h2
                className="common_head mt-2"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                {" "}
                goals of the pre-licensing course
              </h2>
              <Row>
                <Col md={6} xs={12}>
                  <ul className="pl-0 module_list mt-md-1">
                    <li>
                      To reduce injuries, crashes, traffic violations and
                      property damage.
                    </li>
                    <li>To help students become responsible drivers.</li>
                    <li>
                      To learn about the dangers of fatigue, distraction,
                      alcohol and other drugs on driving ability.
                    </li>

                    <li>
                      To develop a positive attitude towards safe and defensive
                      driving.
                    </li>
                  </ul>
                </Col>

                <Col md={6} xs={12}>
                  <ul className="pl-0 module_list mt-md-1">
                    <li>
                      To encourage and promote safety belt and child safety seat
                      use.
                    </li>
                    <li>
                      To learn how to fit safely into the transportation system
                      with other highway users.
                    </li>
                    <li>
                      To familiarize students with the “rules of the road.”
                    </li>

                    <li>
                      To teach new drivers about showing courtesy to others when
                      they are driving.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          <div
            className="module_learn_rules mt-3 pb-md-3 pb-4"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <Container>
              <Row style={{ alignItems: "center" }}>
                <Col md={7} xs={12}>
                  <h3 className="mt-4">RULES FOR LEARNER PERMIT HOLDERS</h3>
                  <p>Learner permit holders may never drive:</p>
                  <ul className="pl-0 module_list mt-md-1">
                    <li>
                      Unless accompanied by a supervising driver who has a valid
                      license to operate the vehicle you are driving and is at
                      least the age of 21.
                    </li>
                    <li>
                      In any street that is within a park in New York City, or
                      any bridge or tunnel under the jurisdiction of the
                      Triborough Bridge and Tunnel Authority.
                    </li>
                    <li>
                      On the Cross County, Hutchinson River, Saw Mill River, or
                      Taconic State parkways in Westchester County.
                    </li>
                  </ul>
                </Col>
                <Col md={5} xs={12} style={{ padding: 0 }}>
                  <img
                    src={Learnrule}
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                    alt="Image"
                    width="100%"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6} xs={12}>
                  <div className="module_learn_border mt-5 pt-md-3">
                    <Row>
                      <Col md={7} xs={12}>
                        <h5>
                          YOU SHOULD CHECK FOR REGIONAL RESTRICTIONS IN YOUR
                          AREA.
                        </h5>
                      </Col>

                      <Col md={4} xs={12} className="ml-md-3">
                        <div className="module_pdf mt-3">
                          <div className="text-left pdf_img d-md-block d-inline-block">
                            <img src={Pdf} alt="Image" />
                          </div>
                          <div className="text-left pdf_img d-md-block d-inline-block ml-md-0 ml-5">
                            <img src={Http} alt="Image" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md={6} xs={12}>
                  <div className="module_learn_head mt-md-3 mt-3 pt-md-3">
                    <h4>PROBATIONARY PERIOD FOR NEWLY LICENSED DRIVERS</h4>
                    <Card className="QuizCollapse">
                      <h6>What is a “Probationary License”?</h6>
                      <Card.Body className="timepara">
                        <p>
                          Any driver’s license, including a license obtained
                          after a revocation, is considered probationary for six
                          months following the date of issuance. This does not
                          include class DJ or MJ license.
                        </p>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module_probation mt-4 pt-3">
            <Container>
              <h2 className="mt-md-4">
                WHILE ON PROBATION, A CONVICTION FOR ANY OF THE FOLLOWING WILL
                RESULT IN THE LICENSE BEING SUSPENDED FOR 60 DAYS:
              </h2>
              <Row>
                <Col md={6} xs={12}>
                  <ul className="pl-0 module_list mt-md-4">
                    <li>Speeding</li>
                    <li>Reckless driving</li>
                    <li>Following too closely</li>
                    <li>Participating in a speed contest</li>
                    <li>
                      Use of a mobile telephone (such as a cellular phone)
                    </li>
                    <li>
                      Use of a portable electronic device (such as a smartphone,
                      GPS or MP3 Player)
                    </li>
                    <li>Two unmentioned traffic violations</li>
                  </ul>
                </Col>

                <div className="module_probation_img">
                  <img
                    src={Probation}
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                    alt="Image"
                  />
                </div>
              </Row>
            </Container>
          </div>

          <div className="module_suspension mt-2">
            <Container>
              <h2
                className="common_head mt-2 mb-3 text-md-center"
                data-aos="zoom-in-right"
                data-aos-duration="2000"
              >
                {" "}
                WHEN THE SUSPENSION ENDS, THERE WILL BE A SECOND SIX-MONTH
                PROBATION PERIOD.
              </h2>

              <Row>
                <Col md={3} xs={12}>
                  <div className="module_suspension_img">
                    <img
                      src={Man}
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      alt="Image"
                    />
                  </div>
                </Col>

                <Col md={9} xs={12}>
                  <ul className="pl-0 module_list mt-lg-5 mt-md-3">
                    <li>
                      A conviction for one of the violations, or two other
                      moving violations during this second probation period,
                      will result in the license being revoked for at least six
                      months.
                    </li>

                    <li>
                      When the revocation ends, the motorist will have to
                      reapply to the DMV, and if relicensed, will serve another
                      six-month probation period.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="module_subject mt-md-4 mt-2">
            <Container>
              <Row>
                <Col md={8} xs={12}>
                  <h2
                    className="common_head mt-2 mb-3"
                    data-aos="zoom-in-right"
                    data-aos-duration="2000"
                  >
                    WHEN YOU MAY BE SUBJECT TO A DRIVER RESPONSIBILITY
                    ASSESSMENT:
                  </h2>

                  <ul className="pl-0 module_list mt-md-4">
                    <li>
                      You have accumulated six or more points on your driving
                      record within an 18-month period, and/or
                    </li>

                    <li>
                      You have been convicted of any alcohol or drug-related
                      driving offense, and/or
                    </li>

                    <li>You have refused to submit to a chemical test.</li>
                  </ul>
                </Col>

                <div className="module_subject_img">
                  <img
                    src={Driving}
                    data-aos="zoom-in-left"
                    data-aos-duration="2000"
                    alt="Image"
                  />
                </div>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
};
