import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import skidImage from "../../../../Images/module4/skid.jpg";
import b15Image from "../../../../Images/module4/b15.png";
import b16Image from "../../../../Images/module4/b16.jpg";
import b17Image from "../../../../Images/module4/b17.jpg";
import b18Image from "../../../../Images/module4/b18.jpg";
import notePoster from "../../../../Images/module4/note-poster.png";

import "./ModuleFour.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`parent-buttons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`parent-buttons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function innerSampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`inner-buttons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function innerSamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`inner-buttons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SkidsSliderComponentCopy({ totalOuterSlides }) {
  const [progress, setProgress] = useState((1 / totalOuterSlides) * 100);
  const [innerCurrentSlide, setInnerCurrentSlide] = useState(0);

  useEffect(() => {
    sessionStorage.setItem("HAS_H5P_ACTIVITY", true);
    sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", false);
  }, []);

  const handleBeforeChange = (oldIndex, newIndex) => {
    const calc =
      (newIndex / totalOuterSlides) * 100 + (1 / totalOuterSlides) * 100;
    if (calc == 100) {
      sessionStorage.setItem("HAS_H5P_ACTIVITY_SUBMIT", true);
    }
    setProgress(calc);
  };

  const handleAfterChange = (index) => {
    setInnerCurrentSlide(index);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: handleBeforeChange,
  };

  const innerSliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    swipe: false,
    nextArrow: <innerSampleNextArrow />,
    prevArrow: <innerSamplePrevArrow />,
    afterChange: handleAfterChange,
  };

  return (
    <div className="slider-container">
      <h3 className="h5p-content-note">
        Note: Please visit all slides before going further.
      </h3>

      <Slider {...settings}>
        {/* slide 1 */}
        <div className="outer-slide">
          <Row className="slide-heading">
            <h1>HOW DO YOU AVOID SKIDS?</h1>
          </Row>
          <Row className="slide-content">
            {/* <Col>
              <img src={skidImage} height="250px" alt="Image" />
            </Col> */}
            <Col>
              <ul className="slide-ul">
                <li>
                  <h3>
                    To ensure safety on wet, snowy, or icy roads, it's advisable
                    to brake ahead of time, with caution and gentleness.
                  </h3>
                </li>
                <li>
                  <h3>
                    Take note of the type of brakes installed in the vehicle
                    you're operating. Many vehicles are outfitted with an
                    anti-lock braking system (ABS).
                  </h3>
                </li>
                <li>
                  <h3>
                    When driving a vehicle equipped with ABS, refrain from
                    pumping the brakes manually as the system automatically
                    modulates brake pressure.
                  </h3>
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        {/* slide 2 */}
        <div className="outer-slide">
          <div>
            <Row className="slide-heading">
              <h1>IF YOU FEEL LIKE YOU ARE SKIDDING:</h1>
            </Row>
            <Row className="slide-content">
              <Col>
                <ul className="slide-ul">
                  <li>
                    <h3>
                      In the event of a skid, maintain consistent and firm brake
                      pressure. If your vehicle lacks ABS, apply the brakes in a
                      controlled manner by gently pumping them with slow, steady
                      strokes. Ensure the wheels continue to roll freely.
                    </h3>
                  </li>
                  <li>
                    <h3>
                      If your wheels begin to lock up, gently release pressure
                      from the brake pedal.
                    </h3>
                  </li>
                  <li style={{ display: "flex", flexWrap: "wrap" }}>
                    <h3>
                      While decelerating, consider shifting into a lower gear
                      for added control.
                    </h3>
                    <img src={b15Image} height="150px" alt="Image" />
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>

        {/* slide 3 */}
        <div className="outer-slide">
          <div>
            <Row className="slide-heading">
              <h1>IF YOUR REAR WHEELS START TO SKID:</h1>
            </Row>
            <Row className="slide-content diff">
              <Row>
                <Col>
                  <ul className="slide-ul">
                    <li>
                      <h3>
                        Steer the wheel towards the direction the vehicle is
                        attempting to move.
                      </h3>
                    </li>
                    <li>
                      <h3>
                        If your rear wheels slide left, steer left; if they
                        slide right, steer right.
                      </h3>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row className="flex-list">
                <Col>
                  <ul className="slide-ul">
                    <li>
                      <h3>
                        If your rear wheels begin to slide in the opposite
                        direction during recovery, gently steer the wheel
                        towards that side.
                      </h3>
                    </li>
                    <li>
                      <h3>
                        You may need to alternate steering left and right
                        several times to regain full control of your vehicle.
                      </h3>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <img src={b16Image} height="250px" alt="Image" />
                </Col>
              </Row>
            </Row>
          </div>
        </div>

        {/* slide 4 */}
        <div className="outer-slide">
          <div>
            <Row className="slide-heading">
              <h1>IF YOUR REAR WHEELS START TO SKID:</h1>
            </Row>
            <Row className="slide-content">
              <Row>
                <img
                  src={b17Image}
                  height="250px"
                  alt="Image"
                  className="slide-content-larg-img"
                />
              </Row>
              <Row>
                <ul className="slide-ul">
                  <li>
                    <h3>
                      If your vehicle is equipped with ABS, maintain consistent
                      pressure on the brake pedal. Without ABS, apply gentle,
                      rhythmic pressure to the pedal, increasing frequency as
                      your vehicle decelerates. Heavy braking without ABS can
                      exacerbate skidding.
                    </h3>
                  </li>
                </ul>
              </Row>
            </Row>
          </div>
        </div>

        {/* slide 5 */}
        <div className="outer-slide">
          <Row className="slide-heading">
            <h1>IF YOUR FRONT WHEELS START TO SKID:</h1>
          </Row>
          <Row className="slide-content">
            <Row className="flex-list">
              <Col>
                <img src={b18Image} height="250px" alt="Image" />
              </Col>
              <Col>
                <ul className="slide-ul">
                  <li>
                    <h3>
                      Lift your foot off the accelerator and shift to neutral or
                      depress the clutch, but refrain from attempting immediate
                      steering adjustments.
                    </h3>
                  </li>
                  <li>
                    <h3>
                      As the wheels slide sideways, they'll decelerate the
                      vehicle, allowing traction to be regained.
                    </h3>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="slide-ul">
                  <li>
                    <h3>
                      Once traction is restored, steer in the desired direction
                      of travel.
                    </h3>
                  </li>
                  <li>
                    <h3>
                      Next, engage the transmission in drive or release the
                      clutch, then accelerate smoothly and steadily.
                    </h3>
                  </li>
                </ul>
              </Col>
            </Row>
          </Row>
        </div>

        {/* slide 6 */}
        <div className="outer-slide">
          <div style={{ display: "flex", height: "100%" }}>
            <Row className="slide-content">
              <Col>
                <img src={notePoster} height="250px" alt="Image" />
                <h3 className="note">NOTE :</h3>
              </Col>
              <Col>
                <ul className="slide-ul">
                  <li>
                    <h3>
                      When sleet, freezing rain and snow start to fall, remember
                      that bridges, ramps and overpasses are likely to freeze
                      first, and that slippery spots may remain after road crews
                      have cleared the highways.
                    </h3>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Slider>
      <div
        className="progress"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ backgroundSize: `${progress}% 100%` }}
      >
        <span className="slider__label sr-only">{progress}% completed</span>
      </div>
    </div>
  );
}

export default SkidsSliderComponentCopy;
